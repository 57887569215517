import { useMatch } from "@tanstack/react-location";
import { Hero, AdminTrapDetailsHeading } from "./Hero";
import styled from "styled-components";
import { Spacer } from "../../../UI/Spacer";
import { useTrapDetails } from "../../../usecases/traps";
import { CustomTable } from "../../../components/CustomTable";
import { format, parseISO } from "date-fns";

export const TrapDetails = () => {
  const {
    params: { trapId },
  } = useMatch();
  const { data: trapDetails, isLoading: isLoadingTrapDetails } =
    useTrapDetails(trapId);

  if (isLoadingTrapDetails) {
    return null;
  }

  const language = window.navigator.userLanguage || window.navigator.language;

  const localValue = new Intl.NumberFormat(language, {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  });

  return (
    <>
      <HeroContainer>
        <Hero trapDetails={trapDetails} />
        <Spacer height="34px" />
      </HeroContainer>
      <Container>
        <AdminTrapDetailsHeading />
        <CustomTable
          data={trapDetails.activations}
          columns={[
            {
              header: "Activation Time",
              body: (activation) => (
                <>
                  {format(parseISO(activation.dateTime), "dd/MM/yyyy")}
                  <br />
                  {format(parseISO(activation.dateTime), "hh:mm aa")}
                </>
              ),
            },
            {
              header: "Temperature (°C)",
              body: (activation) => (
                <p>{localValue.format(activation.temperature)}</p>
              ),
            },
            {
              header: "Pressure  (kPa)",
              body: (activation) => (
                <>{localValue.format(activation.pressure * 0.001)}</>
              ),
            },
            {
              header: "Humidity (%)",
              body: (activation) => (
                <>{localValue.format(activation.humidity)}</>
              ),
            },
          ]}
        />
      </Container>
    </>
  );
};

const Container = styled.div`
  background-color: ${(props) => `${props.theme.colors.gray[100]}`};
  padding: 40px 32px 32px 32px;
`;

const HeroContainer = styled.div`
  background: #f1f3f5;
`;
