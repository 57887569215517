import { useEffect } from "react";
import { useUpdateRef } from "./useUpdateRef";

/**
 * @param {React.RefObject<HTMLElement>} ref
 * @param {(() => void) | undefined} callback
 */
export const useClickOutside = (ref, callback) => {
  const callbackRef = useUpdateRef(callback);

  useEffect(() => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callbackRef.current?.();
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [ref, callbackRef]);
};
