import {
  Navigate,
  Outlet,
  ReactLocation,
  Router as ReactLocationRouter,
  useNavigate,
  useRouter,
} from "@tanstack/react-location";
import { useAtomValue } from "jotai";
import { PageTitleFromLocation } from "./components/PageTitleFromLocation";
import { AcceptInvite } from "./pages/AcceptInvite";
import { AddOrEditClient } from "./pages/AddOrEditClient";
import { AddOrEditProperty } from "./pages/AddOrEditProperty";
import { ClientDetails as AdminClientDetails } from "./pages/admin/ClientDetails";
import { Clients as AdminClients } from "./pages/admin/Clients";
import { TrapDetails as AdminTrapDetails } from "./pages/admin/TrapDetails";
import { Traps as AdminTraps } from "./pages/admin/Traps";
import { ClientDetails } from "./pages/ClientDetails";
import { Clients } from "./pages/Clients";
import { Login } from "./pages/Login";
import { NewPassword } from "./pages/NewPassword";
import { PropertyDetails } from "./pages/PropertyDetails";
import { Registration } from "./pages/Registration";
import { RegistrationAccountSettings } from "./pages/Registration/RegistrationAccountSettings";
import { RegistrationBusinessInformation } from "./pages/Registration/RegistrationBusinessInformation";
import { RegistrationPersonalInformation } from "./pages/Registration/RegistrationPersonalInformation";
import { ResetPassword } from "./pages/ResetPassword";
import { Team } from "./pages/Team";
import { TeamMemberDetails } from "./pages/TeamMemberDetails";
import { TrapDetails } from "./pages/TrapDetails";
import { TrapDetailsForDate } from "./pages/TrapDetailsForDate";
import { TrapInventory } from "./pages/TrapInventory";
import { ReactComponent as NavClientsIcon } from "./UI/icons/nav-clients.svg";
import { ReactComponent as NavTeamIcon } from "./UI/icons/nav-team.svg";
import { ReactComponent as NavTrapsIcon } from "./UI/icons/nav-traps.svg";
import { AuthenticationGuard, userAtom } from "./usecases/auth";
import {
  businessAccountsQueryKeys,
  getAllBusinessAccounts,
  getBusinessAccount,
} from "./usecases/businessAccounts";
import { clientsQueryKeys, getClientDetails } from "./usecases/clients";
import { getPropertyDetails, propertiesQueryKeys } from "./usecases/properties";
import {
  getClientDetailsReport,
  getClientsReport,
  getPropertyDetailsReport,
  getTrapDetailsForDateReport,
  getTrapDetailsReport,
  reportsQueryKeys,
} from "./usecases/reports";
import { getTrap, getTraps, trapsQueryKeys } from "./usecases/traps";
import { getAllUsers, getUser, usersQueryKeys } from "./usecases/users";

const locationInstance = new ReactLocation();

/**
 * @typedef RouterProps
 * @prop {import('./usecases/auth').User | null} user
 * @prop {import('react-query').QueryClient} queryClient
 */

/** @type {React.FC<RouterProps>} */
export const Router = ({ user, children, queryClient }) => {
  /**
   * A utility function so that we don't have to write these 2 functions calls
   * in every loader.
   * The function checks the `react-query` cache for existing cached data and
   * returns it. If no data is cached, it fetches it from the backend.
   * Rendered route components still have to use `useQuery` (or a hook wrapping
   * it) for `react-query` to be fully utilized.
   */
  const fromCacheOrFetchQuery = async (queryKey, queryFn) =>
    queryClient.getQueryData(queryKey) ??
    queryClient.fetchQuery(queryKey, queryFn);
  // const fetchQuery = async (queryKey, queryFn) => queryClient.fetchQuery(queryKey, queryFn);

  const fallbackRoute = (() => {
    if (!user) {
      return "/login";
    }
    if (user.role === "ADMIN") {
      return "/admin/clients";
    }
    return "/clients";
  })();

  return (
    <ReactLocationRouter
      location={locationInstance}
      useErrorBoundary={true}
      defaultErrorElement={<ErrorElement />}
      routes={[
        {
          path: "login",
          element: <Login />,
          meta: {
            auth: {
              loggedOut: true,
            },
          },
        },
        {
          path: "register",
          element: <Registration />,
          meta: {
            auth: {
              loggedOut: true,
            },
          },
          children: [
            {
              path: "1",
              element: <RegistrationBusinessInformation />,
              meta: {
                step: 0,
              },
            },
            {
              path: "2",
              element: <RegistrationPersonalInformation />,
              meta: {
                step: 1,
              },
            },
            {
              path: "3",
              element: <RegistrationAccountSettings />,
              meta: {
                step: 2,
              },
            },
            {
              element: <Navigate to="/register/1" replace />,
            },
          ],
        },
        {
          path: "reset-password",
          element: <ResetPassword />,
          meta: {
            auth: {
              loggedOut: true,
            },
          },
        },
        {
          path: "new-password",
          element: <NewPassword />,
          search: ({ token }) => typeof token === "string" && token.length > 0,
          meta: {
            auth: {
              loggedOut: true,
            },
          },
        },
        {
          path: "accept-invite",
          element: <AcceptInvite />,
          search: ({ token }) => typeof token === "string" && token.length > 0,
          meta: {
            auth: {
              loggedOut: true,
            },
          },
        },
        {
          path: "admin",
          meta: {
            auth: {
              roles: ["ADMIN"],
            },
          },
          children: [
            {
              path: "clients",
              meta: {
                nav: {
                  icon: () => <NavClientsIcon width="13px" height="13px" />,
                  label: () => "Clients",
                  path: () => "/admin/clients",
                },
              },
              children: [
                {
                  path: "/",
                  element: <AdminClients />,
                  loader: async () => ({
                    clients: await fromCacheOrFetchQuery(
                      businessAccountsQueryKeys.list(),
                      () => getAllBusinessAccounts()
                    ),
                  }),
                },
                {
                  path: ":businessAccountId",
                  element: <AdminClientDetails />,
                  loader: async ({ params }) => ({
                    clientDetails: await fromCacheOrFetchQuery(
                      businessAccountsQueryKeys.detailsFor(
                        params.businessAccountId
                      ),
                      () => getBusinessAccount(params.businessAccountId)
                    ),
                  }),
                  meta: {
                    nav: {
                      label: (match) => match.data.clientDetails.name,
                      path: (match) =>
                        `/admin/clients/${match.params.businessAccountId}`,
                    },
                  },
                },
              ],
            },
            {
              path: "traps",
              meta: {
                nav: {
                  icon: () => <NavTrapsIcon width="13px" height="13px" />,
                  label: () => "Traps",
                  path: () => "/admin/traps",
                },
              },
              children: [
                {
                  path: "/",
                  element: <AdminTraps />,
                  loader: async () => ({
                    traps: await fromCacheOrFetchQuery(
                      trapsQueryKeys.list(),
                      () => getTraps()
                    ),
                  }),
                },
                {
                  path: ":trapId",
                  element: <AdminTrapDetails />,
                  loader: async ({ params }) => ({
                    trapDetails: await fromCacheOrFetchQuery(
                      trapsQueryKeys.detailsFor(params.trapId),
                      () => getTrap(params.trapId)
                    ),
                  }),
                  meta: {
                    nav: {
                      label: (match) => match.data.trapDetails.serialNumber,
                      path: (match) => `/admin/traps/${match.params.trapId}`,
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          path: "clients",
          meta: {
            auth: {
              roles: ["BUSINESS_ACCOUNT_ADMIN"],
            },
            nav: {
              icon: () => <NavClientsIcon width="13px" height="13px" />,
              label: () => "Clients",
              path: () => "/clients",
            },
          },
          children: [
            {
              path: "/",
              element: <Clients />,
              loader: async () => ({
                clients: await fromCacheOrFetchQuery(
                  reportsQueryKeys.clients(),
                  () => getClientsReport()
                ),
              }),
            },
            {
              path: "new",
              element: <AddOrEditClient action="add" />,
              loader: async () => ({
                managers: await fromCacheOrFetchQuery(
                  usersQueryKeys.list(),
                  async () => getAllUsers()
                ),
              }),
              meta: {
                nav: {
                  label: () => "New Client",
                  path: () => "/clients/new",
                },
              },
            },
            {
              path: ":clientId",
              loader: async ({ params }) => ({
                clientDetails: await fromCacheOrFetchQuery(
                  reportsQueryKeys.clientDetailsFor(params.clientId),
                  () => getClientDetailsReport(params.clientId)
                ),
              }),
              meta: {
                nav: {
                  label: (match) => match.data.clientDetails.client.name,
                  path: (match) => `/clients/${match.params.clientId}`,
                },
              },
              children: [
                {
                  path: "/",
                  element: <ClientDetails />,
                },
                {
                  path: "edit",
                  element: <AddOrEditClient action="edit" />,
                  loader: async (match, { parentMatch }) => ({
                    clientDetails: await fromCacheOrFetchQuery(
                      clientsQueryKeys.detailsFor(parentMatch.params.clientId),
                      () => getClientDetails(parentMatch.params.clientId)
                    ),
                    managers: await fromCacheOrFetchQuery(
                      usersQueryKeys.list(),
                      () => getAllUsers()
                    ),
                  }),
                  meta: {
                    nav: {
                      label: () => "Edit Client",
                      path: (match) => `/clients/${match.params.clientId}/edit`,
                    },
                  },
                },
                {
                  path: "new-property",
                  element: <AddOrEditProperty action="add" />,
                  meta: {
                    nav: {
                      label: () => "New Property",
                      path: (match) =>
                        `/clients/${match.params.clientId}/new-property`,
                    },
                  },
                },
                {
                  path: ":propertyId",
                  loader: async ({ params }) => ({
                    propertyDetails: await fromCacheOrFetchQuery(
                      reportsQueryKeys.propertyDetailsFor(params.propertyId),
                      () => getPropertyDetailsReport(params.propertyId)
                    ),
                  }),
                  meta: {
                    nav: {
                      label: (match) => {
                        return match.data.clientDetails.properties.find(
                          (property) => property._id === match.params.propertyId
                        )?.name;
                      },
                      path: (match) =>
                        `/clients/${match.params.clientId}/${match.params.propertyId}`,
                    },
                  },
                  children: [
                    {
                      path: "/",
                      element: <PropertyDetails />,
                    },
                    {
                      path: "edit",
                      element: <AddOrEditProperty action="edit" />,
                      loader: async (match, { parentMatch }) => ({
                        propertyDetails: await fromCacheOrFetchQuery(
                          propertiesQueryKeys.detailsFor(
                            parentMatch.params.propertyId
                          ),
                          () =>
                            getPropertyDetails(parentMatch.params.propertyId)
                        ),
                      }),
                      meta: {
                        nav: {
                          label: () => "Edit Property",
                          path: (match) =>
                            `/clients/${match.params.clientId}/${match.params.propertyId}/edit`,
                        },
                      },
                    },
                    {
                      path: ":trapImei",
                      loader: async ({ params }) => ({
                        trapDetails: await fromCacheOrFetchQuery(
                          reportsQueryKeys.trapDetailsFor(params.trapImei),
                          () => getTrapDetailsReport(params.trapImei)
                        ),
                      }),
                      meta: {
                        nav: {
                          label: (match) => {
                            return match.data.propertyDetails.traps.find(
                              (trap) => trap.imei === match.params.trapImei
                            )?.serialNumber;
                          },
                          path: (match) =>
                            `/clients/${match.params.clientId}/${match.params.propertyId}/${match.params.trapImei}`,
                        },
                      },
                      children: [
                        {
                          path: "/",
                          element: <TrapDetails />,
                        },
                        {
                          path: ":activityDate",
                          element: <TrapDetailsForDate />,
                          loader: async ({ params }) => ({
                            trapDetailsForDate: await fromCacheOrFetchQuery(
                              reportsQueryKeys.trapDetailsForDate(
                                params.trapImei,
                                params.activityDate
                              ),
                              () =>
                                getTrapDetailsForDateReport(
                                  params.trapImei,
                                  params.activityDate
                                )
                            ),
                          }),
                          meta: {
                            nav: {
                              label: (match) => {
                                const trapSerial =
                                  match.data.propertyDetails.traps.find(
                                    (trap) =>
                                      trap.imei === match.params.trapImei
                                  )?.serialNumber;
                                return `Activity for ${trapSerial} on ${match.params.activityDate}`;
                              },
                              path: (match) =>
                                `/clients/${match.params.clientId}/${match.params.propertyId}/${match.params.trapImei}/${match.params.activityDate}`,
                            },
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "traps",
          element: <TrapInventory />,
          loader: async () => ({
            traps: await fromCacheOrFetchQuery(trapsQueryKeys.list(), () =>
              getTraps()
            ),
          }),
          meta: {
            auth: {
              roles: ["BUSINESS_ACCOUNT_ADMIN"],
            },
            nav: {
              label: () => "Traps",
              path: () => `/traps`,
            },
          },
        },
        {
          path: "team",
          meta: {
            auth: {
              roles: ["BUSINESS_ACCOUNT_ADMIN"],
            },
            nav: {
              icon: () => <NavTeamIcon width="13px" height="13px" />,
              label: () => "Team",
              path: () => `/team`,
            },
          },
          children: [
            {
              path: "/",
              element: <Team />,
              loader: async () => ({
                team: await fromCacheOrFetchQuery(usersQueryKeys.list(), () =>
                  getAllUsers()
                ),
              }),
            },
            {
              path: ":userId",
              element: <TeamMemberDetails />,
              loader: async ({ params }) => ({
                details: await fromCacheOrFetchQuery(
                  usersQueryKeys.detailsFor(params.userId),
                  () => getUser(params.userId)
                ),
              }),
              meta: {
                nav: {
                  label: (match) =>
                    `${match.data.details.firstName} ${match.data.details.lastName} Details`,
                  path: (match) => `/team/${match.params.userId}`,
                },
              },
            },
          ],
        },
        {
          element: <Navigate to={fallbackRoute} replace />,
        },
      ]}
    >
      <AuthenticationGuardWithRedirect>
        <PageTitleFromLocation />
        <RouteAuthGuard>{children ?? <Outlet />}</RouteAuthGuard>
      </AuthenticationGuardWithRedirect>
    </ReactLocationRouter>
  );
};

const ErrorElement = () => {
  return "Oops, something went wrong.";
};

// Had to move redirection logic here so that we could use `useNavigate` as we
// can't do that in `Router`.
/** @type {React.FC} */
const AuthenticationGuardWithRedirect = ({ children }) => {
  const navigate = useNavigate();

  const handleFailed = () => {
    navigate({ to: "/login", replace: true });
  };

  return (
    <AuthenticationGuard onFailed={handleFailed}>
      {children}
    </AuthenticationGuard>
  );
};

export const RouteAuthGuard = ({ children }) => {
  const user = useAtomValue(userAtom);
  const matches = useRouter().state.matches;

  if (!user) {
    const requiresAuth = matches.some(
      (match) =>
        match.route?.meta?.auth?.roles || match.route?.meta?.auth?.loggedIn
    );
    if (requiresAuth) {
      return <Navigate to="/login" replace />;
    }
  } else {
    const requiresLoggedOut = matches.some(
      (match) => match.route?.meta?.auth?.loggedOut
    );
    if (requiresLoggedOut) {
      return (
        <Navigate
          to={user.role === "ADMIN" ? "/admin/clients" : "/clients"}
          replace
        />
      );
    }

    const requiresOneOfRoles = matches.find(
      (match) => match.route?.meta?.auth?.roles
    );
    if (
      requiresOneOfRoles &&
      !requiresOneOfRoles.route.meta.auth.roles.includes(user.role)
    ) {
      return (
        <Navigate
          to={user.role === "ADMIN" ? "/admin/clients" : "/clients"}
          replace
        />
      );
    }
  }

  return <>{children}</>;
};
