import { useLocation, useNavigate } from "@tanstack/react-location";
import { useCallback } from "react";

/** @type {typeof useNavigate} */
export const useNavigateWithPreviousRoute = () => {
  const navigate = useNavigate();
  const {
    current: { href },
  } = useLocation();

  return useCallback(
    (args) =>
      navigate({
        ...args,
        search: (prev) => ({ ...prev, prev: href }),
      }),
    [navigate, href]
  );
};
