import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "@tanstack/react-location";
import { isValidPhoneNumber } from "libphonenumber-js";
import React from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import { InputSubmit } from "../../../UI/InputSubmit";
import { InputText } from "../../../UI/InputText";
import { Spacer } from "../../../UI/Spacer";
import { useFormAtom } from "../../../utils/hooks/useFormAtom";
import { step2DataAtom } from "../state";

export const RegistrationPersonalInformation = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useFormAtom(step2DataAtom, {
    mode: "onBlur",
    resolver: yupResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      title: "",
    },
  });

  const handleValidSubmit = (data) => {
    navigate({ to: "/register/3" });
  };

  const textField = (name, placeholder) => (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <InputText
          {...field}
          placeholder={placeholder}
          errorMessage={errors[name]?.message}
        />
      )}
    />
  );

  return (
    <StyledSecondForm onSubmit={handleSubmit(handleValidSubmit)}>
      <div style={{ display: "flex", gap: "12px" }}>
        {textField("firstName", "First Name")}
        {textField("lastName", "Last Name")}
      </div>
      <Spacer height="12px" />
      {textField("phoneNumber", "Phone number (e.g. +1 819 555 5555)")}
      <Spacer height="12px" />
      {textField("title", "Position/Title")}
      <Spacer height="55px" />
      <InputSubmit type="submit">Next Step</InputSubmit>
      <div style={{ textAlign: "center", marginTop: "16px" }}>
        <PreviousStepAndLoginLink to="../1">
          Previous Step
        </PreviousStepAndLoginLink>
      </div>
      <div style={{ textAlign: "center", marginTop: "16px" }}>
        <PreviousStepAndLoginLink to="/login">Login</PreviousStepAndLoginLink>
      </div>
    </StyledSecondForm>
  );
};

const formSchema = yup.object({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  phoneNumber: yup
    .string()
    .required("Required")
    .test("is-phone-number", "Invalid phone number", (value) =>
      isValidPhoneNumber(value)
    ),
  title: yup.string(),
});

/* Styles */

const StyledSecondForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
`;

const PreviousStepAndLoginLink = styled(Link)`
  font-family: Bai Jamjuree;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #4a7eff;
  text-decoration: none;
`;
