import { InputText as PrimeInputText } from "primereact/inputtext";
import { forwardRef } from "react";
import styled from "styled-components";
import { InputError } from "../InputError";
import { Spacer } from "../Spacer";

export const InputText = forwardRef(
  (
    {
      value,
      onChange,
      onBlur,
      name,
      placeholder,
      type,
      errorMessage,
      readOnly,
      autoFocus,
    },
    ref
  ) => {
    return (
      <Root>
        <StyledInputText
          ref={ref}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          type={type}
          $hasError={Boolean(errorMessage)}
          readOnly={readOnly}
          autoFocus={autoFocus}
        />
        {errorMessage && (
          <>
            <Spacer height="4px" />
            <InputError>{errorMessage}</InputError>
          </>
        )}
      </Root>
    );
  }
);

const Root = styled.div`
  width: 100%;
`;
Root.displayName = "Root";

const StyledInputText = styled(PrimeInputText)`
  padding: 16px 8px 16px 24px;
  width: 100%;
  height: 48px;
  background: ${(props) => (props.readOnly ? "transparent" : "#ffffff")};
  border: 1px solid ${(props) => (props.$hasError ? "#FF4757" : "#d5dce1")};
  border-radius: 2px;
  font-family: "Bai Jamjuree", sans-serif;
  font-size: 14px;

  &:focus {
    outline-style: solid;
    outline-color: #4a7eff;
    outline-width: 1px;
    outline: ${(props) => (props.readOnly ? "none" : "")};
    border-color: ${(props) => (props.readOnly ? "unset" : "")};
    box-shadow: ${(props) => (props.readOnly ? "none" : "")};
  }

  .p-dropdown-item {
    transition: 0.3s background-color ease-in-out;
    background: black;

    &:hover {
      background-color: #dddddd;
    }
  }
`;
StyledInputText.displayName = "StyledInputText";
