import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import styled from "styled-components";
import loadingLottieData from "../lotties/loading.json";

/**
 * @typedef LoadingProps
 * @prop {number | undefined} width
 * @prop {number | undefined} height
 * @prop {string | undefined} className
 * @prop {React.CSSProperties | undefined} style
 */

/** @type {React.FC<LoadingProps>} */
export const Loading = ({ width, height, className, style }) => {
  return (
    <StyledLottie
      style={style}
      className={className}
      $width={width}
      $height={height}
      animationData={loadingLottieData}
      loop
      play
      rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
      speed={0.9}
    />
  );
};

const StyledLottie = styled(Lottie)`
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  svg * {
    fill: currentColor;
    stroke: currentColor;
  }
`;
StyledLottie.displayName = "StyledLottie";
