import React from "react";
import styled from "styled-components";
import { BreadcrumbsFromLocation } from "../../../components/BreadcrumbsFromLocation";
import { H3 } from "../../../UI/H3";
import { ReactComponent as BlueTrapIcon } from "../../../UI/icons/BlueTrapIcon.svg";
import { ReactComponent as DailySummaryIcon } from "../../../UI/icons/DailySummaryIcon.svg";
import { Spacer } from "../../../UI/Spacer";
import { H4 } from "../../../UI/H4";
import { ReactComponent as BatteryEmptyIcon } from "../../../UI/icons/BatteryEmptyIcon.svg";
import { ReactComponent as BatteryLowIcon } from "../../../UI/icons/BatteryLowIcon.svg";
import { ReactComponent as BatteryFullIcon } from "../../../UI/icons/BatteryFullIcon.svg";
import { ReactComponent as BatteryMediumIcon } from "../../../UI/icons/BatteryMediumIcon.svg";
import { ReactComponent as ExcellentSignal } from "../../../UI/icons/ExcellentSignal.svg";
import { ReactComponent as FairSignal } from "../../../UI/icons/FairSignal.svg";
import { ReactComponent as GoodSignal } from "../../../UI/icons/GoodSignal.svg";
import { ReactComponent as PoorSignal } from "../../../UI/icons/PoorSignal.svg";
import { ReactComponent as DownloadIcon } from "../../../UI/icons/DownloadIcon.svg";
import { Map } from "../../../components/Map";
import { exportToSpreadsheet } from "../../../utils/export";
import { pick } from "../../../utils/object";
import { H5 } from "../../../UI/H5";
import { format, parseISO } from "date-fns";

export const Hero = ({ trapDetails }) => {
  const headers = [["Date", "Activations", "Battery", "Cell Signal"]];
  const exportExcel = async () => {
    await exportToSpreadsheet(
      trapDetails.days.map((trap) => ({
        ...pick(trap, ["date", "batteryLevel", "signalLevel", "activations"]),
      })),
      `Trap Details`,
      "trap-details",
      headers
    );
  };

  return (
    <>
      <Spacer height="24px" />
      <BreadCrumbContainer>
        <BreadcrumbsFromLocation />
        <DownloadButton onClick={exportExcel}>
          <DownloadIcon />
          <Spacer width="6px" />
          Report
        </DownloadButton>
      </BreadCrumbContainer>
      <Spacer height="24px" />
      <HeroContainer>
        <MapContainer>
          <MapContainerChildren>
            <FlexContainer>
              <div style={{ display: "flex", alignItems: "center" }}>
                <BlueTrapIcon />
                <Spacer width="8px" />
                <H4 white>{trapDetails.name}</H4>
              </div>
              {/* TODO: Remove this for DEMO, figure out how these are actually going to work */}
              {/* <div style={{ display: "flex" }}>
            <ClientHeroButton>Edit</ClientHeroButton>
            <Spacer width="8px" />
            <ClientHeroButton red>Deactivate</ClientHeroButton>
          </div> */}
            </FlexContainer>
            <Spacer height="24px" />
            <ClientInformationFlex>
              <Spacer width="24px" />
              <div>
                <ClientInformationParagraph>
                  <span>Manager</span> {trapDetails.manager}
                </ClientInformationParagraph>
                <Spacer height="8px" />
                <ClientInformationParagraph>
                  <span>Client</span> {trapDetails.client}
                </ClientInformationParagraph>
                <Spacer height="8px" />
                <ClientInformationParagraph>
                  <span>Contact</span> {trapDetails.contact.name}
                </ClientInformationParagraph>
                <Spacer height="8px" />
                <ClientInformationParagraph>
                  {trapDetails.contact.email}
                </ClientInformationParagraph>
                <Spacer height="8px" />
                <ClientInformationParagraph>
                  {trapDetails.contact.phoneNumber}
                </ClientInformationParagraph>
              </div>
              <Spacer width="117px" />

              <div>
                <ClientInformationParagraph>
                  <span>Property</span> {trapDetails.property.name}
                </ClientInformationParagraph>
                <Spacer height="8px" />
                <ClientInformationParagraph>
                  {trapDetails.property.street}
                </ClientInformationParagraph>
                <Spacer height="8px" />
                <ClientInformationParagraph>
                  {trapDetails.property.country}{" "}
                  {trapDetails.property.postalCode}
                </ClientInformationParagraph>
                <Spacer height="8px" />
                <ClientInformationParagraph>
                  {trapDetails.property.state} / {trapDetails.property.city}
                </ClientInformationParagraph>
                <Spacer height="8px" />
              </div>
            </ClientInformationFlex>
          </MapContainerChildren>
          <MapContainerChildren>
            {!trapDetails.coordinates?.longitude && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <H5 style={{ color: "white" }}>No data to display.</H5>
              </div>
            )}
            {trapDetails.coordinates?.longitude && (
              <Map pins={[trapDetails.coordinates]} />
            )}
          </MapContainerChildren>
        </MapContainer>
        <RootContainer>
          <Root>
            <InformationContainer>
              <Spacer height="16px" />
              <LabelParagraph>Battery</LabelParagraph>
              <Spacer height="28px" />
              <CountParagraph style={{ fontWeight: "600" }}>
                <BatteryIcon status={trapDetails.battery} />
              </CountParagraph>
              <Spacer height="24px" />
            </InformationContainer>
            <InformationContainer>
              <Spacer height="16px" />
              <LabelParagraph>Signal</LabelParagraph>
              <Spacer height="28px" />
              <CountParagraph style={{ fontWeight: "600" }}>
                <SignalIcon status={trapDetails.signal} />
              </CountParagraph>
              <Spacer height="24px" />
            </InformationContainer>
            <InformationContainer>
              <Spacer height="16px" />
              <LabelParagraph>Bait</LabelParagraph>
              <Spacer height="28px" />
              <CountParagraph style={{ fontWeight: "600" }}>N/A</CountParagraph>
              <Spacer height="24px" />
            </InformationContainer>
          </Root>
          <Root>
            <InformationContainer>
              <Spacer height="16px" />
              <LabelParagraph>Total Activations Past Week</LabelParagraph>
              <Spacer height="28px" />
              <CountParagraph style={{ fontWeight: "600" }}>
                {trapDetails.totalActivationsPastWeek}
              </CountParagraph>
              <Spacer height="24px" />
            </InformationContainer>
            <InformationContainer>
              <Spacer height="16px" />
              <LabelParagraph>Total Activations Past Month</LabelParagraph>
              <Spacer height="28px" />
              <CountParagraph style={{ fontWeight: "600" }}>
                {trapDetails.totalActivationsPastMonth}
              </CountParagraph>
              <Spacer height="24px" />
            </InformationContainer>
            <InformationContainer>
              <Spacer height="16px" />
              <LabelParagraph>Most Recent Visit</LabelParagraph>
              <Spacer height="28px" />
              <CountParagraph style={{ fontWeight: "600" }}>
                {trapDetails.lastVisit === null ? (
                  <>-</>
                ) : (
                  format(parseISO(trapDetails.lastVisit), "dd/MM/yyyy")
                )}
              </CountParagraph>
              <Spacer height="24px" />
            </InformationContainer>
          </Root>
        </RootContainer>
      </HeroContainer>
    </>
  );
};

const BatteryIcon = ({ status }) => {
  if (status === "dead") {
    return <BatteryEmptyIcon />;
  } else if (status === "low") {
    return <BatteryLowIcon />;
  } else if (status === "medium") {
    return <BatteryMediumIcon />;
  } else if (status === "full") {
    return <BatteryFullIcon />;
  }

  return "-";
};

const SignalIcon = ({ status }) => {
  if (status === "poor") {
    return <PoorSignal />;
  } else if (status === "fair") {
    return <FairSignal />;
  } else if (status === "good") {
    return <GoodSignal />;
  } else if (status === "excellent") {
    return <ExcellentSignal />;
  }

  return "-";
};

export const DailySummariesHeading = () => {
  return (
    <>
      <ContentWrapper>
        <Child>
          <DailySummaryIcon />
          <Spacer width="10px" />
          <H3>Daily Summaries</H3>
        </Child>
      </ContentWrapper>
      <Spacer height="48px" />
    </>
  );
};

const BreadCrumbContainer = styled.div`
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
`;

const DownloadButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-family: "Bai Jamjuree";
  font-weight: bold;
  cursor: pointer;
`;

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 0 32px;
  position: relative;
`;

const RootContainer = styled.div`
  display: flex;
  position: absolute;
  top: 260px;
  justify-content: center;
`;

const Root = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  background-color: #fff;
  border: 1px solid #d5dce1;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  top: 20px;
  flex-shrink: 0;
  max-width: 424px;
  width: 100%;

  &:first-child {
    margin-right: 12px;
  }
`;

const InformationContainer = styled.div`
  border-radius: 8px;
  max-width: 151px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 12px;
  text-align: center;
  justify-content: space-between;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Child = styled.div`
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
  }
`;

const CountParagraph = styled.p`
  font-size: 16px;
  font-weight: 600;
  font-family: "Bai Jamjuree";
`;

const LabelParagraph = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #405261;
`;

const MapContainer = styled.div`
  display: flex;
  width: 100%;
  height: 265px;
`;

const MapContainerChildren = styled.div`
  width: 50%;
  &:first-child {
    background: linear-gradient(91.98deg, #0d1026 3.35%, #19204d 96.65%);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:nth-child(2) {
    background: linear-gradient(91.98deg, #0d1026 3.35%, #19204d 96.65%);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const ClientInformationFlex = styled.div`
  display: flex;
`;

const ClientInformationParagraph = styled.div`
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);

  span {
    color: #a5bfff;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 18px 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;
