import { Toast } from "./index";
import { toast } from "react-toastify";

export const toastSuccess = (message, options) =>
  toast(<Toast {...message} type="success" />, {
    ...options,
    className: "success",
  });

export const toastError = (message, options) =>
  toast(<Toast {...message} type="error" />, {
    ...options,
    className: "error",
  });
