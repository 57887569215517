import { useAtom } from "jotai";
import { useEffect } from "react";
import { useForm, useFormState } from "react-hook-form";
import { pick } from "../object";

/**
 * Like `useForm` but takes default values from an atom and keeps that atom
 * up to date with form state, but only if data passes validation. Otherwise,
 * the atom value gets set to `undefined`.
 * @template T
 * @param {import("jotai").Atom<T>} atom
 * @param  {import("react-hook-form").UseFormProps<T, any>} props
 */
export const useFormAtom = (atom, props) => {
  const [atomValue, setAtomValue] = useAtom(atom);
  const propsDefaultValues = props?.defaultValues;
  const keysToUseFromAtom = propsDefaultValues
    ? Object.keys(propsDefaultValues)
    : [];
  const defaultValuesFromAtom = pick(atomValue ?? {}, keysToUseFromAtom);

  const useFormReturn = useForm({
    ...props,
    defaultValues: propsDefaultValues
      ? {
          ...propsDefaultValues,
          ...defaultValuesFromAtom,
        }
      : undefined,
  });

  const { watch, control } = useFormReturn;
  const { isValid } = useFormState({ control });

  useEffect(() => {
    const subscription = watch((data) => {
      setAtomValue(data);
    });

    return () => subscription.unsubscribe();
  }, [watch, setAtomValue, isValid]);

  return useFormReturn;
};
