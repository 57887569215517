import styled from "styled-components";

export const Button = styled.button`
  background: #e6edff;
  border: 1px solid #4a7eff;
  box-shadow: 0px 4px 15px rgba(74, 126, 255, 0.11);
  border-radius: 31px;
  color: #040e56;
  font-size: 14px;
  font-weight: bold;
  padding: 13px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: "Bai Jamjuree";

  svg {
    margin-right: 10px;
  }
`;
