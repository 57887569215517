import { useQuery } from "react-query";
import { queryInvalidateHook } from "../utils/queryInvalidateHook";
import { clientsQueryKeys } from "./queryKeys";
import { getClientDetails, getClients } from "./services";

export const useAllClients = () =>
  useQuery(clientsQueryKeys.list(), () => getClients());

export const useInvalidateClientsList = queryInvalidateHook(() =>
  clientsQueryKeys.list()
);

export const useClientDetails = (id) =>
  useQuery(clientsQueryKeys.detailsFor(id), () => getClientDetails(id));

export const useInvalidateClientDetails = queryInvalidateHook((id) =>
  clientsQueryKeys.detailsFor(id)
);
