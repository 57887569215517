import { useRouter } from "@tanstack/react-location";
import { Breadcrumbs } from "../../UI/Breadcrumbs";

export const BreadcrumbsFromLocation = () => {
  const matches = useRouter().state.matches;
  const matchesToConsider = matches.filter((match) => match.route.meta?.nav);

  if (matchesToConsider.some((match) => match.status !== "resolved")) {
    return null;
  }

  const items = matchesToConsider.map((match) => ({
    icon: match.route.meta.nav.icon?.(match),
    label: match.route.meta.nav.label(match),
    path: match.route.meta.nav.path(match),
  }));

  return <Breadcrumbs items={items} />;
};
