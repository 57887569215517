import { Navigate, Outlet, useMatches } from "@tanstack/react-location";
import { useAtomValue } from "jotai";
import React from "react";
import styled from "styled-components";
import { H2 } from "../../UI/H2";
import { Spacer } from "../../UI/Spacer";
import { Stepper } from "../../UI/Stepper";
import { isNullish } from "../../utils/typeGuards";
import { step1DataAtom, step2DataAtom, step3DataAtom } from "./state";

export const Registration = () => {
  const activeStep = useActiveStep();
  const step1Data = useAtomValue(step1DataAtom);
  const step2Data = useAtomValue(step2DataAtom);
  const step3Data = useAtomValue(step3DataAtom);

  if (
    isNullish(activeStep) ||
    activeStep < 0 ||
    activeStep > steps.length - 1
  ) {
    return <Navigate to="/register/1" replace />;
  }

  const incompleteStep = [step1Data, step2Data, step3Data].findIndex(
    (stepData, index) => activeStep > index && isNullish(stepData)
  );
  if (incompleteStep > -1) {
    return <Navigate to={`/register/${incompleteStep + 1}`} replace />;
  }

  return (
    <RegistrationContainer>
      <RegistrationContainerChild>
        <img src="/RegisterLoginArtwork.svg" alt="" />
        <img src="/Logo.svg" alt="" />
      </RegistrationContainerChild>

      <RegistrationContainerChild>
        <RegistrationRightSideContent>
          <H2>Account Registration</H2>
          <Spacer height="55px" />
          <Stepper steps={steps} activeStep={activeStep} />
          <Spacer height="55px" />
          <Outlet />
        </RegistrationRightSideContent>
      </RegistrationContainerChild>
    </RegistrationContainer>
  );
};

const steps = [
  { label: "Business information" },
  { label: "Account Holder Information" },
  { label: "Account Settings" },
];

const useActiveStep = () => {
  const matches = useMatches();
  const match = matches.find(
    (match) => typeof match.route.meta?.step === "number"
  );
  return match?.route.meta.step;
};

/* Styles */

const RegistrationContainer = styled.div`
  display: flex;
  background-color: #f9fafa;
`;
RegistrationContainer.displayName = "RegistrationContainer";

const RegistrationContainerChild = styled.div`
  flex: 0 50%;
  width: 50vw;
  height: 100vh;

  &:first-child {
    position: relative;

    img:first-child {
      width: 100%;
      object-fit: cover;
      height: 100vh;
    }

    img:nth-child(2) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 90%;
    }

    @media (max-width: 777px) {
      &:first-child {
        display: none;
      }
    }
  }

  &:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    padding: 30px 0;

    @media (max-width: 777px) {
      flex-basis: 100%;
    }
  }
`;
RegistrationContainerChild.displayName = "RegistrationContainerChild";

const RegistrationRightSideContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-width: 424px;
  width: 100%;

  @media (max-width: 907px) {
    padding: 0 10px;
    margin: unset;
  }
`;
RegistrationRightSideContent.displayName = "RegistrationRightSideContent";
