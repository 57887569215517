import { useQuery } from "react-query";
import { queryInvalidateHook } from "../utils/queryInvalidateHook";
import { propertiesQueryKeys } from "./queryKeys";
import { getPropertyDetails } from "./services";

export const usePropertyDetails = (id) =>
  useQuery(propertiesQueryKeys.detailsFor(id), () => getPropertyDetails(id));

export const useInvalidatePropertyDetails = queryInvalidateHook((id) =>
  propertiesQueryKeys.detailsFor(id)
);
