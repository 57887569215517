import styled from "styled-components";
import { Modal } from "../../components/Modal";
import { InputSubmit } from "../../UI/InputSubmit";

export const LogoutConfirmationModal = ({
  isOpen,
  onSubmit,
  onCancel,
  isLoading,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onCancel}
    heading="Are you sure you want to sign out?"
  >
    <ButtonsContainer>
      <BackButton onClick={onCancel}>Cancel</BackButton>
      <InputSubmit loading={isLoading} onClick={onSubmit}>
        Sign out
      </InputSubmit>
    </ButtonsContainer>
  </Modal>
);

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 257px;
  margin: 0 auto;
`;

const BackButton = styled.button`
  background-color: #e5edff;
  border: 2px solid rgba(4, 14, 86, 0.07);
  padding: 16px 32px;
  margin-right: 8px;
  cursor: pointer;
`;
