import { axios } from "../../utils/axios";

export const getAllUsers = async () => {
  const response = await axios.get("/users");
  return response.data;
};

/**
 * @param {string} id
 */
export const getUser = async (id) => {
  const response = await axios.get(`/users/${id}`);
  return response.data;
};

export const inviteUser = async ({
  firstName,
  lastName,
  email,
  phoneNumber,
}) => {
  const response = await axios.post("/auth/invite-user", {
    firstName,
    lastName,
    email,
    phoneNumber,
    role: "BUSINESS_ACCOUNT_ADMIN",
  });
  return response.data;
};

export const updateUser = async (
  userId,
  { firstName, lastName, email, phoneNumber }
) => {
  const response = await axios.patch(`/users/${userId}`, {
    firstName,
    lastName,
    email,
    phoneNumber,
  });
  return response.data;
};
