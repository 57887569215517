import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "@tanstack/react-location";
import React from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import { CountryDropdown } from "../../../UI/CountryDropdown";
import { InputSubmit } from "../../../UI/InputSubmit";
import { InputText } from "../../../UI/InputText";
import { Spacer } from "../../../UI/Spacer";
import { useFormAtom } from "../../../utils/hooks/useFormAtom";
import { step1DataAtom } from "../state";

export const RegistrationBusinessInformation = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useFormAtom(step1DataAtom, {
    mode: "onBlur",
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
      country: "",
      state: "",
      city: "",
      zip: "",
      address: "",
    },
  });

  const handleValidSubmit = (data) => {
    navigate({ to: "/register/2" });
  };

  const textField = (name, placeholder) => (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <InputText
          {...field}
          placeholder={placeholder}
          errorMessage={errors[name]?.message}
        />
      )}
    />
  );

  return (
    <StyledFirstForm onSubmit={handleSubmit(handleValidSubmit)}>
      {textField("name", "Business name")}
      <Spacer height="12px" />
      {textField("email", "Business email")}
      <Spacer height="32px" />
      <Controller
        control={control}
        name="country"
        render={({ field }) => (
          <CountryDropdown
            {...field}
            placeholder="Country"
            errorMessage={errors["country"]?.message}
          />
        )}
      />
      <Spacer height="12px" />
      {textField("state", "State/Province")}
      <Spacer height="12px" />
      <div style={{ display: "flex" }}>
        <div style={{ flex: "1 1 auto" }}>{textField("city", "City")}</div>
        <div style={{ flex: "0 1 144px", marginLeft: "8px" }}>
          {textField("zip", "Zip/Postal Code")}
        </div>
      </div>
      <Spacer height="12px" />
      {textField("address", "Address")}
      <Spacer height="55px" />
      <InputSubmit type="submit">Next Step</InputSubmit>
      <div style={{ textAlign: "center", marginTop: "16px" }}>
        <PreviousStepAndLoginLink to="/login">Login</PreviousStepAndLoginLink>
      </div>
    </StyledFirstForm>
  );
};

const formSchema = yup.object({
  name: yup.string().required("Required"),
  email: yup.string().email("Invalid email").required("Required"),
  country: yup.string().required("Required"),
  address: yup.string(),
  state: yup.string(),
  city: yup.string(),
  zip: yup.string(),
});

/* Styles */

const StyledFirstForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
`;

const PreviousStepAndLoginLink = styled(Link)`
  font-family: Bai Jamjuree;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #4a7eff;
  text-decoration: none;
`;
