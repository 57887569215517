import { Link } from "@tanstack/react-location";
import styled from "styled-components";
import { ReactComponent as NavArrowLeftIcon } from "../../UI/icons/nav-arrow-left.svg";
import { Spacer } from "../../UI/Spacer";
import { useBackLink } from "../../utils/hooks/useBackLink";

export const BackLink = () => {
  const { status, name, path } = useBackLink();

  if (status !== "success") {
    return null;
  }

  return (
    <Root to={path}>
      <StyledNavArrowLeftIcon />
      <Spacer width="8px" />
      <BackText>Back to {name}</BackText>
    </Root>
  );
};

const StyledNavArrowLeftIcon = styled(NavArrowLeftIcon)`
  transition: transform 200ms ease-in-out;
`;
StyledNavArrowLeftIcon.displayName = "StyledNavArrowLeftIcon";

const Root = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover ${StyledNavArrowLeftIcon} {
    transform: translateX(-4px);
  }
`;
Root.displayName = "Root";

const BackText = styled.span`
  color: ${(props) => props.theme.colors.dark[200]};
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 17.5px;
`;
BackText.displayName = "BackText";
