import { formatISO } from "date-fns";

export const exportToSpreadsheet = async (
  data,
  sheetName,
  fileName,
  headers
) => {
  const xlsx = await import("xlsx");
  const worksheet = xlsx.utils.json_to_sheet(data, {
    origin: "A2",
    skipHeader: true,
  });
  const workbook = {
    Sheets: { [sheetName]: worksheet },
    SheetNames: [sheetName],
  };

  xlsx.utils.sheet_add_aoa(worksheet, headers, { origin: "A1" });

  const excelBuffer = xlsx.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  saveAsExcelFile(excelBuffer, fileName);
};

const saveAsExcelFile = async (buffer, fileName) => {
  const fileSaver = await import("file-saver");
  const EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const EXCEL_EXTENSION = ".xlsx";
  const data = new Blob([buffer], {
    type: EXCEL_TYPE,
  });

  fileSaver.default.saveAs(
    data,
    fileName +
      "_" +
      formatISO(new Date(), { representation: "date" }) +
      EXCEL_EXTENSION
  );
};
