import { Link, useNavigate } from "@tanstack/react-location";
import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "../../UI/Button";
import { H3 } from "../../UI/H3";
import { ReactComponent as ClientsIcon } from "../../UI/icons/ClientsIcon.svg";
import { ReactComponent as EditIcon } from "../../UI/icons/EditIcon.svg";
import { ReactComponent as RoundPlusIcon } from "../../UI/icons/RoundPlusIcon.svg";
import { InputText } from "../../UI/InputText";
import { Spacer } from "../../UI/Spacer";
import { CustomTable } from "../../components/CustomTable";
import { useClientsReport } from "../../usecases/reports";
import { useNavigateWithPreviousRoute } from "../../utils/hooks/useNavigateWithPreviousRoute";

export const Clients = () => {
  const navigate = useNavigate();
  const { data: clients, isLoading } = useClientsReport();

  const navigateWithPreviousRoute = useNavigateWithPreviousRoute();

  const handleEditClick = (clientId) => {
    navigateWithPreviousRoute({ to: `${clientId}/edit` });
  };

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const handleGlobalFilterValueChange = (event) => {
    setGlobalFilterValue(event.target.value);
  };

  const navigateCreateClient = () => {
    navigate({ to: "new" });
  };

  if (isLoading) {
    return null;
  }

  return (
    <FlexWrapper>
      <Content>
        <Hero>
          <HeroChild>
            <ClientsIcon />
            <Spacer width="10px" />
            <H3>Clients</H3>
            <Spacer width="18px" />
            <InputContainer>
              <InputText
                placeholder="Search"
                value={globalFilterValue}
                onChange={handleGlobalFilterValueChange}
              />
            </InputContainer>
            <Spacer width="24px" />
          </HeroChild>
          <Button onClick={navigateCreateClient}>
            <RoundPlusIcon />
            New Client
          </Button>
        </Hero>
        <Spacer height="40px" />
        <CustomTable
          data={clients.map((client) => ({
            ...client,
            status: deriveClientStatus(client),
          }))}
          globalFilterValue={globalFilterValue}
          globalFilterFields={["name", "manager"]}
          columns={[
            {
              header: "Name",
              field: "name",
              sortable: true,
            },
            {
              header: "Manager",
              field: "manager",
              sortable: true,
            },
            {
              header: "# of Traps",
              field: "traps",
              sortable: true,
            },
            {
              header: "Total 24 hour activations",
              field: "totalActivationsPastDay",
              sortable: true,
            },
            {
              header: "Status",
              field: "status",
              body: (client) => {
                if (client.status === "deactivated") {
                  return <StyledStatus deactivated>Deactivated</StyledStatus>;
                } else if (client.status === "active") {
                  return <StyledStatus>Active</StyledStatus>;
                } else if (client.status === "inactive") {
                  return <StyledStatus inactive>Inactive</StyledStatus>;
                }
              },
              filter: {
                type: "oneOf",
                options: [
                  {
                    label: "Active",
                    value: "active",
                  },
                  {
                    label: "Inactive",
                    value: "inactive",
                  },
                  {
                    label: "Deactivated",
                    value: "deactivated",
                  },
                ],
              },
            },
            {
              body: (client) => (
                <LastColumnWrapper>
                  <EditButton
                    className="visibleOnRowHover"
                    onClick={() => handleEditClick(client.id)}
                  >
                    <EditIcon />
                  </EditButton>
                  <Spacer width="12px" />
                  <InternalLink to={client.id}>Details</InternalLink>
                </LastColumnWrapper>
              ),
            },
          ]}
        />
      </Content>
    </FlexWrapper>
  );
};

const deriveClientStatus = (client) => {
  if (!client.isActive) {
    return "deactivated";
  }
  if (client.traps > 0) {
    return "active";
  }
  if (client.traps === 0) {
    return "inactive";
  }
};

const FlexWrapper = styled.div`
  background-color: ${(props) => `${props.theme.colors.gray[100]}`};
  padding: ${(props) => `32px ${props.theme.paddings.contentX}`};
`;

const Content = styled.div`
  margin: 0 auto;
  max-width: ${(props) => props.theme.maxWidths.content};
`;

const Hero = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeroChild = styled.div`
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
  }
`;

const InputContainer = styled.div`
  max-width: 200px;
`;

const EditButton = styled.button`
  opacity: 0;
  background-color: transparent;
  border: none;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  transition: 0.3s background-color ease-in-out;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    position: absolute;
    content: "Edit";
    top: -12px;
    font-size: 10px;
    color: #fff;
    background: linear-gradient(91.98deg, #0d1026 3.35%, #19204d 96.65%);
    border-radius: 4px;
    font-family: "Roboto", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-out;
    padding: 4px 8px;
  }

  &:hover {
    background-color: #f1f3f5;

    &::before {
      opacity: 1;
    }
  }
`;

const InternalLink = styled(Link)`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4a7eff;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
`;

const LastColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;

  svg {
    flex-shrink: 0;
  }
`;

const StyledStatus = styled.p`
  padding: 4px 8px 6px 19px;
  border: ${(props) =>
    props.inactive
      ? " 1px solid #B7C2CC"
      : props.deactivated
      ? "1px solid #FAB347"
      : "1px solid #56c288"};
  border-radius: 25px;
  width: fit-content;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: ${(props) =>
      props.inactive ? "#B7C2CC" : props.deactivated ? "#FAB347" : "#56c288"};
    border-radius: 50%;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
