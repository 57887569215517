import { Loader } from "@googlemaps/js-api-loader";
import { useEffect, useState } from "react";

export const googleMapsLoader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  version: "weekly",
  libraries: ["places", "visualization"],
});

/**
 * @template {P}
 * @param {React.ComponentType<P>} Component
 * @returns {React.FC<React.ComponentPropsWithoutRef<P>>}
 */
export const GoogleMapsApiLoadedGuard = (Component) => {
  const Guard = (props) => {
    const [loadingState, setLoadingState] = useState(
      /** @type {'loading' | 'loaded' | 'error'} */ ("loading")
    );

    useEffect(() => {
      (async () => {
        try {
          await googleMapsLoader.load();
          setLoadingState("loaded");
        } catch {
          setLoadingState("error");
        }
      })();
    }, []);

    if (loadingState !== "loaded") {
      return null;
    }

    return <Component {...props} />;
  };

  return Guard;
};
