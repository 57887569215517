import { Link as ReactLocationLink } from "@tanstack/react-location";
import styled from "styled-components";

export const Link = styled(ReactLocationLink)`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4a7eff;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
`;
