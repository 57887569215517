export const reportsQueryKeys = {
  all: () => ["reports"],
  clients: () => [...reportsQueryKeys.all(), "clients"],
  clientDetails: () => [...reportsQueryKeys.all(), "clientDetails"],
  /**
   * @param {string} id
   */
  clientDetailsFor: (id) => [...reportsQueryKeys.clientDetails(), id],
  propertyDetails: () => [...reportsQueryKeys.all(), "propertyDetails"],
  /**
   * @param {string} id
   */
  propertyDetailsFor: (id) => [...reportsQueryKeys.propertyDetails(), id],
  trapDetails: () => [...reportsQueryKeys.all(), "trapDetails"],
  /**
   * @param {string} imei
   */
  trapDetailsFor: (imei) => [...reportsQueryKeys.trapDetails(), imei],
  /**
   *
   * @param {string} imei
   * @param {string} date
   * @returns
   */
  trapDetailsForDate: (imei, date) => [
    ...reportsQueryKeys.trapDetails(),
    imei,
    date,
  ],
};
