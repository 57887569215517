import { useMatch } from "@tanstack/react-location";
import React from "react";
import { H3 } from "../../UI/H3";
import { Spacer } from "../../UI/Spacer";
import styled from "styled-components";
import { format, formatISO, parseISO } from "date-fns";
import { exportToSpreadsheet } from "../../utils/export";
import { ReactComponent as DownloadIcon } from "../../UI/icons/DownloadIcon.svg";
import { useTrapDetailsForDate } from "../../usecases/reports";
import { CustomTable } from "../../components/CustomTable";

export const TrapDetailsForDate = () => {
  const {
    params: { activityDate, trapImei },
  } = useMatch();
  const { data: trapDetailsForDate, isLoading: isLoadingTrapDetails } =
    useTrapDetailsForDate(trapImei, activityDate);

  const Headers = [
    [
      "Activation Date",
      "Activation Time",
      "Pressure (kPa)",
      "Temperature (°C)",
      "Humidity (%)",
    ],
  ];

  const dateStringForUi = format(parseISO(activityDate), "dd/MM/yyyy");
  const dateStringForSheetName = formatISO(parseISO(activityDate), {
    representation: "date",
  });

  const exportExcel = async () => {
    await exportToSpreadsheet(
      trapDetailsForDate.map((trap) => ({
        "Activation Date": format(parseISO(trap.dateTime), "dd/MM/yyyy"),
        "Activation Time": format(parseISO(trap.dateTime), "hh:mm:ss aa"),
        "Pressure (kPa)": localValue.format(trap.pressure * 0.001),
        "Temperature (°C)": localValue.format(trap.temperature),
        "Humidity (%)": localValue.format(trap.humidity),
      })),
      `Trap Details For ${dateStringForSheetName}`,
      "trap-details-for-date",
      Headers
    );
  };

  const language = window.navigator.userLanguage || window.navigator.language;

  const localValue = new Intl.NumberFormat(language, {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  });

  if (isLoadingTrapDetails) {
    return null;
  }

  return (
    <FlexWrapper>
      <Spacer height="34px" />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <H3>Trap Activity for {dateStringForUi}</H3>
        <DownloadButton onClick={exportExcel}>
          <DownloadIcon />
          <Spacer width="6px" />
          Report
        </DownloadButton>
      </div>
      <Spacer height="40px" />
      <CustomTable
        data={trapDetailsForDate}
        columns={[
          {
            header: "Activation Time",
            body: (trapDetails) =>
              format(parseISO(trapDetails.dateTime), "hh:mm:ss aa"),
          },
          {
            header: "Temperature (°C)",
            body: (trapDetails) => (
              <p>{localValue.format(trapDetails.temperature)}</p>
            ),
          },
          {
            header: "Pressure  (kPa)",
            body: (trapDetails) => (
              <>{localValue.format(trapDetails.pressure * 0.001)}</>
            ),
          },
          {
            header: "Humidity (%)",
            body: (trapDetails) => (
              <>{localValue.format(trapDetails.humidity)}</>
            ),
          },
        ]}
      />
    </FlexWrapper>
  );
};

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px;
`;

const DownloadButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-family: "Bai Jamjuree";
  font-weight: bold;
  cursor: pointer;
`;
