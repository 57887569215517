import { useUpdateAtom } from "jotai/utils";
import React, { useState } from "react";
import styled from "styled-components";
import { toastError, toastSuccess } from "../../components/Toast/Toast";
import { FlashMessage } from "../../UI/FlashMessage";
import { H2 } from "../../UI/H2";
import { ReactComponent as ErrorInfoIcon } from "../../UI/icons/error-info.svg";
import { Spacer } from "../../UI/Spacer";
import { authStateAtom, login } from "../../usecases/auth";
import { LoginForm } from "./LoginForm";

export const Login = () => {
  const setAuthState = useUpdateAtom(authStateAtom);
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = async ({ email, password }) => {
    try {
      const loginResult = await login({ email, password });
      if (!loginResult.success) {
        setErrorMessage(
          "Oops! That email and password combination is incorrect."
        );
        return;
      }
      toastSuccess({
        header: "Successful Action!",
        body: "You've been logged in",
      });

      setAuthState({ status: "unauthenticated" });
    } catch {
      setErrorMessage("Oops! Something went wront.");
      toastError({
        header: "Error!",
        body: "Oops, something went wrong. Please try again",
      });
      return;
    }
  };

  return (
    <LoginContainer>
      <LoginContainerChild>
        <img src="/RegisterLoginArtwork.svg" alt="" />
        <img src="/Logo.svg" alt="" />
      </LoginContainerChild>
      <LoginContainerChild>
        <LoginRightsideContent>
          <SmallLogo>
            <img src="/Logo.svg" alt="" />
            <Spacer height="40px" />
          </SmallLogo>
          <H2 login>Welcome Back!</H2>
          <Spacer height="16px" />
          {errorMessage && (
            <FlashMessage icon={<ErrorInfoIcon />}>
              {errorMessage}
              <br />
              <span style={{ fontWeight: "500" }}>Please try again.</span>
            </FlashMessage>
          )}
          <Spacer height="16px" />
          <LoginForm onSubmit={handleLogin} />
        </LoginRightsideContent>
      </LoginContainerChild>
    </LoginContainer>
  );
};

const SmallLogo = styled.div`
  display: none;

  img {
    max-width: 80%;
    margin: 0 auto;
  }

  @media (max-width: 777px) {
    display: block;
  }
`;

const LoginContainer = styled.div`
  display: flex;
  background-color: #f9fafa;
`;

const LoginContainerChild = styled.div`
  flex: 0 50%;
  width: 50vw;
  height: 100vh;

  &:first-child {
    position: relative;

    img:first-child {
      width: 100%;
      object-fit: cover;
      height: 100vh;
    }

    img:nth-child(2) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 90%;
    }

    @media (max-width: 777px) {
      &:first-child {
        display: none;
      }
    }
  }

  &:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;

    @media (max-width: 777px) {
      flex-basis: 100%;
    }
  }
`;

const LoginRightsideContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 424px;

  @media (max-width: 907px) {
    padding: 0 10px;
    margin: unset;
  }
`;
