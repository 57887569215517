import { axios } from "../../utils/axios";

/**
 * @typedef PersonalInformationDto
 * @prop {string} firstName
 * @prop {string} lastName
 * @prop {string} phoneNumber
 * @prop {string} position
 *
 * @typedef BusinessAccountDto
 * @prop {string} name
 * @prop {string} email
 * @prop {string} country
 * @prop {string} state
 * @prop {string} city
 * @prop {string} postalCode
 * @prop {string} street
 *
 * @typedef AccountSettingsDto
 * @prop {string} email
 * @prop {string} password
 *
 * @typedef SignupDto
 * @prop {PersonalInformationDto} personalInformation
 * @prop {BusinessAccountDto} businessAccount
 * @prop {AccountSettingsDto} accountSettings
 *
 * @typedef SignupSuccess
 * @prop {'success'} status
 *
 * @typedef SignupEmailTaken
 * @prop {'email-taken'} status
 *
 * @typedef {SignupSuccess | SignupEmailTaken} SignupResult
 */

/**
 * Signs up a new user/business. A verification email is sent if the signup
 * process was successful.
 * @param {SignupDto} data
 * @returns {Promise<SignupResult>}
 * @throws If something went wrong (network error, unknown response...)
 */
export const signup = async (data) => {
  try {
    await axios.post("/auth/signup", data);
  } catch (err) {
    if (err.response) {
      if (err.response.status === 409) {
        return { status: "email-taken" };
      }
    }

    throw err;
  }

  return { status: "success " };
};

/**
 * @typedef LoginDto
 * @prop {string} email
 * @prop {string} password
 */

/**
 * @typedef LoginResultSuccess
 * @prop {true} success
 * @prop {import('./').User} user
 *
 * @typedef LoginResultFailure
 * @prop {false} success
 *
 * @typedef {LoginResultSuccess | LoginResultFailure} LoginResult
 */

/**
 * Attempts to log in, returning an access token if successful.
 * @param {LoginDto} data
 * @returns {Promise<LoginResult>}
 * @throws If something went wrong with reaching the server and such, such as
 *         a network error.
 */
export const login = async (data) => {
  let response;
  try {
    response = await axios.post("/auth/login", data);
  } catch (err) {
    if (err.response.status === 401) {
      return { success: false };
    }

    throw err;
  }

  if (!response.data.success) {
    return { success: false };
  }

  const user = await currentUser();
  if (!user) {
    throw new Error(
      "User logged in succcessfully, but could not retrieve user info"
    );
  }

  return {
    success: true,
    user,
  };
};

/**
 * Retrieves info about the current user. Returns null if user is not logged in.
 * @returns {Promise<import('./').User | null>}
 * @throws If something went wrong with reaching the server and such, such as
 *         a network error.
 */
export const currentUser = async () => {
  let response;
  try {
    response = await axios.get("/users/me");
  } catch (err) {
    if (err.response.status === 401) {
      return null;
    }
    throw err;
  }

  const userData = response.data;
  return {
    email: userData.email,
    userId: userData._id,
    businessId: userData.bid,
    role: userData.role,
    firstName: userData.firstName,
    lastName: userData.lastName,
    phoneNumber: userData.phoneNumber,
  };
};

/**
 * Logs out the user by tellingt the backend to delete their cookie.
 * @returns {Promise<void>}
 */
export const logout = async () => {
  const response = axios.post("/auth/logout");
  return response;
};

export const resetPassword = async ({ email }) => {
  const response = axios.post("/auth/reset-password", { email });
  return response;
};

export const newPassword = async ({ password, token }) => {
  const response = axios.post("/auth/new-password", { password, token });
  return response;
};

export const acceptInvite = async ({ password, token }) => {
  const response = axios.post("/auth/accept-user-invite", { password, token });
  return response.data;
};
