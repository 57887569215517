import { useAtomValue } from "jotai";
import { useUpdateAtom } from "jotai/utils";
import { useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { ReactComponent as SignOutIcon } from "../../UI/icons/SignOutIcon.svg";
import { ReactComponent as UserIcon } from "../../UI/icons/user.svg";
import { Spacer } from "../../UI/Spacer";
import { authStateAtom, userAtom } from "../../usecases/auth";
import { logout } from "../../usecases/auth/services";
import { toastError, toastSuccess } from "../Toast/Toast";
import { BackLink } from "./BackLink";
import { FetchingIndicator } from "./FetchingIndicator";
import { LogoutConfirmationModal } from "./LogoutConfirmationModal";

export const Header = () => {
  const setAuthState = useUpdateAtom(authStateAtom);
  const user = useAtomValue(userAtom);

  const logoutMutation = useMutation({
    mutationFn: () => logout(),
  });

  const [isLogoutConfirmationModalOpen, setIsLogoutConfirmationModalOpen] =
    useState(false);

  const handleLogoutConfirm = async () => {
    try {
      await logoutMutation.mutateAsync();
      toastSuccess({
        header: "Successful Action!",
        body: "You've been logged out",
      });
      setAuthState({ status: "unauthenticated" });
    } catch (err) {
      toastError({
        header: "Error!",
        body: "Oops, something went wrong. Please try again",
      });
    }
  };

  const handleLogoutCancel = () => {
    setIsLogoutConfirmationModalOpen(false);
  };

  const handleLogoutClick = async () => {
    setIsLogoutConfirmationModalOpen(true);
  };

  return (
    <>
      <LogoutConfirmationModal
        isOpen={isLogoutConfirmationModalOpen}
        onSubmit={handleLogoutConfirm}
        onCancel={handleLogoutCancel}
        isLoading={logoutMutation.isLoading}
      />

      <Root>
        <Content>
          <LeftSide>
            <BackLink />
          </LeftSide>
          <RightSide>
            <User>
              <UserIconWrapper>
                <UserIcon />
              </UserIconWrapper>
              <Spacer width="12px" />
              <UserName>
                {user.firstName} {user.lastName}
              </UserName>
            </User>
            <Spacer width="24px" />
            <Divider />
            <Spacer width="24px" />
            <LogoutButton onClick={handleLogoutClick}>
              <SignOutIcon />
              <Spacer width="4px" />
              Sign out
            </LogoutButton>
          </RightSide>
        </Content>

        <FetchingIndicator />
      </Root>
    </>
  );
};

const Root = styled.header`
  position: relative;
  height: 64px;
  padding: ${(props) => `0 ${props.theme.paddings.contentX}`};
  background: ${(props) => props.theme.colors.gray[0]};
  box-shadow: 0px 1px 0px 0px #d5dce1;
`;
Root.displayName = "Root";

const Content = styled.div`
  margin: 0 auto;
  max-width: ${(props) => props.theme.maxWidths.content};
  height: 100%;
  display: flex;
  align-items: center;
`;
Content.displayName = "Content";

const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;
LeftSide.displayName = "LeftSide";

const RightSide = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
RightSide.displayName = "RightSide";

const User = styled.div`
  display: flex;
  align-items: center;
`;
User.displayName = "User";

const UserIconWrapper = styled.span`
  color: ${(props) => props.theme.colors.dark[200]};
`;
UserIconWrapper.displayName = "UserIconWrapper";

const UserName = styled.span`
  color: ${(props) => props.theme.colors.dark[400]};
  font-family: "Bai Jamjuree";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`;
UserName.displayName = "UserName";

const Divider = styled.hr`
  border-right: 1px solid ${(props) => props.theme.colors.gray[400]};
  opacity: 0.3;
  height: 26px;
`;
Divider.displayName = "Divider";

const LogoutButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4a7eff;
  font-family: "Bai Jamjuree", sans-serif;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
`;
LogoutButton.displayName = "LogoutButton";
