import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useState } from "react";
import { Spacer } from "../../UI/Spacer";
import { InputRadio } from "../../UI/InputRadio";

export const CustomTable = ({
  data,
  columns,
  rows = 14,
  paginated = true,
  globalFilterValue,
  globalFilterFields,
}) => {
  const [filters, setFilters] = useState(initialFiltersState(columns));

  const handleOneOfFilterChange = (column, value) => {
    setFilters((oldFilters) => ({
      ...oldFilters,
      [column.field]: { ...oldFilters[column.field], value },
    }));
  };

  return (
    <DataTable
      value={data}
      paginator={paginated}
      rows={rows}
      globalFilter={globalFilterValue}
      globalFilterFields={globalFilterFields}
      filters={filters}
    >
      {columns.map((column, index) => (
        <Column
          key={`${column.header}/${index}`}
          className={column.className}
          field={column.field}
          body={column.body}
          header={column.header}
          sortable={column.sortable}
          filter={Boolean(column.filter)}
          showFilterMenuOptions={false}
          filterElement={() =>
            column.filter?.type === "oneOf" ? (
              <OneOfFilterMenu
                column={column}
                value={filters[column.field].value}
                onChange={handleOneOfFilterChange}
              />
            ) : null
          }
          showApplyButton={false}
          showClearButton={false}
          headerClassName="custom-table-header"
        />
      ))}
    </DataTable>
  );
};

const OneOfFilterMenu = ({ column, value, onChange }) => {
  const options = [{ label: "All", value: "" }, ...column.filter.options];

  const handleValueChange = (event) => {
    onChange(column, event.target.value);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {options.map((option, index) => (
        <>
          <div
            key={`${option.value}/${index}`}
            style={{ marginLeft: "20px", display: "flex" }}
          >
            <InputRadio
              type="radio"
              name="table-column-filter"
              value={option.value}
              checked={value === option.value}
              onChange={handleValueChange}
            />
            <Spacer width="12px" />
            <span>{option.label}</span>
          </div>
          <Spacer height="12px" />
        </>
      ))}
    </div>
  );
};

const initialFiltersState = (columns) => {
  const filterPerColumnEntries = columns
    .filter((column) => column.field && column.filter)
    .map((column) => [
      column.field,
      initialFilterStateForType(column.filter.type),
    ]);
  return Object.fromEntries(filterPerColumnEntries);
};

const initialFilterStateForType = (type) => {
  switch (type) {
    case "oneOf":
      return { value: "", matchMode: FilterMatchMode.EQUALS };
    default:
      throw new Error(`Invalid filter type: ${type}`);
  }
};
