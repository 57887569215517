import styled from "styled-components";
import { Loading } from "../Loading";

/** @type {React.FC<{ loading: boolean }>} */
export const InputSubmit = ({ children, loading, onClick, ...props }) => {
  const handleClick = (event) => {
    if (loading) {
      event.preventDefault();
      return;
    }

    onClick?.(event);
  };

  return (
    <Root $loading={loading} type="submit" onClick={handleClick} {...props}>
      {loading && (
        <LoadingIndicator>
          <Loading height="48px" />
        </LoadingIndicator>
      )}
      {!loading && children}
    </Root>
  );
};

const Root = styled.button`
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #040e56;
  border-radius: 2px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  font-family: "Bai Jamjuree", sans-serif;
  border: none;
  cursor: ${(props) => (props.$loading ? "wait" : "pointer")};
`;
Root.displayName = "Root";

const LoadingIndicator = styled.div`
  margin-right: 16px;
`;
LoadingIndicator.displayName = "LoadingIndicator";
