import { useRef } from "react";
import { useIsFetching } from "react-query";
import { Transition } from "react-transition-group";
import styled, { css } from "styled-components";
import { Loading } from "../../UI/Loading";

/** @type {React.FC} */
export const FetchingIndicator = () => {
  const fetchesCount = useIsFetching();
  const transitioningNodeRef = useRef(null);

  return (
    <Transition
      in={fetchesCount > 0}
      timeout={transitionDurationMs}
      classNames="transition"
      nodeRef={transitioningNodeRef}
    >
      {(status) => (
        <TransitioningLoadingIndicatorRoot
          $transitionStatus={status}
          ref={transitioningNodeRef}
        >
          <Loading width="48px" height="48px" style={{ margin: "-4px" }} />
        </TransitioningLoadingIndicatorRoot>
      )}
    </Transition>
  );
};

const transitionDurationMs = 500;

const TransitioningLoadingIndicatorRoot = styled.div`
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 80px;
  transform: translateX(-50%);
  color: ${(props) => props.theme.colors.brandBlue};
  background: ${(props) => props.theme.colors.gray[0]};
  border-radius: 50%;
  box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.2);

  ${(props) =>
    props.$transitionStatus === "entering" &&
    css`
      transition: all ${transitionDurationMs}ms
        cubic-bezier(0.67, -0.23, 0.96, 0.93);
      opacity: 1;
      transform: translateX(-50%) scale(1);
    `};
  ${(props) =>
    props.$transitionStatus === "entered" &&
    css`
      opacity: 1;
      transform: translateX(-50%) scale(1);
    `};
  ${(props) =>
    props.$transitionStatus === "exiting" &&
    css`
      transition: all ${transitionDurationMs}ms
        cubic-bezier(0.04, 0.07, 0.33, 1.23);
      opacity: 0;
      transform: translateX(-50%) scale(0);
    `};
  ${(props) =>
    props.$transitionStatus === "exited" &&
    css`
      opacity: 0;
      transform: translateX(-50%) scale(0.8);
    `};
`;
TransitioningLoadingIndicatorRoot.displayName =
  "TransitioningLoadingIndicatorRoot";
