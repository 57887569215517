import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  body {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    background: ${(props) => props.theme.colors.gray[100]};
  }

  html,
  body,
  #root {
    height: 100%;
  }

  img,
  svg {
    display: block;
  }

  /* React Prime DataTable override */

  html .p-datatable .p-datatable-tbody > tr {
    background: transparent;
    transition: 0.3s background-color box-shadow ease-in-out;
  }

  html .p-datatable .p-datatable-tbody > tr:hover {
    background-color: #fff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.07);
    outline: 1px solid #d5dce1;
  }

  html .p-datatable .p-datatable-tbody > tr:hover .visibleOnRowHover {
    opacity: 1;
  }

  html .p-datatable .p-datatable-tbody > tr > td {
    border: 1px solid #d5dce1;
    border-width: 0 0 1px 0;
  }

  html .p-datatable .p-datatable-thead > tr > th {
    border-bottom: 1px solid #d5dce1;
    color: #556575;
  }

  html .p-datatable .p-datatable-thead > tr > th > div > span {
    color: #556575;
  }

  html .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: ${(props) => props.theme.colors.brandDark};
    color: #fff;
  }

  html .p-paginator {
    background: transparent;
    border: none;
    padding-top: 32px;
  }

  .Toastify__toast {
    &.success {
      background-color: #F5FFF9;
      border: 1px solid #56C288;
    }
    &.error {
      background-color: #FFF5F6;
      border: 1px solid #EB3444;
    }
  }

  .p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover, .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    background: unset;
  }

  .toast_container {
    display: flex;
    align-items: center;
    padding: 6px 0;
  }

  .Toastify__toast-container   {
    max-width: 424px;
    width: 100%;
  }

  .toast_icon_container {
    padding: 0 12px;
  }

  .p-column-filter-buttonbar {
    display: none;
  }

  .p-column-filter-overlay {
    border: 1px solid #D5DCE1;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    max-width: 146px;
    }

    .last-visit {
      &:hover {
        background-color: grey
      }
    }

    .custom-table-header .p-column-filter {
      margin-left: 4px;
    }

    .lastVisitColumn {
      &:hover .visibleOnLastVisit {
        opacity: 1;
      }
    }

`;
