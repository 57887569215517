import { Link, useMatchRoute } from "@tanstack/react-location";
import { useAtom } from "jotai";
import { useRef } from "react";
import styled, { css, useTheme } from "styled-components";
import { ReactComponent as ChevronsRightIcon } from "../../UI/icons/chevrons-right.svg";
import { ReactComponent as LogoWithoutText } from "../../UI/images/LogoWithoutText.svg";
import { ReactComponent as LogoWithText } from "../../UI/images/LogoWithText.svg";
import { Spacer } from "../../UI/Spacer";
import { useClickOutside } from "../../utils/hooks/useClickOutside";
import { useDebouncedValue } from "../../utils/hooks/useDebouncedValue";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { sidebarExpandedAtom } from "./state";

/**
 * @typedef NavEntry
 * @prop {string} label
 * @prop {React.ReactNode} icon
 * @prop {string} route
 *
 * @typedef SidebarProps
 * @prop {NavEntry[]} nav
 */

/** @type {React.FC<SidebarProps>} */
export const Sidebar = ({ nav = [] }) => {
  const theme = useTheme();
  const [sidebarExpanded, setSidebarExpanded] = useAtom(sidebarExpandedAtom);
  const debouncedSidebarExpanded = useDebouncedValue(sidebarExpanded, 200);
  const rootRef = useRef(null);
  const { width: windowWidth } = useWindowSize();
  const matchRoute = useMatchRoute();

  const shouldDiplayChildren = sidebarExpanded
    ? true
    : debouncedSidebarExpanded;

  const isLargerScreen = windowWidth >= theme.breakpoints.lg;

  useClickOutside(rootRef, () => {
    if (sidebarExpanded && !isLargerScreen) {
      setSidebarExpanded(false);
    }
  });

  const handleExpandOrCollapse = () => {
    setSidebarExpanded((expanded) => !expanded);
  };

  return (
    <Root ref={rootRef} $expanded={sidebarExpanded}>
      <Overlay $expanded={sidebarExpanded}>
        <LogoContainer>
          {shouldDiplayChildren ? <LogoWithText /> : <LogoWithoutText />}
        </LogoContainer>
        <div style={{ flex: "0 99999 128px", minHeight: "16px" }} />
        <Nav>
          {nav.map((entry) => (
            <NavItem
              key={`${entry.label}__${entry.route}`}
              $active={matchRoute({ to: entry.route, fuzzy: true })}
              to={entry.route}
            >
              {entry.icon}
              {shouldDiplayChildren && (
                <>
                  <Spacer width="16px" />
                  {entry.label}
                </>
              )}
            </NavItem>
          ))}
        </Nav>
        <ExpandCollapseButton
          $expanded={sidebarExpanded}
          onClick={handleExpandOrCollapse}
        >
          <ChevronsRightIcon />
        </ExpandCollapseButton>
      </Overlay>
    </Root>
  );
};

const Root = styled.aside`
  position: relative;
  width: ${(props) => props.theme.widths.sidebarCollapsed};
  height: 100%;
  transition: width 200ms ease-in-out;

  ${(props) => props.theme.breakpoints.up("lg")} {
    width: ${(props) =>
      props.$expanded
        ? props.theme.widths.sidebarExpanded
        : props.theme.widths.sidebarCollapsed};
  }
`;
Root.displayName = "Root";

const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: ${(props) =>
    props.$expanded
      ? props.theme.widths.sidebarExpanded
      : props.theme.widths.sidebarCollapsed};

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 12px 24px;
  background: white;
  box-shadow: 1px 0px 0px #d5dce1;
  transition: width 200ms ease-in-out;
  overflow-x: hidden;

  ${(props) => props.theme.breakpoints.up("lg")} {
    width: 100%;
  }
`;
Overlay.displayName = "Overlay";

const LogoContainer = styled.div`
  width: 100%;
  padding: 0 12px;
`;
LogoContainer.displayName = "LogoContainer";

const Nav = styled.div`
  width: 100%;
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: auto;
  overflow-x: hidden;
`;
Nav.displayName = "Nav";

const NavItem = styled(Link)`
  border-radius: 8px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  font-family: Bai Jamjuree;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: ${(props) => props.theme.colors.dark[200]};
  text-decoration: none;
  transition: background-color 100ms ease-in-out;
  white-space: nowrap;
  min-width: 100%;
  width: max-content;

  &:hover {
    background: ${(props) => props.theme.colors.gray[200]};
  }

  ${(props) =>
    props.$active &&
    css`
      background: ${(props) => props.theme.colors.gray[200]};
      color: ${(props) => props.theme.colors.brandDark};
    `}

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`;
NavItem.displayName = "NavItem";

const ExpandCollapseButton = styled.button`
  align-self: flex-start;
  transform: rotateY(${(props) => (props.$expanded ? "-180deg" : "0")});
  transition: transform 150ms ease-in-out;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 12px;
  margin-bottom: 12px;
`;
ExpandCollapseButton.displayName = "ExpandCollapseButton";
