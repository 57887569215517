import styled from "styled-components";

/**
 *
 * @param {object} props
 * @param {React.ReactNode | undefined} props.icon
 * @param {React.ReactNode} props.children
 */
export const FlashMessage = ({ icon, children }) => {
  return (
    <Root>
      {icon && <Icon>{icon}</Icon>}
      <Content>{children}</Content>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  background: #fff5f6;
  border: 1px solid #ff4757;
  border-radius: 4px;
  padding: 12px;
  display: flex;
`;
Root.displayName = "Root";

const Icon = styled.div`
  margin-right: 10px;
`;
Icon.displayName = "Icon";

const Content = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #ff4757;
`;
Content.displayName = "Content";
