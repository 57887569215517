import { yupResolver } from "@hookform/resolvers/yup";
import { isValidPhoneNumber } from "libphonenumber-js";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import { CountryDropdown } from "../../UI/CountryDropdown";
import { Dropdown } from "../../UI/Dropdown";
import { InputSubmit } from "../../UI/InputSubmit";
import { InputText } from "../../UI/InputText";
import { Paragraph } from "../../UI/Paragraph";
import { Spacer } from "../../UI/Spacer";

export const ClientForm = ({
  action,
  managers,
  clientToEdit,
  onBack,
  onSubmit,
  isLoading,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(formSchema),
    defaultValues: {
      manager: "",
      businessName: "",
      businessEmail: "",
      country: "",
      stateOrProvince: "",
      city: "",
      zip: "",
      address: "",
      contactFirstName: "",
      contactLastName: "",
      contactPhoneNumber: "",
      contactEmail: "",
      contactPosition: "",
      ...clientToEdit,
    },
  });

  const managerOptions = managers.map((manager) => ({
    label: manager.name,
    value: manager.id,
  }));

  const handleBack = (event) => {
    event.preventDefault();
    onBack?.();
  };

  const handleValidSubmit = (clientData) => {
    onSubmit?.(clientData);
  };

  const textField = (name, placeholder) => (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <InputText
          {...field}
          placeholder={placeholder}
          errorMessage={errors[name]?.message}
        />
      )}
    />
  );

  const dropdown = (name, placeholder, options) => (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Dropdown
          {...field}
          options={options}
          placeholder={placeholder}
          errorMessage={errors[name]?.message}
          isSearchable
        />
      )}
    />
  );

  return (
    <FormContainer onSubmit={handleSubmit(handleValidSubmit)}>
      <SpacedParagraph>Manager</SpacedParagraph>
      <Paragraph>Assign a manager for this client account</Paragraph>
      <Spacer height="12px" />
      {dropdown("manager", "Managers", managerOptions)}
      <Spacer height="48px" />
      <SpacedParagraph>BUSINESS INFORMATION</SpacedParagraph>
      <Spacer height="4px" />
      {textField("businessName", "Business name")}
      <Spacer height="12px" />
      {textField("businessEmail", "Business email")}
      <Spacer height="32px" />
      <Controller
        control={control}
        name="country"
        render={({ field }) => (
          <CountryDropdown
            {...field}
            placeholder="Country"
            errorMessage={errors["country"]?.message}
          />
        )}
      />
      <Spacer height="12px" />
      {textField("stateOrProvince", "State/Province")}
      <Spacer height="12px" />
      <Spacer height="12px" />
      <div style={{ display: "flex" }}>
        <div style={{ flex: "1 1 auto" }}>{textField("city", "City")}</div>
        <div style={{ flex: "0 1 144px", marginLeft: "8px" }}>
          {textField("zip", "Zip/Postal Code")}
        </div>
      </div>
      <Spacer height="12px" />
      {textField("address", "Address")}
      <Spacer height="64px" />
      <SpacedParagraph>CONTACT INFORMATION</SpacedParagraph>
      <Spacer height="12px" />
      <div style={{ display: "flex" }}>
        <div style={{ flex: "1", marginRight: "4px" }}>
          {textField("contactFirstName", "First name")}
        </div>
        <div style={{ flex: "1", marginLeft: "4px" }}>
          {textField("contactLastName", "Last name")}
        </div>
      </div>
      <Spacer height="12px" />
      {textField(
        "contactPhoneNumber",
        "Contact phone number (e.g. +1 819 555 5555)"
      )}
      <Spacer height="12px" />
      {textField("contactEmail", "Contact email")}
      <Spacer height="12px" />
      {textField("contactPosition", "Position/Title")}
      <Spacer height="12px" />
      <ButtonContainer>
        <BackButton onClick={handleBack}>Back</BackButton>
        <InputSubmit loading={isLoading}>
          {action === "add" && "Add Client"}
          {action === "edit" && "Save Changes"}
        </InputSubmit>
      </ButtonContainer>
    </FormContainer>
  );
};

const formSchema = yup.object({
  manager: yup.string().required("Required"),
  businessName: yup.string().required("Required"),
  businessEmail: yup.string().email("Invalid email").required("Required"),
  country: yup.string().required("Required"),
  stateOrProvince: yup.string().required("Required"),
  city: yup.string().required("Required"),
  zip: yup.string().required("Required"),
  address: yup.string().required("Required"),
  contactFirstName: yup.string().required("Required"),
  contactLastName: yup.string().required("Required"),
  contactPhoneNumber: yup
    .string()
    .required("Required")
    .test("is-phone-number", "Invalid phone number", (value) =>
      isValidPhoneNumber(value)
    ),
  contactEmail: yup.string().email("Invalid email").required("Required"),
  contactPosition: yup.string(),
});

/* Styles */

const FormContainer = styled.form`
  max-width: 424px;
`;

const SpacedParagraph = styled.p`
  text-transform: uppercase;
  line-height: 20px;
  letter-spacing: 0.06em;
  color: #556575;
  font-family: "Bai Jamjuree";
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const BackButton = styled.button`
  background-color: #e5edff;
  border: 2px solid rgba(4, 14, 86, 0.07);
  padding: 16px 32px;
  margin-right: 8px;
  cursor: pointer;
`;
