import { useMatch } from "@tanstack/react-location";
import React from "react";
import styled from "styled-components";
import { Hero } from "./Hero/index";
import { Spacer } from "../../UI/Spacer";
import { DailySummariesHeading } from "./Hero";
import { useTrapDetailsReport } from "../../usecases/reports";
import { CustomTable } from "../../components/CustomTable";
import { ReactComponent as BatteryEmptyIcon } from "../../UI/icons/BatteryEmptyIcon.svg";
import { ReactComponent as BatteryLowIcon } from "../../UI/icons/BatteryLowIcon.svg";
import { ReactComponent as BatteryFullIcon } from "../../UI/icons/BatteryFullIcon.svg";
import { ReactComponent as BatteryMediumIcon } from "../../UI/icons/BatteryMediumIcon.svg";
import { ReactComponent as ExcellentSignal } from "../../UI/icons/ExcellentSignal.svg";
import { ReactComponent as FairSignal } from "../../UI/icons/FairSignal.svg";
import { ReactComponent as GoodSignal } from "../../UI/icons/GoodSignal.svg";
import { ReactComponent as PoorSignal } from "../../UI/icons/PoorSignal.svg";
import { Link } from "@tanstack/react-location";

export const TrapDetails = () => {
  const {
    params: { trapImei },
  } = useMatch();
  const { data: trapDetails, isLoading: isLoadingTrapDetails } =
    useTrapDetailsReport(trapImei);

  if (isLoadingTrapDetails) {
    return null;
  }

  return (
    <>
      <HeroContainer>
        <Hero trapDetails={trapDetails} />
        <Spacer height="75px" />
      </HeroContainer>
      <Container>
        <DailySummariesHeading />
        <CustomTable
          data={trapDetails.days}
          columns={[
            {
              header: "Date",
              field: "date",
              sortable: true,
              body: (summary) => summary.date,
            },
            {
              header: " Battery",
              body: (summary) => {
                if (summary.batteryLevel === "dead") {
                  return <BatteryEmptyIcon />;
                } else if (summary.batteryLevel === "low") {
                  return <BatteryLowIcon />;
                } else if (summary.batteryLevel === "medium") {
                  return <BatteryMediumIcon />;
                } else if (summary.batteryLevel === "full") {
                  return <BatteryFullIcon />;
                }
              },
            },
            {
              header: "Cell Signal",
              body: (summary) => {
                if (summary.signalLevel === "poor") {
                  return <PoorSignal />;
                } else if (summary.signalLevel === "fair") {
                  return <FairSignal />;
                } else if (summary.signalLevel === "good") {
                  return <GoodSignal />;
                } else if (summary.signalLevel === "excellent") {
                  return <ExcellentSignal />;
                }
              },
            },
            {
              header: "Bait Supply",
              body: () => "N/A",
            },
            {
              field: "activations",
              header: "Activations",
              body: (summary) => summary.activations,
            },
            {
              body: (summary) => (
                <LastColumnWrapper>
                  <InternalLink to={summary.date}>Details</InternalLink>
                </LastColumnWrapper>
              ),
            },
          ]}
        />
      </Container>
    </>
  );
};

const Container = styled.div`
  background-color: ${(props) => `${props.theme.colors.gray[100]}`};
  padding: 102px 32px 32px 32px;
`;

const HeroContainer = styled.div`
  background: #f1f3f5;
`;

const InternalLink = styled(Link)`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4a7eff;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
`;

const LastColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;

  svg {
    flex-shrink: 0;
  }
`;
