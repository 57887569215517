import { Dropdown as PrimeDropdown } from "primereact/dropdown";
import { forwardRef } from "react";
import styled, { createGlobalStyle, css } from "styled-components";
import { InputError } from "../InputError";
import { Spacer } from "../Spacer";

export const Dropdown = forwardRef(
  (
    {
      options,
      value,
      placeholder,
      onChange,
      onBlur,
      name,
      errorMessage,
      isSearchable,
      renderItem,
      renderValue,
      readOnly,
    },
    ref
  ) => {
    return (
      <>
        <PanelGlobalStyle />
        <StyledPrimeDropdown
          ref={ref}
          options={options}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          filter={isSearchable}
          panelClassName="catchdata-prime-react-dropdown-panel"
          itemTemplate={renderItem}
          valueTemplate={renderValue}
          $readOnly={readOnly}
          dropdownIcon={readOnly ? "" : undefined}
          disabled={readOnly}
        />
        {errorMessage && (
          <>
            <Spacer height="4px" />
            <InputError>{errorMessage}</InputError>
          </>
        )}
      </>
    );
  }
);

const StyledPrimeDropdown = styled(PrimeDropdown)`
  padding: 0 8px 0 24px;
  width: 100%;
  height: 48px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #d5dce1;

  .p-dropdown-label {
    font-family: "Bai Jamjuree", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #748494;
    line-height: 16px;
    display: flex;
    align-items: center;
    padding: 0;
  }

  ${(props) =>
    props.$readOnly &&
    css`
      background: transparent;
      opacity: 1;

      &,
      & * {
        pointer-events: auto;
        cursor: text !important;
        user-select: text;
      }
    `}
`;

const PanelGlobalStyle = createGlobalStyle`
    .catchdata-prime-react-dropdown-panel {
        .p-dropdown-items  {
            border: 1px solid #D5DCE1;
            border-top: none;

            li {
                padding: 7px 8px 7px 24px;
            }
        }

        .p-dropdown-item {
            transition: 0.3s background-color ease-in-out;
            background: #fff;
            font-size: 14px;

            &:hover {
                background-color: #DDDDDD;
            }
        }

        .p-dropdown-filter {
          height: 24px;
        }
    }
`;
