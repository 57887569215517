import { axios } from "../../utils/axios";
import { currentTimezone } from "../../utils/timezone";

export const getClientsReport = async () => {
  const response = await axios.get("/reports/clients");
  return response.data;
};

/**
 * @param {string} id
 */
export const getClientDetailsReport = async (id) => {
  const response = await axios.get(`/reports/client/${id}`);
  return response.data;
};

/**
 * @param {string} id
 */
export const getPropertyDetailsReport = async (id) => {
  const response = await axios.get(`/reports/property/${id}`);
  return response.data;
};

/**
 * @param {string} imei
 */
export const getTrapDetailsReport = async (imei) => {
  const response = await axios.get(
    `/reports/trap/${imei}?timezone=${currentTimezone()}`
  );
  return response.data;
};

/**
 * @param {string} imei
 * @param {string} date Date in YYYY-MM-DD format
 */
export const getTrapDetailsForDateReport = async (imei, date) => {
  const response = await axios.get(
    `/reports/trap/details/${imei}/${date}?timezone=${currentTimezone()}`
  );
  return response.data;
};
