import { useAtom } from "jotai";
import { useEffect } from "react";
import { useUpdateRef } from "../../utils/hooks/useUpdateRef";
import { currentUser } from "./services";
import { authStateAtom } from "./state";

/**
 * @typedef AuthenticationGuardProps
 * @prop {(() => void) | undefined} onFailed Called if authentication failed (something went wrong).
 * @prop {(() => void) | undefined} onLoggedOut Called if the client is logged out.
 * @prop {(() => void) | undefined} onLoggedIn Called if the client is logged in.
 */
/** @type {React.FC<AuthenticationGuardProps>} */
export const AuthenticationGuard = ({
  children,
  onFailed,
  onLoggedOut,
  onLoggedIn,
}) => {
  const [authState, setAuthState] = useAtom(authStateAtom);
  const onFailedRef = useUpdateRef(onFailed);
  const onLoggedOutRef = useUpdateRef(onLoggedOut);
  const onLoggedInRef = useUpdateRef(onLoggedIn);

  useEffect(() => {
    if (authState.status !== "unauthenticated") {
      return;
    }

    setAuthState({ status: "authenticating" });

    (async () => {
      try {
        const user = await currentUser();
        if (!user) {
          setAuthState({ status: "authenticated", user: null });
          onLoggedOutRef.current?.();
          return;
        }
        setAuthState({ status: "authenticated", user });
        onLoggedInRef.current?.();
      } catch {
        setAuthState({ status: "authenticated", user: null });
        onFailedRef.current?.();
      }
    })();
  }, [
    setAuthState,
    authState.status,
    onFailedRef,
    onLoggedOutRef,
    onLoggedInRef,
  ]);

  if (authState.status !== "authenticated") {
    return null;
  }

  return <>{children}</>;
};
