import { useMatch } from "@tanstack/react-location";
import React, { useState } from "react";
import styled from "styled-components";
import { Spacer } from "../../UI/Spacer";
import {
  useClientDetailsReport,
  useInvalidateClientDetailsReport,
} from "../../usecases/reports";
import { Hero, PropertiesCTA } from "./Hero";
import { CustomTable } from "../../components/CustomTable";
import { ReactComponent as CheckMark } from "../../UI/icons/CheckMark.svg";
import { ReactComponent as EditIcon } from "../../UI/icons/EditIcon.svg";
import { ReactComponent as NotManagedIcon } from "../../UI/icons/NotManagedIcon.svg";
import { useNavigateWithPreviousRoute } from "../../utils/hooks/useNavigateWithPreviousRoute";
import { Link } from "@tanstack/react-location";
import { EditLastVisitModal } from "../../components/EditLastVisitModal";
import { useMutation } from "react-query";
import { updatePropertyLastVisit } from "../../usecases/properties";
import { toastError, toastSuccess } from "../../components/Toast/Toast";
import { format, parseISO } from "date-fns";

export const ClientDetails = () => {
  const navigate = useNavigateWithPreviousRoute();
  const {
    params: { clientId },
  } = useMatch();
  const { data: clientDetails } = useClientDetailsReport(clientId);

  console.log(clientDetails);

  const invalidateClientDetailsReport =
    useInvalidateClientDetailsReport(clientId);

  const updateLastVisitMutation = useMutation({
    mutationFn: ({ id, date }) => updatePropertyLastVisit(id, date),
    onSuccess: () => invalidateClientDetailsReport(),
  });

  const [lastVisitModalOpenFor, setLastVisitModalOpenFor] = useState(null);

  const handleLastVisitClick = (propertyId) => {
    setLastVisitModalOpenFor(propertyId);
  };

  const handleLastVisitModalRequestClose = () => {
    setLastVisitModalOpenFor(null);
  };

  const handleLastVisitModalSubmit = async ({ date }) => {
    try {
      await updateLastVisitMutation.mutateAsync({
        id: lastVisitModalOpenFor,
        date,
      });
      toastSuccess({
        header: "Successful Action!",
        body: "Successfully updated the last visit date!",
      });
      setLastVisitModalOpenFor(null);
    } catch (err) {
      toastError({
        header: "Error!",
        body: "Oops, something went wrong. Please try again",
      });
    }
  };

  const handleEditClick = (propertyId) => {
    navigate({ to: `${propertyId}/edit` });
  };

  return (
    <>
      <EditLastVisitModal
        isOpen={lastVisitModalOpenFor !== null}
        isLoading={updateLastVisitMutation.isLoading}
        onRequestClose={handleLastVisitModalRequestClose}
        onSubmit={handleLastVisitModalSubmit}
      />

      <HeroContainer>
        <Hero clientDetails={clientDetails} />
        <Spacer height="75px" />
      </HeroContainer>
      <Container>
        <PropertiesCTA />
        <CustomTable
          data={clientDetails.properties.map((clientDetail) => ({
            ...clientDetail,
            managedStatus: deriveManagedStatus(clientDetail),
            streetAdress: `${clientDetail.street}, ${clientDetail.city}`,
          }))}
          columns={[
            {
              header: "Name",
              field: "name",
              sortable: true,
            },
            {
              header: "Managed",
              field: "managedStatus",
              body: (clientDetail) => {
                if (clientDetail.managedStatus === "managed") {
                  return (
                    <ManagedWrapper>
                      <CheckMark />{" "}
                    </ManagedWrapper>
                  );
                } else if (clientDetail.managedStatus === "unmanaged") {
                  return (
                    <ManagedWrapper>
                      <NotManagedIcon />
                    </ManagedWrapper>
                  );
                }
              },
              filter: {
                type: "oneOf",
                options: [
                  {
                    label: "Yes",
                    value: "managed",
                  },
                  {
                    label: "No",
                    value: "unmanaged",
                  },
                ],
              },
            },
            {
              header: "Location",
              field: "streetAdress",
              sortable: true,
            },
            {
              header: "Traps",
              field: "trapsCount",
              sortable: true,
            },
            {
              field: "activationsPastDay",
              header: "Past 24 hour activations",
              sortable: true,
            },
            {
              field: "activationsSinceLastVisit",
              header: "Activations Since Last Visit",
              sortable: true,
            },
            {
              header: "Last visit",
              className: "lastVisitColumn",
              field: "lastVisit",
              sortable: true,
              body: (property) => (
                <div style={{ display: "flex" }}>
                  {property.lastVisit === null
                    ? "-"
                    : format(parseISO(property.lastVisit), "dd/MM/yyyy")}
                  <Spacer width="34px" />
                  <LastVisitButton
                    className="visibleOnLastVisit"
                    onClick={() => handleLastVisitClick(property._id)}
                  >
                    Edit
                  </LastVisitButton>
                </div>
              ),
            },
            {
              body: (property) => (
                <LastColumnWrapper>
                  <EditButton
                    className="visibleOnRowHover"
                    onClick={() => handleEditClick(property._id)}
                  >
                    <EditIcon />
                  </EditButton>
                  <Spacer width="12px" />
                  <InternalLink to={property._id}>Details</InternalLink>
                </LastColumnWrapper>
              ),
            },
          ]}
        />
      </Container>
    </>
  );
};

const deriveManagedStatus = (clientDetail) => {
  if (!clientDetail.managed) {
    return "unmanaged";
  }
  if (clientDetail.managed) {
    return "managed";
  }
};

const Container = styled.div`
  background-color: ${(props) => `${props.theme.colors.gray[100]}`};
  padding: 102px 32px 32px 32px;
`;

const HeroContainer = styled.div`
  background: #f1f3f5;
`;

const LastVisitButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4a7eff;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  opacity: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: 0.3s opacity ease-in-out;
`;

const EditButton = styled.button`
  opacity: 0;
  background-color: transparent;
  border: none;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  transition: 0.3s background-color ease-in-out;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    position: absolute;
    content: "Edit";
    top: -12px;
    font-size: 10px;
    color: #fff;
    width: 29px;
    height: 16px;
    background: linear-gradient(91.98deg, #0d1026 3.35%, #19204d 96.65%);
    border-radius: 4px;
    font-family: "Roboto", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  &:hover {
    background-color: #f1f3f5;

    &::before {
      opacity: 1;
    }
  }
`;

const InternalLink = styled(Link)`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4a7eff;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
`;

const LastColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;

  svg {
    flex-shrink: 0;
  }
`;

const ManagedWrapper = styled.div`
  margin-left: 10px;
`;
