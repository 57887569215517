import { useRouter } from "@tanstack/react-location";
import { useEffect } from "react";

export const PageTitleFromLocation = () => {
  const matches = useRouter().state.matches;
  const matchesToConsider = matches.filter((match) => match.route.meta?.nav);
  const allMatchesResolved = matchesToConsider.every(
    (match) => match.status === "resolved"
  );
  const lastMatch = matchesToConsider[matchesToConsider.length - 1];
  const lastMatchLabel = allMatchesResolved
    ? lastMatch?.route.meta.nav.label(lastMatch)
    : undefined;

  useEffect(() => {
    if (!allMatchesResolved || !lastMatchLabel) {
      return;
    }

    document.title = `${process.env.REACT_APP_TITLE} - ${lastMatchLabel}`;
  }, [allMatchesResolved, lastMatchLabel]);

  return null;
};
