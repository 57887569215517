import styled from "styled-components";

export const H2 = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #040e56;
  font-family: "Bai Jamjuree";
`;
