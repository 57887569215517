export const countryList = [
  { name: "Afghanistan", code2: "AF", code3: "AFG" },
  { name: "Aland Islands", code2: "AX", code3: "ALA" },
  { name: "Albania", code2: "AL", code3: "ALB" },
  { name: "Algeria", code2: "DZ", code3: "DZA" },
  { name: "American Samoa", code2: "AS", code3: "ASM" },
  { name: "Andorra", code2: "AD", code3: "AND" },
  { name: "Angola", code2: "AO", code3: "AGO" },
  { name: "Anguilla", code2: "AI", code3: "AIA" },
  { name: "Antarctica", code2: "AQ", code3: "ATA" },
  { name: "Antigua and Barbuda", code2: "AG", code3: "ATG" },
  { name: "Argentina", code2: "AR", code3: "ARG" },
  { name: "Armenia", code2: "AM", code3: "ARM" },
  { name: "Aruba", code2: "AW", code3: "ABW" },
  { name: "Australia", code2: "AU", code3: "AUS" },
  { name: "Austria", code2: "AT", code3: "AUT" },
  { name: "Azerbaijan", code2: "AZ", code3: "AZE" },
  { name: "Bahamas", code2: "BS", code3: "BHS" },
  { name: "Bahrain", code2: "BH", code3: "BHR" },
  { name: "Bangladesh", code2: "BD", code3: "BGD" },
  { name: "Barbados", code2: "BB", code3: "BRB" },
  { name: "Belarus", code2: "BY", code3: "BLR" },
  { name: "Belgium", code2: "BE", code3: "BEL" },
  { name: "Belize", code2: "BZ", code3: "BLZ" },
  { name: "Benin", code2: "BJ", code3: "BEN" },
  { name: "Bermuda", code2: "BM", code3: "BMU" },
  { name: "Bhutan", code2: "BT", code3: "BTN" },
  { name: "Bolivia", code2: "BO", code3: "BOL" },
  { name: "Bonaire, Sint Eustatius and Saba", code2: "BQ", code3: "BES" },
  { name: "Bosnia and Herzegovina", code2: "BA", code3: "BIH" },
  { name: "Botswana", code2: "BW", code3: "BWA" },
  { name: "Bouvet Island", code2: "BV", code3: "BVT" },
  { name: "Brazil", code2: "BR", code3: "BRA" },
  { name: "British Indian Ocean Territory", code2: "IO", code3: "IOT" },
  { name: "Brunei Darussalam", code2: "BN", code3: "BRN" },
  { name: "Bulgaria", code2: "BG", code3: "BGR" },
  { name: "Burkina Faso", code2: "BF", code3: "BFA" },
  { name: "Burundi", code2: "BI", code3: "BDI" },
  { name: "Cambodia", code2: "KH", code3: "KHM" },
  { name: "Cameroon", code2: "CM", code3: "CMR" },
  { name: "Canada", code2: "CA", code3: "CAN" },
  { name: "Cape Verde", code2: "CV", code3: "CPV" },
  { name: "Cayman Islands", code2: "KY", code3: "CYM" },
  { name: "Central African Republic", code2: "CF", code3: "CAF" },
  { name: "Chad", code2: "TD", code3: "TCD" },
  { name: "Chile", code2: "CL", code3: "CHL" },
  { name: "China", code2: "CN", code3: "CHN" },
  { name: "Christmas Island", code2: "CX", code3: "CXR" },
  { name: "Cocos (Keeling) Islands", code2: "CC", code3: "CCK" },
  { name: "Colombia", code2: "CO", code3: "COL" },
  { name: "Comoros", code2: "KM", code3: "COM" },
  { name: "Congo", code2: "CG", code3: "COG" },
  {
    name: "Congo, Democratic Republic of the Congo",
    code2: "CD",
    code3: "COD",
  },
  { name: "Cook Islands", code2: "CK", code3: "COK" },
  { name: "Costa Rica", code2: "CR", code3: "CRI" },
  { name: "Cote D'Ivoire", code2: "CI", code3: "CIV" },
  { name: "Croatia", code2: "HR", code3: "HRV" },
  { name: "Cuba", code2: "CU", code3: "CUB" },
  { name: "Curacao", code2: "CW", code3: "CUW" },
  { name: "Cyprus", code2: "CY", code3: "CYP" },
  { name: "Czech Republic", code2: "CZ", code3: "CZE" },
  { name: "Denmark", code2: "DK", code3: "DNK" },
  { name: "Djibouti", code2: "DJ", code3: "DJI" },
  { name: "Dominica", code2: "DM", code3: "DMA" },
  { name: "Dominican Republic", code2: "DO", code3: "DOM" },
  { name: "Ecuador", code2: "EC", code3: "ECU" },
  { name: "Egypt", code2: "EG", code3: "EGY" },
  { name: "El Salvador", code2: "SV", code3: "SLV" },
  { name: "Equatorial Guinea", code2: "GQ", code3: "GNQ" },
  { name: "Eritrea", code2: "ER", code3: "ERI" },
  { name: "Estonia", code2: "EE", code3: "EST" },
  { name: "Ethiopia", code2: "ET", code3: "ETH" },
  { name: "Falkland Islands (Malvinas)", code2: "FK", code3: "FLK" },
  { name: "Faroe Islands", code2: "FO", code3: "FRO" },
  { name: "Fiji", code2: "FJ", code3: "FJI" },
  { name: "Finland", code2: "FI", code3: "FIN" },
  { name: "France", code2: "FR", code3: "FRA" },
  { name: "French Guiana", code2: "GF", code3: "GUF" },
  { name: "French Polynesia", code2: "PF", code3: "PYF" },
  { name: "French Southern Territories", code2: "TF", code3: "ATF" },
  { name: "Gabon", code2: "GA", code3: "GAB" },
  { name: "Gambia", code2: "GM", code3: "GMB" },
  { name: "Georgia", code2: "GE", code3: "GEO" },
  { name: "Germany", code2: "DE", code3: "DEU" },
  { name: "Ghana", code2: "GH", code3: "GHA" },
  { name: "Gibraltar", code2: "GI", code3: "GIB" },
  { name: "Greece", code2: "GR", code3: "GRC" },
  { name: "Greenland", code2: "GL", code3: "GRL" },
  { name: "Grenada", code2: "GD", code3: "GRD" },
  { name: "Guadeloupe", code2: "GP", code3: "GLP" },
  { name: "Guam", code2: "GU", code3: "GUM" },
  { name: "Guatemala", code2: "GT", code3: "GTM" },
  { name: "Guernsey", code2: "GG", code3: "GGY" },
  { name: "Guinea", code2: "GN", code3: "GIN" },
  { name: "Guinea-Bissau", code2: "GW", code3: "GNB" },
  { name: "Guyana", code2: "GY", code3: "GUY" },
  { name: "Haiti", code2: "HT", code3: "HTI" },
  { name: "Heard Island and Mcdonald Islands", code2: "HM", code3: "HMD" },
  { name: "Holy See (Vatican City State)", code2: "VA", code3: "VAT" },
  { name: "Honduras", code2: "HN", code3: "HND" },
  { name: "Hong Kong", code2: "HK", code3: "HKG" },
  { name: "Hungary", code2: "HU", code3: "HUN" },
  { name: "Iceland", code2: "IS", code3: "ISL" },
  { name: "India", code2: "IN", code3: "IND" },
  { name: "Indonesia", code2: "ID", code3: "IDN" },
  { name: "Iran, Islamic Republic of", code2: "IR", code3: "IRN" },
  { name: "Iraq", code2: "IQ", code3: "IRQ" },
  { name: "Ireland", code2: "IE", code3: "IRL" },
  { name: "Isle of Man", code2: "IM", code3: "IMN" },
  { name: "Israel", code2: "IL", code3: "ISR" },
  { name: "Italy", code2: "IT", code3: "ITA" },
  { name: "Jamaica", code2: "JM", code3: "JAM" },
  { name: "Japan", code2: "JP", code3: "JPN" },
  { name: "Jersey", code2: "JE", code3: "JEY" },
  { name: "Jordan", code2: "JO", code3: "JOR" },
  { name: "Kazakhstan", code2: "KZ", code3: "KAZ" },
  { name: "Kenya", code2: "KE", code3: "KEN" },
  { name: "Kiribati", code2: "KI", code3: "KIR" },
  { name: "Korea, Democratic People's Republic of", code2: "KP", code3: "PRK" },
  { name: "Korea, Republic of", code2: "KR", code3: "KOR" },
  { name: "Kosovo", code2: "XK", code3: "XKX" },
  { name: "Kuwait", code2: "KW", code3: "KWT" },
  { name: "Kyrgyzstan", code2: "KG", code3: "KGZ" },
  { name: "Lao People's Democratic Republic", code2: "LA", code3: "LAO" },
  { name: "Latvia", code2: "LV", code3: "LVA" },
  { name: "Lebanon", code2: "LB", code3: "LBN" },
  { name: "Lesotho", code2: "LS", code3: "LSO" },
  { name: "Liberia", code2: "LR", code3: "LBR" },
  { name: "Libyan Arab Jamahiriya", code2: "LY", code3: "LBY" },
  { name: "Liechtenstein", code2: "LI", code3: "LIE" },
  { name: "Lithuania", code2: "LT", code3: "LTU" },
  { name: "Luxembourg", code2: "LU", code3: "LUX" },
  { name: "Macao", code2: "MO", code3: "MAC" },
  {
    name: "Macedonia, the Former Yugoslav Republic of",
    code2: "MK",
    code3: "MKD",
  },
  { name: "Madagascar", code2: "MG", code3: "MDG" },
  { name: "Malawi", code2: "MW", code3: "MWI" },
  { name: "Malaysia", code2: "MY", code3: "MYS" },
  { name: "Maldives", code2: "MV", code3: "MDV" },
  { name: "Mali", code2: "ML", code3: "MLI" },
  { name: "Malta", code2: "MT", code3: "MLT" },
  { name: "Marshall Islands", code2: "MH", code3: "MHL" },
  { name: "Martinique", code2: "MQ", code3: "MTQ" },
  { name: "Mauritania", code2: "MR", code3: "MRT" },
  { name: "Mauritius", code2: "MU", code3: "MUS" },
  { name: "Mayotte", code2: "YT", code3: "MYT" },
  { name: "Mexico", code2: "MX", code3: "MEX" },
  { name: "Micronesia, Federated States of", code2: "FM", code3: "FSM" },
  { name: "Moldova, Republic of", code2: "MD", code3: "MDA" },
  { name: "Monaco", code2: "MC", code3: "MCO" },
  { name: "Mongolia", code2: "MN", code3: "MNG" },
  { name: "Montenegro", code2: "ME", code3: "MNE" },
  { name: "Montserrat", code2: "MS", code3: "MSR" },
  { name: "Morocco", code2: "MA", code3: "MAR" },
  { name: "Mozambique", code2: "MZ", code3: "MOZ" },
  { name: "Myanmar", code2: "MM", code3: "MMR" },
  { name: "Namibia", code2: "NA", code3: "NAM" },
  { name: "Nauru", code2: "NR", code3: "NRU" },
  { name: "Nepal", code2: "NP", code3: "NPL" },
  { name: "Netherlands", code2: "NL", code3: "NLD" },
  { name: "Netherlands Antilles", code2: "AN", code3: "ANT" },
  { name: "New Caledonia", code2: "NC", code3: "NCL" },
  { name: "New Zealand", code2: "NZ", code3: "NZL" },
  { name: "Nicaragua", code2: "NI", code3: "NIC" },
  { name: "Niger", code2: "NE", code3: "NER" },
  { name: "Nigeria", code2: "NG", code3: "NGA" },
  { name: "Niue", code2: "NU", code3: "NIU" },
  { name: "Norfolk Island", code2: "NF", code3: "NFK" },
  { name: "Northern Mariana Islands", code2: "MP", code3: "MNP" },
  { name: "Norway", code2: "NO", code3: "NOR" },
  { name: "Oman", code2: "OM", code3: "OMN" },
  { name: "Pakistan", code2: "PK", code3: "PAK" },
  { name: "Palau", code2: "PW", code3: "PLW" },
  { name: "Palestinian Territory, Occupied", code2: "PS", code3: "PSE" },
  { name: "Panama", code2: "PA", code3: "PAN" },
  { name: "Papua New Guinea", code2: "PG", code3: "PNG" },
  { name: "Paraguay", code2: "PY", code3: "PRY" },
  { name: "Peru", code2: "PE", code3: "PER" },
  { name: "Philippines", code2: "PH", code3: "PHL" },
  { name: "Pitcairn", code2: "PN", code3: "PCN" },
  { name: "Poland", code2: "PL", code3: "POL" },
  { name: "Portugal", code2: "PT", code3: "PRT" },
  { name: "Puerto Rico", code2: "PR", code3: "PRI" },
  { name: "Qatar", code2: "QA", code3: "QAT" },
  { name: "Reunion", code2: "RE", code3: "REU" },
  { name: "Romania", code2: "RO", code3: "ROM" },
  { name: "Russian Federation", code2: "RU", code3: "RUS" },
  { name: "Rwanda", code2: "RW", code3: "RWA" },
  { name: "Saint Barthelemy", code2: "BL", code3: "BLM" },
  { name: "Saint Helena", code2: "SH", code3: "SHN" },
  { name: "Saint Kitts and Nevis", code2: "KN", code3: "KNA" },
  { name: "Saint Lucia", code2: "LC", code3: "LCA" },
  { name: "Saint Martin", code2: "MF", code3: "MAF" },
  { name: "Saint Pierre and Miquelon", code2: "PM", code3: "SPM" },
  { name: "Saint Vincent and the Grenadines", code2: "VC", code3: "VCT" },
  { name: "Samoa", code2: "WS", code3: "WSM" },
  { name: "San Marino", code2: "SM", code3: "SMR" },
  { name: "Sao Tome and Principe", code2: "ST", code3: "STP" },
  { name: "Saudi Arabia", code2: "SA", code3: "SAU" },
  { name: "Senegal", code2: "SN", code3: "SEN" },
  { name: "Serbia", code2: "RS", code3: "SRB" },
  { name: "Seychelles", code2: "SC", code3: "SYC" },
  { name: "Sierra Leone", code2: "SL", code3: "SLE" },
  { name: "Singapore", code2: "SG", code3: "SGP" },
  { name: "Sint Maarten", code2: "SX", code3: "SXM" },
  { name: "Slovakia", code2: "SK", code3: "SVK" },
  { name: "Slovenia", code2: "SI", code3: "SVN" },
  { name: "Solomon Islands", code2: "SB", code3: "SLB" },
  { name: "Somalia", code2: "SO", code3: "SOM" },
  { name: "South Africa", code2: "ZA", code3: "ZAF" },
  {
    name: "South Georgia and the South Sandwich Islands",
    code2: "GS",
    code3: "SGS",
  },
  { name: "South Sudan", code2: "SS", code3: "SSD" },
  { name: "Spain", code2: "ES", code3: "ESP" },
  { name: "Sri Lanka", code2: "LK", code3: "LKA" },
  { name: "Sudan", code2: "SD", code3: "SDN" },
  { name: "Suriname", code2: "SR", code3: "SUR" },
  { name: "Svalbard and Jan Mayen", code2: "SJ", code3: "SJM" },
  { name: "Swaziland", code2: "SZ", code3: "SWZ" },
  { name: "Sweden", code2: "SE", code3: "SWE" },
  { name: "Switzerland", code2: "CH", code3: "CHE" },
  { name: "Syrian Arab Republic", code2: "SY", code3: "SYR" },
  { name: "Taiwan, Province of China", code2: "TW", code3: "TWN" },
  { name: "Tajikistan", code2: "TJ", code3: "TJK" },
  { name: "Tanzania, United Republic of", code2: "TZ", code3: "TZA" },
  { name: "Thailand", code2: "TH", code3: "THA" },
  { name: "Timor-Leste", code2: "TL", code3: "TLS" },
  { name: "Togo", code2: "TG", code3: "TGO" },
  { name: "Tokelau", code2: "TK", code3: "TKL" },
  { name: "Tonga", code2: "TO", code3: "TON" },
  { name: "Trinidad and Tobago", code2: "TT", code3: "TTO" },
  { name: "Tunisia", code2: "TN", code3: "TUN" },
  { name: "Turkey", code2: "TR", code3: "TUR" },
  { name: "Turkmenistan", code2: "TM", code3: "TKM" },
  { name: "Turks and Caicos Islands", code2: "TC", code3: "TCA" },
  { name: "Tuvalu", code2: "TV", code3: "TUV" },
  { name: "Uganda", code2: "UG", code3: "UGA" },
  { name: "Ukraine", code2: "UA", code3: "UKR" },
  { name: "United Arab Emirates", code2: "AE", code3: "ARE" },
  { name: "United Kingdom", code2: "GB", code3: "GBR" },
  { name: "United States", code2: "US", code3: "USA" },
  { name: "United States Minor Outlying Islands", code2: "UM", code3: "UMI" },
  { name: "Uruguay", code2: "UY", code3: "URY" },
  { name: "Uzbekistan", code2: "UZ", code3: "UZB" },
  { name: "Vanuatu", code2: "VU", code3: "VUT" },
  { name: "Venezuela", code2: "VE", code3: "VEN" },
  { name: "Viet Nam", code2: "VN", code3: "VNM" },
  { name: "Virgin Islands, British", code2: "VG", code3: "VGB" },
  { name: "Virgin Islands, U.s.", code2: "VI", code3: "VIR" },
  { name: "Wallis and Futuna", code2: "WF", code3: "WLF" },
  { name: "Western Sahara", code2: "EH", code3: "ESH" },
  { name: "Yemen", code2: "YE", code3: "YEM" },
  { name: "Zambia", code2: "ZM", code3: "ZMB" },
  { name: "Zimbabwe", code2: "ZW", code3: "ZWE" },
];

/**
 * @param {string} code3
 */
export const countryForDisplay = (code3) =>
  countryList.find((country) => country.code3 === code3)?.name ?? code3;
