import React from "react";
import { ReactComponent as ErrorIcon } from "../../UI/icons/ToastError.svg";
import { ReactComponent as SuccessIcon } from "../../UI/icons/ToastSuccess.svg";

export const Toast = ({ header, body, type }) => {
  return (
    <div className="toast_container">
      <div className="toast_icon_container">
        {type === "error" ? <ErrorIcon /> : <SuccessIcon />}
      </div>
      <div>
        <h3 style={{ color: type === "error" ? "#99000D" : "#003C1C" }}>
          {header}
        </h3>
        <p style={{ color: type === "error" ? "#99000D" : "#003C1C" }}>
          {body}
        </p>
      </div>
    </div>
  );
};
