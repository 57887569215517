import { useEffect, useRef, useState } from "react";

/**
 * Returns `value` when `value` hasn't changed for `delay` milliseconds. If it
 * did change in that time, returns the old value until updates stop.
 * @template T
 * @param {T} value
 * @param {number} delay
 */
export const useDebouncedValue = (value, delay) => {
  const [debouncedState, setDebouncedState] = useState(value);
  const timeoutRef = useRef(
    /** @type {ReturnType<typeof setTimeout> | undefined} */ (undefined)
  );

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setDebouncedState(value);
    }, delay);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [value, delay]);

  return debouncedState;
};
