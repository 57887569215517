import { Link, useMatch } from "@tanstack/react-location";
import { format, parseISO } from "date-fns";
import React, { useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { CustomTable } from "../../components/CustomTable";
import { EditLastVisitModal } from "../../components/EditLastVisitModal";
import { toastError, toastSuccess } from "../../components/Toast/Toast";
import { ReactComponent as BatteryEmptyIcon } from "../../UI/icons/BatteryEmptyIcon.svg";
import { ReactComponent as BatteryFullIcon } from "../../UI/icons/BatteryFullIcon.svg";
import { ReactComponent as BatteryLowIcon } from "../../UI/icons/BatteryLowIcon.svg";
import { ReactComponent as BatteryMediumIcon } from "../../UI/icons/BatteryMediumIcon.svg";
import { ReactComponent as ExcellentSignal } from "../../UI/icons/ExcellentSignal.svg";
import { ReactComponent as FairSignal } from "../../UI/icons/FairSignal.svg";
import { ReactComponent as GoodSignal } from "../../UI/icons/GoodSignal.svg";
import { ReactComponent as PoorSignal } from "../../UI/icons/PoorSignal.svg";
import { ReactComponent as RemoveIcon } from "../../UI/icons/RemoveIcon.svg";
import { Spacer } from "../../UI/Spacer";
import {
  useInvalidatePropertyDetailsReport,
  usePropertyDetailsReport,
} from "../../usecases/reports";
import {
  assignTrap,
  unassignTrap,
  updateTrapLastVisit,
  useInvalidateTrapsList,
} from "../../usecases/traps";
import { AddTrapModal } from "./AddTrapModal";
import { Hero, IndividualPropertyCTA } from "./Hero";
import { RemoveTrapModal } from "./RemoveTrapModal";

export const PropertyDetails = () => {
  const {
    params: { propertyId },
  } = useMatch();
  const { data: propertyDetails, isLoading: isLoadingPropertyDetails } =
    usePropertyDetailsReport(propertyId);
  const invalidatePropertyDetailsReport =
    useInvalidatePropertyDetailsReport(propertyId);
  const invalidateTrapsList = useInvalidateTrapsList();

  const assignTrapMutation = useMutation({
    mutationFn: (data) => assignTrap(data),
    onSuccess: () =>
      Promise.all([invalidatePropertyDetailsReport(), invalidateTrapsList()]),
  });
  const unassignTrapMutation = useMutation({
    mutationFn: (data) => unassignTrap(data),
    onSuccess: () =>
      Promise.all([invalidatePropertyDetailsReport(), invalidateTrapsList()]),
  });
  const updateLastVisitMutation = useMutation({
    mutationFn: ({ id, date }) => updateTrapLastVisit(id, date),
    onSuccess: () =>
      Promise.all([invalidatePropertyDetailsReport(), invalidateTrapsList()]),
  });

  const [isAddTrapModalOpen, setIsAddTrapModalOpen] = useState(false);
  const [confirmingTrapRemovalForId, setConfirmingTrapRemovalForId] =
    useState(null);
  const [lastVisitModalOpenFor, setLastVisitModalOpenFor] = useState(null);

  const handleAddTrapSubmit = async ({ trap }) => {
    try {
      await assignTrapMutation.mutateAsync({ trapId: trap, propertyId });
      toastSuccess({
        header: "Successful Action!",
        body: "Successfully assigned a trap to this property!",
      });
      setIsAddTrapModalOpen(false);
    } catch (err) {
      toastError({
        header: "Error!",
        body: "Oops, something went wrong. Please try again",
      });
    }
  };

  const handleLastVisitClick = (trapId) => {
    setLastVisitModalOpenFor(trapId);
  };

  const handleLastVisitRequestClose = () => {
    setLastVisitModalOpenFor(null);
  };

  const handleLastVisitModalSubmit = async ({ date }) => {
    try {
      await updateLastVisitMutation.mutateAsync({
        id: lastVisitModalOpenFor,
        date,
      });
      toastSuccess({
        header: "Successful Action!",
        body: "Successfully updated the last visit date!",
      });
      setLastVisitModalOpenFor(null);
    } catch (err) {
      toastError({
        header: "Error!",
        body: "Oops, something went wrong. Please try again",
      });
    }
  };

  const handleRemoveTrapSubmit = async () => {
    try {
      await unassignTrapMutation.mutateAsync({
        trapId: confirmingTrapRemovalForId,
      });
      toastSuccess({
        header: "Successful Action!",
        body: "Successfully removed a trap from this property!",
      });
      setConfirmingTrapRemovalForId(null);
    } catch (err) {
      toastError({
        header: "Error!",
        body: "Oops, something went wrong. Please try again",
      });
    }
  };

  if (isLoadingPropertyDetails) {
    return null;
  }

  return (
    <>
      <EditLastVisitModal
        isOpen={lastVisitModalOpenFor !== null}
        isLoading={updateLastVisitMutation.isLoading}
        onRequestClose={handleLastVisitRequestClose}
        onSubmit={handleLastVisitModalSubmit}
      />

      <AddTrapModal
        isOpen={isAddTrapModalOpen}
        onSubmit={handleAddTrapSubmit}
        onCancel={() => setIsAddTrapModalOpen(false)}
        isLoading={assignTrapMutation.isLoading}
      />
      <RemoveTrapModal
        isOpen={confirmingTrapRemovalForId !== null}
        onSubmit={handleRemoveTrapSubmit}
        onCancel={() => setConfirmingTrapRemovalForId(null)}
        isLoading={unassignTrapMutation.isLoading}
      />

      <HeroContainer>
        <Hero
          propertyDetails={propertyDetails}
          onAddTrapClick={() => setIsAddTrapModalOpen(true)}
        />
        <Spacer height="75px" />
      </HeroContainer>
      <Container>
        <IndividualPropertyCTA
          onAddTrapClick={() => setIsAddTrapModalOpen(true)}
        />
        <CustomTable
          data={propertyDetails.traps}
          columns={[
            {
              header: "Trap #",
              field: "name",
            },
            {
              header: "Battery",
              body: (trap) => {
                if (trap.batteryLevel === "dead") {
                  return <BatteryEmptyIcon />;
                } else if (trap.batteryLevel === "low") {
                  return <BatteryLowIcon />;
                } else if (trap.batteryLevel === "medium") {
                  return <BatteryMediumIcon />;
                } else if (trap.batteryLevel === "full") {
                  return <BatteryFullIcon />;
                } else if (!trap.batteryLevel) {
                  return "-";
                }
              },
            },
            {
              header: "Cell Signal",
              body: (trap) => {
                if (trap.signalLevel === "poor") {
                  return <PoorSignal />;
                } else if (trap.signalLevel === "fair") {
                  return <FairSignal />;
                } else if (trap.signalLevel === "good") {
                  return <GoodSignal />;
                } else if (trap.signalLevel === "excellent") {
                  return <ExcellentSignal />;
                } else if (!trap.signalLevel) {
                  return "-";
                }
              },
            },
            {
              header: "Bait Supply",
              body: () => "N/A",
            },
            {
              header: "Activations Since Last Visit",
              body: (trap) => trap.activationsSinceLastVisit,
            },
            {
              header: "Last Visit",
              className: "lastVisitColumn",
              body: (trap) => (
                <div style={{ display: "flex" }}>
                  {trap.lastVisit === null
                    ? "-"
                    : format(parseISO(trap.lastVisit), "dd/MM/yyyy")}
                  <Spacer width="34px" />
                  <LastVisitButton
                    className="visibleOnLastVisit"
                    onClick={() => handleLastVisitClick(trap._id)}
                  >
                    Edit
                  </LastVisitButton>
                </div>
              ),
            },
            {
              body: (trap) => (
                <LastColumnWrapper>
                  <RemoveButton
                    className="visibleOnRowHover"
                    onClick={() => setConfirmingTrapRemovalForId(trap._id)}
                  >
                    <RemoveIcon />
                  </RemoveButton>
                  <Spacer width="12px" />
                  <InternalLink to={trap.imei}>Details</InternalLink>
                </LastColumnWrapper>
              ),
            },
          ]}
        />
      </Container>
    </>
  );
};

const Container = styled.div`
  background-color: ${(props) => `${props.theme.colors.gray[100]}`};
  padding: 102px 32px 32px 32px;
`;

const HeroContainer = styled.div`
  background: #f1f3f5;
`;

const LastVisitButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4a7eff;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  opacity: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: 0.3s opacity ease-in-out;
`;

const RemoveButton = styled.button`
  opacity: 0;
  background-color: transparent;
  border: none;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  transition: 0.3s background-color ease-in-out;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    position: absolute;
    content: "Unassign";
    top: -12px;
    font-size: 10px;
    color: #fff;
    background: linear-gradient(91.98deg, #0d1026 3.35%, #19204d 96.65%);
    border-radius: 4px;
    font-family: "Roboto", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-out;
    padding: 4px 8px;
  }

  &:hover {
    background-color: #f1f3f5;

    &::before {
      opacity: 1;
    }
  }
`;

const InternalLink = styled(Link)`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4a7eff;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
`;

const LastColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;

  svg {
    flex-shrink: 0;
  }
`;
