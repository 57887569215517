import React from "react";
import { H3 } from "../../UI/H3";
import styled from "styled-components";
import { Spacer } from "../../UI/Spacer";
import { InputText } from "../../UI/InputText";
import { Link, useMatch, useNavigate } from "@tanstack/react-location";
import {
  updateUser,
  useInvalidateUserDetails,
  useInvalidateUsersList,
  useUserDetails,
} from "../../usecases/users";
import * as yup from "yup";
import { isValidPhoneNumber } from "libphonenumber-js";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { useBackLink } from "../../utils/hooks/useBackLink";
import { toastError, toastSuccess } from "../../components/Toast/Toast";
import { InputSubmit } from "../../UI/InputSubmit";

export const TeamMemberDetails = () => {
  const {
    params: { userId },
  } = useMatch();
  const { data: userDetails, isLoading: isLoadingUserDetails } =
    useUserDetails(userId);

  if (isLoadingUserDetails) {
    return null;
  }

  return <TeamMemberDetailsInner userDetails={userDetails} userId={userId} />;
};

/**
 * Exists so that `useForm` will be called only when `userDetails` have been loaded.
 */
export const TeamMemberDetailsInner = ({ userDetails, userId }) => {
  const navigate = useNavigate();
  const { path: backPath } = useBackLink();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      ...userDetails,
    },
  });

  const invalidateUsersList = useInvalidateUsersList();
  const invalidateUserDetails = useInvalidateUserDetails(userId);

  const updateUserMutation = useMutation({
    mutationFn: ({ id, data }) => updateUser(id, data),
    onSuccess: () =>
      Promise.all([invalidateUsersList(), invalidateUserDetails()]),
  });

  const handleValidSubmit = async (data) => {
    try {
      await updateUserMutation.mutateAsync({ id: userId, data });
      toastSuccess({
        header: "Successful Action!",
        body: "Successfully edited team member's information!",
      });
      navigate({ to: backPath });
    } catch (err) {
      toastError({
        header: "Error!",
        body: "Oops, something went wrong. Please try again",
      });
    }
  };

  const textField = (name, placeholder, readOnly) => (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <InputText
          {...field}
          placeholder={placeholder}
          errorMessage={errors[name]?.message}
          readOnly={readOnly}
        />
      )}
    />
  );

  return (
    <Container>
      <Spacer height="32px" />
      <H3>Team member details</H3>
      <form onSubmit={handleSubmit(handleValidSubmit)}>
        <Spacer height="32px" />
        <div style={{ display: "flex" }}>
          <div style={{ flex: "1", marginRight: "4px" }}>
            {textField("firstName", "First Name")}
          </div>
          <div style={{ flex: "1", marginLeft: "4px" }}>
            {textField("lastName", "Last Name")}
          </div>
        </div>
        <Spacer height="12px" />
        {textField("phoneNumber", "Phone number (e.g. +1 819 555 5555)")}
        <Spacer height="12px" />
        {textField("email", "Email", "readOnly")}
        <Spacer height="57px" />
        <div style={{ display: "flex" }}>
          <ButtonLink to={backPath}>Close</ButtonLink>
          <Spacer width="8px" />
          <InputSubmit loading={updateUserMutation.isLoading}>Save</InputSubmit>
        </div>
      </form>
    </Container>
  );
};

const formSchema = yup.object({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  phoneNumber: yup
    .string()
    .required("Required")
    .test("is-phone-number", "Invalid phone number", (value) =>
      isValidPhoneNumber(value)
    ),
  email: yup.string().email("Invalid email").required("Required"),
});

const ButtonLink = styled(Link)`
  display: flex;
  max-width: 150px;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  background-color: #e5edff;
  border: 2px solid rgba(4, 14, 86, 0.07);
  padding: 16px 32px;
`;

const Container = styled.div`
  max-width: 424px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
`;
