import React from "react";
import styled from "styled-components";
import { NewPasswordForm } from "./NewPasswordForm";

export const NewPassword = () => {
  return (
    <NewPasswordContainer>
      <NewPasswordContainerChild>
        <img src="/RegisterLoginArtwork.svg" alt="" />
        <img src="/Logo.svg" alt="" />
      </NewPasswordContainerChild>
      <NewPasswordContainerChild>
        <NewPasswordForm />
      </NewPasswordContainerChild>
    </NewPasswordContainer>
  );
};

/* Styles */

const NewPasswordContainer = styled.div`
  display: flex;
  background-color: #f9fafa;
`;

const NewPasswordContainerChild = styled.div`
  flex: 0 50%;
  width: 50vw;
  height: 100vh;

  &:first-child {
    position: relative;

    img:first-child {
      width: 100%;
      object-fit: cover;
      height: 100vh;
    }

    img:nth-child(2) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 90%;
    }

    @media (max-width: 777px) {
      &:first-child {
        display: none;
      }
    }
  }

  &:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    padding: 30px 0;

    @media (max-width: 777px) {
      flex-basis: 100%;
    }
  }
`;
