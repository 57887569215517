import { axios } from "../../utils/axios";

/**
 * @returns {Promise<Client[]>}
 */
export const getClients = async () => {
  const response = await axios.get("/clients");
  return response.data.map((client) => ({
    id: client._id,
    name: client.name,
    email: client.email,
    managerName: client.manager,
    trapCount: 0,
    isActive: true,
  }));
};

export const getClientDetails = async (id) => {
  const response = await axios.get(`/clients/${id}`);
  return response.data;
};

export const addNewClient = async ({
  // Listing all properties for documentation's sake
  name,
  email,
  address: { street, city, postalCode, state, country },
  contact: { firstName, lastName, position, phoneNumber, email: contactEmail },
  managerId,
}) => {
  await axios.post("/clients", {
    name,
    email,
    address: {
      street,
      city,
      postalCode,
      state,
      country,
    },
    contact: {
      firstName,
      lastName,
      position,
      phoneNumber,
      email: contactEmail,
    },
    manager: managerId,
  });
};

export const updateClient = async (
  id,
  {
    // Listing all properties for documentation's sake
    name,
    email,
    address: { street, city, postalCode, state, country },
    contact: {
      firstName,
      lastName,
      position,
      phoneNumber,
      email: contactEmail,
    },
    managerId,
  }
) => {
  await axios.patch(`/clients/${id}`, {
    name,
    email,
    address: {
      street,
      city,
      postalCode,
      state,
      country,
    },
    contact: {
      firstName,
      lastName,
      position,
      phoneNumber,
      email: contactEmail,
    },
    manager: managerId,
  });
};
