import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { Modal } from "../../components/Modal";
import { Dropdown } from "../../UI/Dropdown";
import { InputSubmit } from "../../UI/InputSubmit";
import { Loading } from "../../UI/Loading";
import { Spacer } from "../../UI/Spacer";
import { useAllTraps } from "../../usecases/traps";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const AddTrapModal = ({ isOpen, onSubmit, onCancel, isLoading }) => (
  <Modal isOpen={isOpen} onRequestClose={onCancel} heading="Add Trap">
    {isOpen && (
      <ModalInner
        onSubmit={onSubmit}
        onCancel={onCancel}
        isLoading={isLoading}
      />
    )}
  </Modal>
);

/**
 * Exists because we want to `useAllTraps` only when the modal is open
 */
const ModalInner = ({ onSubmit, onCancel, isLoading }) => {
  const { data: traps, isLoading: isLoadingTraps, isError } = useAllTraps();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
    defaultValues: {
      trap: "",
    },
  });

  const handleValidSubmit = (data) => {
    onSubmit(data);
  };

  if (isLoadingTraps) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Loading height="96px" width="96px" />;
      </div>
    );
  }
  if (isError) {
    return "Oops, something went wrong. Please try again.";
  }

  const unassignedTraps = traps.filter((trap) => !trap.assignedAt);
  const trapOptions = unassignedTraps.map((trap) => ({
    label: `${trap.serialNumber} (IMEI: ${trap.imei})`,
    value: trap._id,
  }));

  return (
    <form onSubmit={handleSubmit(handleValidSubmit)}>
      <Controller
        control={control}
        name="trap"
        render={({ field }) => (
          <Dropdown
            {...field}
            options={trapOptions}
            placeholder="Choose a Trap"
            errorMessage={errors["trap"]?.message}
            isSearchable
          />
        )}
      />
      <Spacer height="56px" />
      <ButtonsContainer>
        <BackButton onClick={onCancel}>Close</BackButton>
        <InputSubmit loading={isLoading}>Add Trap</InputSubmit>
      </ButtonsContainer>
    </form>
  );
};

const formSchema = yup.object({
  trap: yup.string().required("Required"),
});

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 257px;
  margin: 0 auto;
`;

const BackButton = styled.button`
  background-color: #e5edff;
  border: 2px solid rgba(4, 14, 86, 0.07);
  padding: 16px 32px;
  margin-right: 8px;
  cursor: pointer;
`;
