import { Link } from "@tanstack/react-location";
import styled from "styled-components";
import { Spacer } from "../Spacer";

/**
 * @typedef BreadcrumbsItem
 * @prop {React.ReactNode | undefined} icon
 * @prop {string} label
 * @prop {string} path
 *
 * @typedef BreadcrumbsProps
 * @prop {BreadcrumbsItem[]} items
 */

/** @type {React.FC<BreadcrumbsProps>} */
export const Breadcrumbs = ({ items }) => {
  return (
    <Root>
      {items.map((item, index) => (
        <Item key={item.path}>
          {item.icon && (
            <Icon>
              {item.icon}
              <Spacer width="6px" />
            </Icon>
          )}
          {index < items.length - 1 ? (
            <>
              <ItemLink to={item.path}>{item.label}</ItemLink>
              <Spacer width="6px" />
              <Slash>/</Slash>
              <Spacer width="6px" />
            </>
          ) : (
            <ItemText>{item.label}</ItemText>
          )}
        </Item>
      ))}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
`;
Root.displayName = "Root";

const Item = styled.div`
  display: flex;
  align-items: center;
`;
Item.displayName = "Item";

const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.dark[200]};
`;

const ItemLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.brandBlue};
`;
ItemLink.displayName = "ItemLink";

const ItemText = styled.span`
  color: ${(props) => props.theme.colors.dark[700]};
`;

const Slash = styled.span`
  color: ${(props) => props.theme.colors.dark[200]};
`;
