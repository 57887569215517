import styled, { css } from "styled-components";
import { ReactComponent as ErrorInfoIcon } from "../icons/error-info.svg";
import { useState } from "react";

export const InputError = ({ children }) => {
  // `useState` instead of `useRef` because we want this component to re-render
  // when it's mounted.
  const [content, setContent] = useState(undefined);

  return (
    <Root $contentHeight={content?.scrollHeight}>
      <Icon>
        <ErrorInfoIcon width="10px" height="10px" />
      </Icon>
      <Content ref={(ref) => setContent(ref)}>{children}</Content>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  height: 0;
  opacity: 0;
  transition: all 300ms ease-in-out;

  ${(props) =>
    props.$contentHeight &&
    css`
      height: ${(props) => props.$contentHeight}px;
      opacity: 1;
    `}
`;
Root.displayName = "Root";

const Icon = styled.div`
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
Icon.displayName = "Icon";

const Content = styled.div`
  color: #ff4757;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
`;
Content.displayName = "Content";
