import React from "react";
import styled from "styled-components";
import { Spacer } from "../../../UI/Spacer";
import { InputText } from "../../../UI/InputText";
import { InputSubmit } from "../../../UI/InputSubmit";
import { Paragraph } from "../../../UI/Paragraph";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { resetPassword } from "../../../usecases/auth";
import { toastSuccess, toastError } from "../../../components/Toast/Toast";
import { Link, useNavigate } from "@tanstack/react-location";

export const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  const mutation = useMutation({
    mutationFn: (data) => resetPassword(data),
  });

  const handleValidSubmit = async (data) => {
    try {
      await mutation.mutateAsync(data);
      navigate({ to: "/login", replace: true });
      toastSuccess({
        header: "Successful Action!",
        body: "An email has been sent, check your Inbox.",
      });
    } catch (err) {
      if (err.response?.status === 404) {
        toastError({
          header: "Error!",
          body: "A user with that email doesn't exist",
        });
        return;
      }

      toastError({
        header: "Error!",
        body: "Oops, something went wrong. Please try again",
      });
    }
  };

  return (
    <ResetPasswordFormContainer onSubmit={handleSubmit(handleValidSubmit)}>
      <SmallLogo>
        <img src="/Logo.svg" alt="" />
        <Spacer height="40px" />
      </SmallLogo>
      <Paragraph>
        Enter your email and we'll send you a link to reset your password.
      </Paragraph>
      <Spacer height="30px" />
      <Controller
        control={control}
        name={"email"}
        render={({ field }) => (
          <InputText
            {...field}
            placeholder="example@email.com"
            errorMessage={errors["email"]?.message}
          />
        )}
      />
      <Spacer height="32px" />
      <InputSubmit loading={mutation.isLoading}>Send link to email</InputSubmit>
      <div style={{ textAlign: "center", marginTop: "16px" }}>
        <BackToLoginLink to="/login">Back to login</BackToLoginLink>
      </div>
    </ResetPasswordFormContainer>
  );
};

const formSchema = yup.object({
  email: yup.string().email("Invalid email").required("Required"),
});

/* Styles */

const BackToLoginLink = styled(Link)`
  font-family: Bai Jamjuree;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #4a7eff;
  text-decoration: none;
`;

const SmallLogo = styled.div`
  display: none;

  img {
    max-width: 80%;
    margin: 0 auto;
  }

  @media (max-width: 777px) {
    display: block;
  }
`;

const ResetPasswordFormContainer = styled.form`
  max-width: 424px;

  @media (max-width: 500px) {
    padding: 0 10px;
  }
`;
