import { formatISO } from "date-fns";
import { axios } from "../../utils/axios";

export const getProperties = async () => {
  const response = await axios.get("/properties");
  return response.data;
};

export const getPropertyDetails = async (id) => {
  const response = await axios.get(`/properties/${id}`);
  return response.data;
};

export const addProperty = async ({
  // Listing all properties for documentation's sake
  clientId,
  name,
  address: { street, city, postalCode, state, country },
  contact: { firstName, lastName, position, phoneNumber, email },
  coordinates: { latitude, longitude },
}) => {
  await axios.post("/properties", {
    client: clientId,
    name,
    address: {
      street,
      city,
      postalCode,
      state,
      country,
    },
    contact: {
      firstName,
      lastName,
      position,
      phoneNumber,
      email,
    },
    coordinates: {
      latitude,
      longitude,
    },
  });
};

export const updateProperty = async (
  id,
  {
    // Listing all properties for documentation's sake
    clientId,
    name,
    lastVisit,
    address: { street, city, postalCode, state, country },
    contact: { firstName, lastName, position, phoneNumber, email },
    coordinates: { latitude, longitude },
  }
) => {
  await axios.patch(`/properties/${id}`, {
    client: clientId,
    name,
    address: {
      street,
      city,
      postalCode,
      state,
      country,
    },
    contact: {
      firstName,
      lastName,
      position,
      phoneNumber,
      email,
    },
    coordinates: {
      latitude,
      longitude,
    },
    lastVisit,
  });
};

/**
 *
 * @param {string} id
 * @param {Date} lastVisitDate
 * @returns
 */
export const updatePropertyLastVisit = async (id, lastVisitDate) => {
  const response = await axios.patch(`/properties/${id}`, {
    lastVisit: formatISO(lastVisitDate, { representation: "date" }),
  });
  return response.data;
};
