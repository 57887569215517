import { countryList } from "../../utils/countries";
import { Dropdown } from "../Dropdown";
import * as countryFlagComponents from "country-flag-icons/react/3x2";
import { Spacer } from "../Spacer";
import { forwardRef } from "react";

const countryListOptions = countryList.map((countryData) => ({
  ...countryData,
  value: countryData.code3,
  label: countryData.name,
}));

const renderItem = (option) => {
  const Flag = countryFlagComponents[option.code2];

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {Flag && (
        <>
          <Flag width="22px" height="15px" />
          <Spacer width="12px" />
        </>
      )}
      <span>{option.name}</span>
    </div>
  );
};

const renderValue = (option, props) => {
  if (!option) {
    return <span>{props.placeholder}</span>;
  }

  return renderItem(option);
};

export const CountryDropdown = forwardRef((props, ref) => (
  <Dropdown
    isSearchable
    {...props}
    ref={ref}
    options={countryListOptions}
    renderItem={renderItem}
    renderValue={renderValue}
  />
));
