import React, { useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { CustomTable } from "../../components/CustomTable";
import { toastError, toastSuccess } from "../../components/Toast/Toast";
import { Button } from "../../UI/Button";
import { H3 } from "../../UI/H3";
import { ReactComponent as RoundPlusIcon } from "../../UI/icons/RoundPlusIcon.svg";
import { ReactComponent as TrapsIcon } from "../../UI/icons/TrapsIcon.svg";
import { InputText } from "../../UI/InputText";
import { Spacer } from "../../UI/Spacer";
import {
  useAllTraps,
  useInvalidateTrapsList,
  registerTrap,
  removeTrap,
} from "../../usecases/traps";
import { RegisterNewTrapModal } from "./RegisterNewTrapModal";
import { RemoveTrapModal } from "./RemoveTrapModal";
import { ReactComponent as RemoveIcon } from "../../UI/icons/RemoveIcon.svg";

export const TrapInventory = () => {
  const { data: traps, isLoading: isLoadingTraps } = useAllTraps();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [confirmingRemovalForId, setConfirmingRemovalForId] = useState(null);

  const invalidateTrapsList = useInvalidateTrapsList();

  const registerTrapMutation = useMutation({
    mutationFn: (data) => registerTrap(data),
    onSuccess: () => invalidateTrapsList(),
  });
  const removeTrapMutation = useMutation({
    mutationFn: (data) => removeTrap(data),
    onSuccess: () => invalidateTrapsList(),
  });

  const [isRegisterNewTrapModalOpen, setIsRegisterNewTrapModalOpen] =
    useState(false);

  const handleRegisterNewTrapClick = () => {
    setIsRegisterNewTrapModalOpen(true);
  };

  const handleNewTrapCancel = () => {
    setIsRegisterNewTrapModalOpen(false);
  };

  const handleNewTrapSubmit = async (data) => {
    try {
      await registerTrapMutation.mutateAsync(data);
      toastSuccess({
        header: "Successful Action!",
        body: "Successfully added a new trap to the inventory.",
      });
      setIsRegisterNewTrapModalOpen(false);
    } catch {
      toastError({
        header: "Error!",
        body: "Oops, something went wrong. Please try again",
      });
    }
  };

  const handleGlobalFilterValueChange = (event) => {
    setGlobalFilterValue(event.target.value);
  };

  const handleRemoveClick = (trapId) => setConfirmingRemovalForId(trapId);
  const handleTrapRemovalCancel = () => setConfirmingRemovalForId(null);
  const handleTrapRemovalSubmit = async () => {
    try {
      await removeTrapMutation.mutateAsync({
        trapId: confirmingRemovalForId,
      });
      toastSuccess({
        header: "Successful Action!",
        body: "Successfully removed the trap from the inventory.",
      });
      setConfirmingRemovalForId(null);
    } catch {
      toastError({
        header: "Error!",
        body: "Oops, something went wrong. Please try again",
      });
    }
  };

  if (isLoadingTraps) {
    return null;
  }

  return (
    <>
      <RegisterNewTrapModal
        isOpen={isRegisterNewTrapModalOpen}
        onSubmit={handleNewTrapSubmit}
        onCancel={handleNewTrapCancel}
        isLoading={registerTrapMutation.isLoading}
      />
      <RemoveTrapModal
        isOpen={confirmingRemovalForId !== null}
        onSubmit={handleTrapRemovalSubmit}
        onCancel={handleTrapRemovalCancel}
        isLoading={removeTrapMutation.isLoading}
      />

      <FlexWrapper>
        <Content>
          <Hero>
            <HeroChild>
              <TrapsIcon />
              <Spacer width="10px" />
              <H3>Trap Inventory</H3>
              <Spacer width="18px" />
              <InputContainer>
                <InputText
                  placeholder="Search"
                  value={globalFilterValue}
                  onChange={handleGlobalFilterValueChange}
                />
              </InputContainer>
              <Spacer width="24px" />
            </HeroChild>
            <Button onClick={handleRegisterNewTrapClick}>
              <RoundPlusIcon />
              Register New Trap
            </Button>
          </Hero>
          <Spacer height="40px" />
          <CustomTable
            data={traps.map((trap) => ({
              ...trap,
              trapStatus: deriveTrapStatus(trap),
            }))}
            globalFilterValue={globalFilterValue}
            globalFilterFields={["serialNumber", "imei"]}
            columns={[
              {
                header: "Serial #",
                field: "serialNumber",
                sortable: true,
              },
              {
                header: "IMEI #",
                field: "imei",
                sortable: true,
              },
              {
                header: "Client",
                field: "client",
                body: (trap) => {
                  if (!trap.client) {
                    return "-";
                  } else if (trap.client) {
                    return trap.client;
                  }
                },
                sortable: true,
              },
              {
                header: "Status",
                field: "trapStatus",
                body: (trap) => {
                  if (trap.trapStatus === "assigned") {
                    return <StyledStatus assigned>Assigned</StyledStatus>;
                  } else if (trap.trapStatus === "available") {
                    return <StyledStatus>Available</StyledStatus>;
                  }
                },
                filter: {
                  type: "oneOf",
                  options: [
                    {
                      label: "Available",
                      value: "available",
                    },
                    {
                      label: "Assigned",
                      value: "assigned",
                    },
                  ],
                },
              },
              {
                body: (trap) => (
                  <LastColumnWrapper>
                    <RemoveButton
                      className="visibleOnRowHover"
                      onClick={() => handleRemoveClick(trap._id)}
                    >
                      <RemoveIcon />
                    </RemoveButton>
                  </LastColumnWrapper>
                ),
              },
            ]}
          />
        </Content>
      </FlexWrapper>
    </>
  );
};

const deriveTrapStatus = (trap) => {
  if (!trap.assignedAt) {
    return "available";
  }
  if (trap.assignedAt) {
    return "assigned";
  }
};

const FlexWrapper = styled.div`
  background-color: ${(props) => `${props.theme.colors.gray[100]}`};
  padding: ${(props) => `32px ${props.theme.paddings.contentX}`};
`;

const Content = styled.div`
  margin: 0 auto;
  max-width: ${(props) => props.theme.maxWidths.content};
`;

const Hero = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeroChild = styled.div`
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
  }
`;

const InputContainer = styled.div`
  max-width: 200px;
`;

const StyledStatus = styled.p`
  padding: 4px 8px 6px 19px;
  border: ${(props) =>
    props.assigned ? "1px solid #B7C2CC" : "1px solid #56c288"};
  border-radius: 25px;
  width: fit-content;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: ${(props) => (props.assigned ? "#B7C2CC" : "#56c288")};
    border-radius: 50%;
    left: 8px;
    top: calc(50% + 1px);
    transform: translateY(-50%);
  }
`;

const RemoveButton = styled.button`
  opacity: 0;
  background-color: transparent;
  border: none;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  transition: 0.3s background-color ease-in-out;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    position: absolute;
    content: "Remove";
    top: -12px;
    font-size: 10px;
    color: #fff;
    background: linear-gradient(91.98deg, #0d1026 3.35%, #19204d 96.65%);
    border-radius: 4px;
    font-family: "Roboto", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-out;
    padding: 4px 8px;
  }

  &:hover {
    background-color: #f1f3f5;

    &::before {
      opacity: 1;
    }
  }
`;

const LastColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;

  svg {
    flex-shrink: 0;
  }
`;
