import axiosStatic from "axios";

export const axios = axiosStatic.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});
// Use for testing UX - add artifical delay on every request
// axios.interceptors.response.use(async (response) => {
//   await new Promise((resolve) => setTimeout(resolve, 1000));
//   return response;
// });
