import { axios } from "../../utils/axios";

/**
 * @returns {Promise<Client[]>}
 */
export const getAllBusinessAccounts = async () => {
  const response = await axios.get("/businessaccounts");
  return response.data;
};

export const getBusinessAccount = async (id) => {
  const response = await axios.get(`/businessaccounts/${id}`);
  return response.data;
};
