import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearch } from "@tanstack/react-location";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import styled from "styled-components";
import * as yup from "yup";
import { toastError, toastSuccess } from "../../components/Toast/Toast";
import { H4 } from "../../UI/H4";
import { InputSubmit } from "../../UI/InputSubmit";
import { InputText } from "../../UI/InputText";
import { Paragraph } from "../../UI/Paragraph";
import { Spacer } from "../../UI/Spacer";
import { acceptInvite } from "../../usecases/auth";

export const AcceptInvite = () => {
  const navigate = useNavigate();
  const { token } = useSearch();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(formSchema),
    defaultValues: {
      password: "",
      repeatPassword: "",
    },
  });

  const acceptInviteMutation = useMutation({
    mutationFn: (data) => acceptInvite(data),
  });

  const handleValidSubmit = async ({ password }) => {
    try {
      await acceptInviteMutation.mutateAsync({ password, token });
      navigate({ to: "/login" });
      toastSuccess({
        header: "Successful Action!",
        body: "You've accepted the invitation. You can now log in with your new password.",
      });
    } catch (err) {
      toastError({
        header: "Error!",
        body: "Oops, something went wrong. Please try again",
      });
    }
  };

  const textField = (name, placeholder, type) => (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <InputText
          {...field}
          placeholder={placeholder}
          errorMessage={errors[name]?.message}
          type={type}
        />
      )}
    />
  );

  return (
    <NewPasswordContainer>
      <NewPasswordContainerChild>
        <img src="/RegisterLoginArtwork.svg" alt="" />
        <img src="/Logo.svg" alt="" />
      </NewPasswordContainerChild>
      <NewPasswordContainerChild>
        <NewPasswordFormContainer onSubmit={handleSubmit(handleValidSubmit)}>
          <SmallLogo>
            <img src="/Logo.svg" alt="" />
            <Spacer height="40px" />
          </SmallLogo>
          <H4 style={{ textAlign: "center" }}>
            You've been invited to join CatchData!
          </H4>
          <Spacer height="4px" />
          <Paragraph style={{ textAlign: "center" }}>
            Enter the password you are going to use to log in.
          </Paragraph>
          <Spacer height="30px" />
          {textField("password", "Your new password", "password")}
          <Spacer height="12px" />
          {textField("repeatPassword", "Repeat your new password", "password")}
          <Spacer height="32px" />
          <InputSubmit loading={acceptInviteMutation.isLoading}>
            Reset Password
          </InputSubmit>
        </NewPasswordFormContainer>
      </NewPasswordContainerChild>
    </NewPasswordContainer>
  );
};

const formSchema = yup
  .object({
    password: yup.string().required("Required"),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match"),
  })
  .required();

/* Styles */

const NewPasswordContainer = styled.div`
  display: flex;
  background-color: #f9fafa;
`;

const NewPasswordContainerChild = styled.div`
  flex: 0 50%;
  width: 50vw;
  height: 100vh;

  &:first-child {
    position: relative;

    img:first-child {
      width: 100%;
      object-fit: cover;
      height: 100vh;
    }

    img:nth-child(2) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 90%;
    }

    @media (max-width: 777px) {
      &:first-child {
        display: none;
      }
    }
  }

  &:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    padding: 30px 0;

    @media (max-width: 777px) {
      flex-basis: 100%;
    }
  }
`;

const SmallLogo = styled.div`
  display: none;

  img {
    max-width: 80%;
    margin: 0 auto;
  }

  @media (max-width: 777px) {
    display: block;
  }
`;

const NewPasswordFormContainer = styled.form`
  max-width: 424px;
  width: 100%;

  @media (max-width: 500px) {
    padding: 0 10px;
  }
`;
