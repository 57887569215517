import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "@tanstack/react-location";
import { useAtom } from "jotai";
import { RESET } from "jotai/utils";
import React from "react";
import { Controller } from "react-hook-form";
import { useMutation } from "react-query";
import styled from "styled-components";
import * as yup from "yup";
import { toastError, toastSuccess } from "../../../components/Toast/Toast";
import { InputSubmit } from "../../../UI/InputSubmit";
import { InputText } from "../../../UI/InputText";
import { Spacer } from "../../../UI/Spacer";
import { signup } from "../../../usecases/auth";
import { useFormAtom } from "../../../utils/hooks/useFormAtom";
import { step1DataAtom, step2DataAtom, step3DataAtom } from "../state";

export const RegistrationAccountSettings = () => {
  const navigate = useNavigate();
  const [step1Data, setStep1Data] = useAtom(step1DataAtom);
  const [step2Data, setStep2Data] = useAtom(step2DataAtom);
  const [step3Data, setStep3Data] = useAtom(step3DataAtom);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useFormAtom(step3DataAtom, {
    mode: "onBlur",
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: "",
      repeatEmail: "",
      password: "",
      repeatPassword: "",
    },
  });

  const mutation = useMutation({
    mutationFn: async (data) => signup(data),
  });

  const handleValidSubmit = async (data, e) => {
    try {
      const result = await mutation.mutateAsync({
        businessAccount: {
          name: step1Data.name,
          email: step1Data.email,
          country: step1Data.country,
          state: step1Data.state,
          city: step1Data.city,
          postalCode: step1Data.zip,
          street: step1Data.address,
        },
        personalInformation: {
          firstName: step2Data.firstName,
          lastName: step2Data.lastName,
          phoneNumber: step2Data.phoneNumber,
          position: step2Data.title,
        },
        accountSettings: {
          email: step3Data.email,
          password: step3Data.password,
        },
      });

      if (result.status === "email-taken") {
        setError("email", { type: "custom", message: "Email already in use" });
        return;
      }

      toastSuccess({
        header: "Successful Action!",
        body: "Account activation email sent.",
      });
      navigate({ to: "/login" });

      setStep1Data(RESET);
      setStep2Data(RESET);
      setStep3Data(RESET);
    } catch (err) {
      toastError({
        header: "Error!",
        body: "Oops, something went wrong. Please try again",
      });
    }
  };

  const textField = (name, placeholder, type) => (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <InputText
          {...field}
          placeholder={placeholder}
          errorMessage={errors[name]?.message}
          type={type}
        />
      )}
    />
  );

  return (
    <StyledThirdForm onSubmit={handleSubmit(handleValidSubmit)}>
      {textField("email", "Email")}
      <Spacer height="12px" />
      {textField("repeatEmail", "Repeat Email")}
      <Spacer height="12px" />
      {textField("password", "Password", "password")}
      <Spacer height="12px" />
      {textField("repeatPassword", "Repeat Password", "password")}
      <Spacer height="55px" />
      <InputSubmit loading={mutation.isLoading}>Sign up</InputSubmit>
      <div style={{ textAlign: "center", marginTop: "16px" }}>
        <PreviousStepAndLoginLink to="../2">
          Previous Step
        </PreviousStepAndLoginLink>
      </div>
      <div style={{ textAlign: "center", marginTop: "16px" }}>
        <PreviousStepAndLoginLink to="/login">Login</PreviousStepAndLoginLink>
      </div>
    </StyledThirdForm>
  );
};

const formSchema = yup
  .object({
    email: yup.string().email("Invalid email").required("Required"),
    repeatEmail: yup.string().oneOf([yup.ref("email")], "Emails must match"),
    password: yup.string().required("Required"),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match"),
  })
  .required();

/* Styles */

const StyledThirdForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
`;

const PreviousStepAndLoginLink = styled(Link)`
  font-family: Bai Jamjuree;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #4a7eff;
  text-decoration: none;
`;
