import { useMatch, useNavigate } from "@tanstack/react-location";
import { parseISO } from "date-fns";
import React, { useCallback, useMemo, useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { toastError, toastSuccess } from "../../components/Toast/Toast";
import { H2 } from "../../UI/H2";
import { Spacer } from "../../UI/Spacer";
import { addProperty, updateProperty } from "../../usecases/properties";
import {
  usePropertyDetails,
  useInvalidatePropertyDetails,
} from "../../usecases/properties";
import {
  useInvalidateClientDetailsReport,
  useInvalidatePropertyDetailsReport,
} from "../../usecases/reports";
import { googleMapsLoader } from "../../utils/googleMapsLoader";
import { useBackLink } from "../../utils/hooks/useBackLink";
import { PropertyForm } from "./PropertyForm";

/**
 * @typedef AddOrEditPropertyProps
 * @prop {'add' | 'edit'} action
 */

/** @type {React.FC<AddOrEditPropertyProps>} */
export const AddOrEditProperty = ({ action }) => {
  const navigate = useNavigate();
  const {
    params: { clientId, propertyId },
  } = useMatch();
  const { path: backPath } = useBackLink();

  const invalidateClientDetailsReport =
    useInvalidateClientDetailsReport(clientId);
  const invalidatePropertyDetailsReport =
    useInvalidatePropertyDetailsReport(propertyId);
  const invalidatePropertyDetails = useInvalidatePropertyDetails(propertyId);

  const [locationError, setLocationError] = useState(null);

  const addOrEditPropertyMutation = useMutation({
    mutationFn: async ({ action, id, data }) => {
      setLocationError(null);

      let coordinates;
      const google = await googleMapsLoader.load();
      const geocoder = new google.maps.Geocoder();
      /** @type {google.maps.GeocoderResult} */
      let geocodingResult;
      try {
        const response = await geocoder.geocode({
          address: [data.address, data.city, data.stateOrProvince]
            .filter((component) => component)
            .join(", "),
        });
        geocodingResult = response.results[0];
      } catch (err) {
        // A way to flag geocoding errors.
        err.geocodingFailed = true;
        throw err;
      }

      // Accept only precise results
      const locationType = geocodingResult.geometry.location_type;
      if (!["ROOFTOP", "RANGE_INTERPOLATED"].includes(locationType)) {
        const error = new Error("Geocoding result not precise enough");
        // A way to flag geocoding errors.
        error.geocodingFailed = true;
        throw error;
      }

      const location = geocodingResult.geometry.location;
      coordinates = { longitude: location.lng(), latitude: location.lat() };

      const dataForService = {
        clientId,
        name: data.propertyName,
        address: {
          country: data.country,
          state: data.stateOrProvince,
          city: data.city,
          postalCode: data.zip,
          street: data.address,
        },
        contact: {
          firstName: data.contactFirstName,
          lastName: data.contactLastName,
          phoneNumber: data.contactPhoneNumber,
          email: data.contactEmail,
          position: data.contactPosition,
        },
        coordinates,
      };

      if (action === "add") {
        return addProperty(dataForService);
      } else if (action === "edit") {
        return updateProperty(id, {
          ...dataForService,
          lastVisit: data.lastVisit,
        });
      }
    },
    onSuccess: () =>
      Promise.all([
        invalidateClientDetailsReport(),
        invalidatePropertyDetailsReport(),
        invalidatePropertyDetails(),
      ]),
  });

  const handleBack = () => {
    navigate({ to: backPath });
  };

  const handleAddPropertySubmit = async (data) => {
    try {
      await addOrEditPropertyMutation.mutateAsync({ action: "add", data });
      toastSuccess({
        header: "Successful Action!",
        body: "Successfully added a new property!",
      });
      navigate({ to: backPath });
    } catch (err) {
      if (err.geocodingFailed) {
        setLocationError("We were not able to find this address");
        return;
      }

      toastError({
        header: "Error!",
        body: "Oops, something went wrong. Please try again",
      });
    }
  };

  const handleEditPropertySubmit = async (id, data) => {
    try {
      await addOrEditPropertyMutation.mutateAsync({ action: "edit", id, data });
      toastSuccess({
        header: "Successful Action!",
        body: "Successfully changed property's information!",
      });
      navigate({ to: backPath });
    } catch (err) {
      if (err.geocodingFailed) {
        setLocationError("We were not able to find this address");
        return;
      }

      toastError({
        header: "Error!",
        body: "Oops, something went wrong. Please try again",
      });
    }
  };

  return (
    <Wrapper>
      <div>
        <Spacer height="32px" />
        {action === "add" && (
          <AddProperty
            action={action}
            onBack={handleBack}
            onSubmit={handleAddPropertySubmit}
            isLoading={addOrEditPropertyMutation.isLoading}
            locationError={locationError}
          />
        )}
        {action === "edit" && (
          <EditProperty
            action={action}
            onBack={handleBack}
            onSubmit={handleEditPropertySubmit}
            isLoading={addOrEditPropertyMutation.isLoading}
            locationError={locationError}
          />
        )}
        <Spacer height="55px" />
      </div>
    </Wrapper>
  );
};

const AddProperty = ({
  action,
  onBack,
  onSubmit,
  isLoading,
  locationError,
}) => (
  <>
    <FlexWrapper>
      <H2>Add a New Property</H2>
    </FlexWrapper>
    <Spacer height="32px" />
    <PropertyForm
      action={action}
      onBack={onBack}
      onSubmit={onSubmit}
      isLoading={isLoading}
      locationError={locationError}
    />
  </>
);

const EditProperty = ({
  action,
  managers,
  onBack,
  onSubmit,
  isLoading,
  locationError,
}) => {
  const {
    params: { propertyId },
  } = useMatch();
  const { data: propertyDetails, isLoading: isLoadingPropertyDetails } =
    usePropertyDetails(propertyId);

  const detailsForForm = useMemo(() => {
    if (isLoadingPropertyDetails) {
      return undefined;
    }

    return {
      propertyName: propertyDetails.name,
      country: propertyDetails.address.country,
      stateOrProvince: propertyDetails.address.state,
      city: propertyDetails.address.city,
      zip: propertyDetails.address.postalCode,
      address: propertyDetails.address.street,
      contactFirstName: propertyDetails.contact.firstName,
      contactLastName: propertyDetails.contact.lastName,
      contactPhoneNumber: propertyDetails.contact.phoneNumber,
      contactEmail: propertyDetails.contact.email,
      contactPosition: propertyDetails.contact.position,
      lastVisit: parseISO(propertyDetails.lastVisit),
    };
  }, [propertyDetails, isLoadingPropertyDetails]);

  const handleSubmit = useCallback(
    (data) => {
      if (typeof onSubmit !== "function") {
        return;
      }

      onSubmit(propertyDetails._id, data);
    },
    [onSubmit, propertyDetails._id]
  );

  if (isLoadingPropertyDetails) {
    return null;
  }

  return (
    <>
      <FlexWrapper>
        <H2>Edit {propertyDetails.name}</H2>
      </FlexWrapper>
      <Spacer height="32px" />
      <PropertyForm
        action={action}
        propertyToEdit={detailsForForm}
        onBack={onBack}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        locationError={locationError}
      />
    </>
  );
};

/* Styles */

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
