import { atomWithStorage, createJSONStorage } from "jotai/utils";
import { breakpoints } from "../../Theme";

const isLargerScreen = window.matchMedia(
  `(min-width: ${breakpoints.lg}px)`
).matches;

export const sidebarExpandedAtom = atomWithStorage(
  "sidebarExpanded",
  isLargerScreen,
  createJSONStorage(() => window.sessionStorage)
);
sidebarExpandedAtom.debugLabel = "sidebarExpandedAtom";
