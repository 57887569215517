import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { Modal } from "../../components/Modal";
import { InputSubmit } from "../../UI/InputSubmit";
import { InputText } from "../../UI/InputText";
import { Spacer } from "../../UI/Spacer";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const RegisterNewTrapModal = ({
  isOpen,
  onSubmit,
  onCancel,
  isLoading,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(imeiSchema),
    defaultValues: {
      serialNumber: "",
      imei: "",
    },
  });

  const handleValidSubmit = (data) => {
    onSubmit(data);
    reset();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      heading="Trap Registration"
    >
      <form onSubmit={handleSubmit(handleValidSubmit)}>
        <Controller
          control={control}
          name="serialNumber"
          render={({ field }) => (
            <InputText {...field} placeholder="Serial Number" />
          )}
        />
        <Spacer height="12px" />
        <Controller
          control={control}
          name="imei"
          render={({ field }) => (
            <InputText
              {...field}
              placeholder="IMEI"
              errorMessage={errors["imei"]?.message}
            />
          )}
        />
        <Spacer height="56px" />
        <ButtonsContainer>
          <BackButton onClick={onCancel}>Close</BackButton>
          <InputSubmit loading={isLoading}>Register Trap</InputSubmit>
        </ButtonsContainer>
      </form>
    </Modal>
  );
};

const imeiSchema = yup.object({
  imei: yup
    .string()
    .min(15, "Must contain at least 15 characters")
    .required("Required")
    .matches(/^(\S+$)/g, "This field cannot contain blankspaces"),
});

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 257px;
  margin: 0 auto;
`;

const BackButton = styled.button`
  background-color: #e5edff;
  border: 2px solid rgba(4, 14, 86, 0.07);
  padding: 16px 32px;
  margin-right: 8px;
  cursor: pointer;
`;
