import { useRef } from "react";

/**
 * Just like `useRef` but also updates `.current` on every render so it's always
 * up to date. Useful for simplifying code for callback refs.
 * @template T
 * @param {T} value
 * @returns {React.MutableRefObject<T>}
 */
export const useUpdateRef = (value) => {
  const valueRef = useRef(value);
  valueRef.current = value;
  return valueRef;
};
