import React from "react";
import { ThemeProvider } from "styled-components";

const gray = {
  0: "#ffffff",
  100: "#f9fafa",
  200: "#f1f3f5",
  300: "#e5e9ec",
  400: "#d5dce1",
  500: "#c5ced6",
  600: "#b7c2cc",
  700: "#a5b2bd",
};

const dark = {
  100: "#93A1B0",
  200: "#748494",
  300: "#556575",
  400: "#405261",
  500: "#314351",
  600: "#253642",
  700: "#1D2B36",
};

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1536,
};

/**
 * Constructs a media query that matches screen widths greater than the screen
 * size given by the breakpoint key.
 * @param {keyof typeof breakpoints} breakpoint
 * @returns {string}
 */
const breakpointUp = (breakpoint) =>
  `@media (min-width: ${breakpoints[breakpoint]}px)`;

const theme = {
  colors: {
    brandDark: "#040e56",
    brandBlue: "#4A7EFF",
    gray,
    dark,
  },
  paddings: {
    contentX: "32px",
  },
  widths: {
    sidebarCollapsed: "72px",
    sidebarExpanded: "220px",
  },
  maxWidths: {
    content: "1920px",
  },
  breakpoints: {
    ...breakpoints,
    up: breakpointUp,
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
