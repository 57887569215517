import { useQuery } from "react-query";
import { queryInvalidateHook } from "../utils/queryInvalidateHook";
import { reportsQueryKeys } from "./queryKeys";
import {
  getClientDetailsReport,
  getClientsReport,
  getPropertyDetailsReport,
  getTrapDetailsForDateReport,
  getTrapDetailsReport,
} from "./services";

export const useClientsReport = (id) =>
  useQuery(reportsQueryKeys.clients(), () => getClientsReport(id));

export const useClientDetailsReport = (id) =>
  useQuery(reportsQueryKeys.clientDetailsFor(id), () =>
    getClientDetailsReport(id)
  );

export const useInvalidateClientDetailsReport = queryInvalidateHook((id) =>
  reportsQueryKeys.clientDetailsFor(id)
);

export const usePropertyDetailsReport = (id) =>
  useQuery(reportsQueryKeys.propertyDetailsFor(id), () =>
    getPropertyDetailsReport(id)
  );

export const useInvalidatePropertyDetailsReport = queryInvalidateHook((id) =>
  reportsQueryKeys.propertyDetailsFor(id)
);

export const useTrapDetailsReport = (imei) =>
  useQuery(reportsQueryKeys.trapDetailsFor(imei), () =>
    getTrapDetailsReport(imei)
  );

export const useTrapDetailsForDate = (imei, date) =>
  useQuery(reportsQueryKeys.trapDetailsForDate(imei, date), () =>
    getTrapDetailsForDateReport(imei, date)
  );
