import { yupResolver } from "@hookform/resolvers/yup";
import { isValidPhoneNumber } from "libphonenumber-js";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import { Calendar } from "../../UI/Calendar";
import { CountryDropdown } from "../../UI/CountryDropdown";
import { InputSubmit } from "../../UI/InputSubmit";
import { InputText } from "../../UI/InputText";
import { Spacer } from "../../UI/Spacer";

export const PropertyForm = ({
  action,
  onBack,
  onSubmit,
  isLoading,
  propertyToEdit,
  locationError,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(formSchema),
    defaultValues: {
      lastVisit: null,
      manager: "",
      businessName: "",
      businessEmail: "",
      country: "",
      stateOrProvince: "",
      city: "",
      zip: "",
      address: "",
      contactFirstName: "",
      contactLastName: "",
      contactPhoneNumber: "",
      contactEmail: "",
      contactPosition: "",
      ...propertyToEdit,
    },
  });

  useEffect(() => {
    if (locationError) {
      setError("address", { type: "custom", message: locationError });
    } else {
      clearErrors("address");
    }
  }, [locationError, setError, clearErrors]);

  const handleBack = (event) => {
    event.preventDefault();
    onBack?.();
  };

  const handleValidSubmit = (data) => {
    onSubmit(data);
  };

  const textField = (name, placeholder) => (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <InputText
          {...field}
          placeholder={placeholder}
          errorMessage={errors[name]?.message}
        />
      )}
    />
  );

  return (
    <FormContainer onSubmit={handleSubmit(handleValidSubmit)}>
      {action === "edit" && (
        <>
          <SpacedParagraph>Last Visit</SpacedParagraph>
          <Spacer height="4px" />
          <Controller
            control={control}
            name="lastVisit"
            render={({ field }) => (
              <Calendar
                {...field}
                placeholder="Choose a Date"
                errorMessage={errors["lastVisit"]?.message}
              />
            )}
          />
        </>
      )}
      <Spacer height="48px" />
      <SpacedParagraph>Property Information</SpacedParagraph>
      <Spacer height="4px" />
      {textField("propertyName", "Property name")}
      <Spacer height="12px" />
      <Controller
        control={control}
        name="country"
        render={({ field }) => (
          <CountryDropdown
            {...field}
            placeholder="Country"
            errorMessage={errors["country"]?.message}
          />
        )}
      />
      <Spacer height="12px" />
      {textField("stateOrProvince", "State/Province")}
      <Spacer height="12px" />
      <div style={{ display: "flex" }}>
        <div style={{ flex: "1", marginRight: "4px" }}>
          {textField("city", "City")}
        </div>
        <div style={{ flexBasis: "144px", marginLeft: "4px" }}>
          {textField("zip", "Zip/Postal Code")}
        </div>
      </div>
      <Spacer height="12px" />
      {textField("address", "Property address")}
      <Spacer height="64px" />
      <SpacedParagraph>CONTACT INFORMATION</SpacedParagraph>
      <Spacer height="12px" />
      <div style={{ display: "flex" }}>
        <div style={{ flex: "1", marginRight: "4px" }}>
          {textField("contactFirstName", "First Name")}
        </div>
        <div style={{ flex: "1", marginLeft: "4px" }}>
          {textField("contactLastName", "Last Name")}
        </div>
      </div>
      <Spacer height="12px" />
      {textField("contactPhoneNumber", "Phone number (e.g. +1 819 555 5555)")}
      <Spacer height="12px" />
      {textField("contactEmail", "Email")}
      <Spacer height="12px" />
      {textField("contactPosition", "Position/Title")}
      <Spacer height="55px" />
      <FlexWrapper>
        <BackButton onClick={handleBack}>Back</BackButton>
        <InputSubmit loading={isLoading}>
          {action === "add" && "Add Property"}
          {action === "edit" && "Save Changes"}
        </InputSubmit>
      </FlexWrapper>
    </FormContainer>
  );
};

const formSchema = yup.object({
  propertyName: yup.string().required("Required"),
  country: yup.string().required("Required"),
  stateOrProvince: yup.string().required("Required"),
  city: yup.string().required("Required"),
  zip: yup.string().required("Required"),
  address: yup.string().required("Required"),
  contactFirstName: yup.string().required("Required"),
  contactLastName: yup.string().required("Required"),
  contactPhoneNumber: yup
    .string()
    .required("Required")
    .test("is-phone-number", "Invalid phone number", (value) =>
      isValidPhoneNumber(value)
    ),
  contactEmail: yup.string().email("Invalid email").required("Required"),
  contactPosition: yup.string(),
});

/* Styles */

const FormContainer = styled.form`
  max-width: 424px;
`;

const SpacedParagraph = styled.p`
  text-transform: uppercase;
  line-height: 20px;
  letter-spacing: 0.06em;
  color: #556575;
  font-family: "Bai Jamjuree";
`;

const BackButton = styled.button`
  background-color: #e5edff;
  border: 2px solid rgba(4, 14, 86, 0.07);
  padding: 16px 32px;
  margin-right: 8px;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
