import { useCallback, useRef } from "react";
import { useQueryClient } from "react-query";

export const queryInvalidateHook = (queryKeyFn) => {
  const useQueryInvalidate = (...params) => {
    const queryClient = useQueryClient();
    const paramsRef = useRef(params);
    paramsRef.current = params;

    return useCallback(
      () => queryClient.invalidateQueries(queryKeyFn(...paramsRef.current)),
      [queryClient]
    );
  };

  return useQueryInvalidate;
};
