import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { Modal } from "../../components/Modal";
import { InputSubmit } from "../../UI/InputSubmit";
import { InputText } from "../../UI/InputText";
import { Spacer } from "../../UI/Spacer";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { isValidPhoneNumber } from "libphonenumber-js";

export const NewMemberModal = ({
  isOpen,
  onSubmit,
  onCancel,
  isLoading,
  userExists,
}) => {
  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    },
  });

  useEffect(() => {
    if (userExists) {
      setError("email", { type: "custom", message: userExists });
    }
  }, [userExists, setError]);

  const handleValidSubmit = (data) => {
    onSubmit(data, () => reset());
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onCancel} heading="Send Invitation">
      <form onSubmit={handleSubmit(handleValidSubmit)}>
        <div style={{ display: "flex" }}>
          <Controller
            control={control}
            name="firstName"
            render={({ field }) => (
              <InputText
                {...field}
                placeholder="First Name"
                errorMessage={errors["firstName"]?.message}
              />
            )}
          />
          <Spacer width="8px" />
          <Controller
            control={control}
            name="lastName"
            render={({ field }) => (
              <InputText
                {...field}
                placeholder="Last Name"
                errorMessage={errors["lastName"]?.message}
              />
            )}
          />
        </div>
        <Spacer height="12px" />
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <InputText
              {...field}
              placeholder="Email"
              errorMessage={errors["email"]?.message}
            />
          )}
        />
        <Spacer height="12px" />
        <Controller
          control={control}
          name="phoneNumber"
          render={({ field }) => (
            <InputText
              {...field}
              placeholder="Phone number (e.g. +1 819 555 5555)"
              errorMessage={errors["phoneNumber"]?.message}
            />
          )}
        />
        <Spacer height="56px" />
        <ButtonsContainer>
          <CloseButton onClick={onCancel}>Close</CloseButton>
          <InputSubmit loading={isLoading}>Send Invite</InputSubmit>
        </ButtonsContainer>
      </form>
    </Modal>
  );
};

const schema = yup.object({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  email: yup.string().email("Invalid email").required("Required"),
  phoneNumber: yup
    .string()
    .required("Required")
    .test("is-phone-number", "Invalid phone number", (value) =>
      isValidPhoneNumber(value)
    ),
});

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 257px;
  margin: 0 auto;
`;

const CloseButton = styled.button`
  background-color: #e5edff;
  border: 2px solid rgba(4, 14, 86, 0.07);
  padding: 16px 32px;
  margin-right: 8px;
  cursor: pointer;
`;
