import { useUpdateAtom } from "jotai/utils";
import { useEffect } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import { H3 } from "../../UI/H3";
import { ReactComponent as ModalXIcon } from "../../UI/icons/modal-x.svg";
import { modalsDisplayedCountAtom } from "./state";

ReactModal.setAppElement("#root");

export const Modal = ({
  children,
  isOpen,
  onRequestClose,
  maxWidth = "472px",
  width = "100%",
  heading,
}) => {
  const setModalsDisplayedCount = useUpdateAtom(modalsDisplayedCountAtom);

  useEffect(() => {
    if (isOpen) {
      setModalsDisplayedCount((count) => count + 1);
      return () => {
        setModalsDisplayedCount((count) => count - 1);
      };
    }
  }, [isOpen, setModalsDisplayedCount]);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          position: "fixed",
          zIndex: "100",
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          background: "#040E564D",
          display: "flex",
          padding: "8px",
        },
        content: { maxWidth, width, margin: "auto" },
      }}
      className="CatchData_CustomReactModal_Content"
      overlayClassName="CatchData_CustomReactModal_Overlay"
    >
      <ModalLayout heading={heading} onRequestClose={onRequestClose}>
        {children}
      </ModalLayout>
    </ReactModal>
  );
};

const ModalLayout = ({ children, heading, onRequestClose }) => (
  <ModalLayoutRoot>
    <ModalX onClick={onRequestClose}>
      <ModalXIcon />
    </ModalX>
    <ModalHeading>
      <H3 style={{ textAlign: "center", flex: "1" }}>{heading}</H3>
    </ModalHeading>
    {children}
  </ModalLayoutRoot>
);

const ModalLayoutRoot = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.gray[100]};
  box-shadow: 0px 4px 28px 0px #00000026;
  padding: 24px;
  border-radius: 8px;
`;
ModalLayoutRoot.displayName = "ModalLayoutRoot";

const ModalHeading = styled.div`
  display: flex;
  margin: 12px 48px 32px;
`;
ModalHeading.displayName = "ModalHeader";

const ModalX = styled.button`
  position: absolute;
  right: 24px;
  top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: ${(props) => props.theme.colors.gray[300]};
  border: 0;
  border-radius: 4px;
  cursor: pointer;
`;
ModalX.displayName = "ModalX";
