import { useQuery } from "react-query";
import { businessAccountsQueryKeys } from "./queryKeys";
import { getAllBusinessAccounts, getBusinessAccount } from "./services";

export const useAllBusinessAccounts = () =>
  useQuery(businessAccountsQueryKeys.list(), () => getAllBusinessAccounts());

export const useBusinessAccountDetails = (id) =>
  useQuery(businessAccountsQueryKeys.detailsFor(id), () =>
    getBusinessAccount(id)
  );
