import styled from "styled-components";

export const InputRadio = styled.input`
  appearance: none;
  -webkit-appearance: none;
  font: inherit;
  color: currentColor;
  width: 20px;
  height: 20px;
  border: 1px solid #b7c2cc;
  border-radius: 50%;
  display: grid;
  place-content: center;

  &:before {
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 50%;
    opacity: 0;
    transition: 120ms opacity ease-in-out;
    border: 4px solid #4a7eff;
  }

  &:checked:before {
    opacity: 1;
  }
`;
