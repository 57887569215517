import { useQuery } from "react-query";
import { queryInvalidateHook } from "../utils/queryInvalidateHook";
import { trapsQueryKeys } from "./queryKeys";
import { getTrap, getTraps } from "./services";

export const useAllTraps = () =>
  useQuery(trapsQueryKeys.list(), () => getTraps());

export const useInvalidateTrapsList = queryInvalidateHook(() =>
  trapsQueryKeys.list()
);

export const useTrapDetails = (id) =>
  useQuery(trapsQueryKeys.detailsFor(id), () => getTrap(id));
