import { useQuery } from "react-query";
import { queryInvalidateHook } from "../utils/queryInvalidateHook";
import { usersQueryKeys } from "./queryKeys";
import { getAllUsers, getUser } from "./services";

export const useAllUsers = () =>
  useQuery(usersQueryKeys.list(), () => getAllUsers());

export const useInvalidateUsersList = queryInvalidateHook(() =>
  usersQueryKeys.list()
);

export const useUserDetails = (id) =>
  useQuery(usersQueryKeys.detailsFor(id), () => getUser(id));

export const useInvalidateUserDetails = queryInvalidateHook((id) =>
  usersQueryKeys.detailsFor(id)
);
