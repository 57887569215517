import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "@tanstack/react-location";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import { InputSubmit } from "../../../UI/InputSubmit";
import { InputText } from "../../../UI/InputText";
import { Spacer } from "../../../UI/Spacer";
import { noop } from "../../../utils/functional";

/**
 * @typedef LoginFormProps
 * @prop {(data: { user: string; password: string }) => void} onSubmit
 */
/** @type {React.FC<LoginFormProps>} */
export const LoginForm = ({ onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  return (
    <LoginFormContainer onSubmit={handleSubmit(onSubmit ?? noop)}>
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <InputText
            {...field}
            placeholder="Email"
            errorMessage={errors.email?.message}
            autoFocus
          />
        )}
      />
      <Spacer height="12px" />
      <Controller
        control={control}
        name="password"
        render={({ field }) => (
          <InputText
            {...field}
            placeholder="Password"
            type="password"
            errorMessage={errors.password?.message}
          />
        )}
      />
      <Spacer height="8px" />
      <InternalLink to="/reset-password" alignment="self-end">
        Reset Password
      </InternalLink>
      <Spacer height="32px" />
      <InputSubmit type="submit">Sign in</InputSubmit>
      <Spacer height="12px" />
      <InternalLink to="/register" register alignment="center">
        Register
      </InternalLink>
    </LoginFormContainer>
  );
};

const formSchema = yup.object({
  email: yup.string().email("Invalid email").required("Required"),
  password: yup.string().required("Required"),
});

const InternalLink = styled(Link)`
  font-weight: ${(props) => (props.register ? "700" : "500")};
  font-size: ${(props) => (props.register ? "16px" : "14px")};
  line-height: 16px;
  color: ${(props) => (props.register ? "#222" : "#4a7eff")};
  text-decoration: none;
  align-self: ${(props) => props.alignment ?? ""};
  width: ${(props) => (props.register ? "100%" : "")};
  height: ${(props) => (props.register ? "52px" : "")};
  background-color: ${(props) => (props.register ? "#e6edff" : "")};
  border: ${(props) =>
    props.register ? "2px solid rgba(4, 14, 86, 0.07)" : ""};
  display: ${(props) => (props.register ? "flex" : "")};
  align-items: ${(props) => (props.register ? "center" : "")};
  justify-content: ${(props) => (props.register ? "center" : "")};
  font-family: ${(props) => (props.register ? "Bai Jamjuree" : "")};
`;
InternalLink.displayName = "InternalLink";

const LoginFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
LoginFormContainer.displayName = "LoginFormContainer";
