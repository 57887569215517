import { useMatch } from "@tanstack/react-location";
import styled from "styled-components";
import { InputText } from "../../../UI/InputText";
import { Spacer } from "../../../UI/Spacer";
import { H3 } from "../../../UI/H3";
import { Link } from "@tanstack/react-location";
import { useBusinessAccountDetails } from "../../../usecases/businessAccounts";
import { CountryDropdown } from "../../../UI/CountryDropdown";

export const ClientDetails = () => {
  const {
    params: { businessAccountId },
  } = useMatch();
  const { data: clientDetails, isLoading } =
    useBusinessAccountDetails(businessAccountId);

  if (isLoading) {
    return null;
  }

  return (
    <Container>
      <Spacer height="32px" />
      <H3>{clientDetails.name}</H3>
      <Spacer height="32px" />
      <SpacedParagraph>BUSINESS INFORMATION</SpacedParagraph>
      <Spacer height="12px" />
      <InputText value={clientDetails.name} readOnly />
      <Spacer height="12px" />
      <InputText value={clientDetails.email} readOnly />
      <Spacer height="32px" />
      <CountryDropdown value={clientDetails.address.country} readOnly />
      <Spacer height="12px" />
      <InputText value={clientDetails.address.state} readOnly />
      <Spacer height="12px" />
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ flex: "1", marginRight: "4px" }}>
            <InputText value={clientDetails.address.city} readOnly />
          </div>
          <div style={{ flexBasis: "144px", marginLeft: "4px" }}>
            <InputText value={clientDetails.address.postalCode} readOnly />
          </div>
        </div>
      </div>
      <Spacer height="12px" />
      <InputText value={clientDetails.address.street} readOnly />
      <div>
        <Spacer height="32px" />
        <SpacedParagraph>CONTACT PERSON INFORMATION</SpacedParagraph>
        <Spacer height="12px" />
        <div style={{ display: "flex" }}>
          <div style={{ flex: "1", marginRight: "4px" }}>
            <InputText value={clientDetails.contact.firstName} readOnly />
          </div>
          <div style={{ flex: "1", marginLeft: "4px" }}>
            <InputText value={clientDetails.contact.lastName} readOnly />
          </div>
        </div>
        <Spacer height="12px" />
        <InputText value={clientDetails.contact.phoneNumber} readOnly />
        <Spacer height="12px" />
        <InputText value={clientDetails.contact.email} readOnly />
        <Spacer height="12px" />
        <InputText value={clientDetails.contact.position} readOnly />
        <Spacer height="57px" />
        <ButtonLink to="/clients">Close</ButtonLink>
      </div>
    </Container>
  );
};

const SpacedParagraph = styled.p`
  text-transform: uppercase;
  line-height: 20px;
  letter-spacing: 0.06em;
  color: #556575;
  font-family: "Bai Jamjuree";
  text-align: left;
`;

const ButtonLink = styled(Link)`
  text-decoration: none;
  padding: 16px 32px;
  color: #fff;
  background-color: #040e56;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`;

const Container = styled.div`
  max-width: 424px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
`;
