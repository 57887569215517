import { useMatch, useNavigate } from "@tanstack/react-location";
import React, { useCallback, useMemo } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { toastError, toastSuccess } from "../../components/Toast/Toast";
import { H2 } from "../../UI/H2";
import { Spacer } from "../../UI/Spacer";
import {
  addNewClient,
  updateClient,
  useClientDetails,
  useInvalidateClientDetails,
  useInvalidateClientsList,
} from "../../usecases/clients";
import { useInvalidateClientDetailsReport } from "../../usecases/reports";
import { useAllUsers } from "../../usecases/users";
import { useBackLink } from "../../utils/hooks/useBackLink";
import { ClientForm } from "./ClientForm";

/**
 * @typedef AddOrEditClientProps
 * @prop {'add' | 'edit'} action
 */

/** @type {React.FC<AddOrEditClientProps>} */
export const AddOrEditClient = ({ action }) => {
  const navigate = useNavigate();
  const {
    params: { clientId },
  } = useMatch();
  const { data: users, isLoading: isLoadingUsers } = useAllUsers();
  const { path: backPath } = useBackLink();

  const invalidateClientsList = useInvalidateClientsList();
  const invalidateClientDetails = useInvalidateClientDetails(clientId);
  const invalidateClientDetailsReport =
    useInvalidateClientDetailsReport(clientId);

  const mutation = useMutation({
    mutationFn: async ({ action, id, data }) => {
      const dataForService = {
        name: data.businessName,
        email: data.businessEmail,
        address: {
          country: data.country,
          state: data.stateOrProvince,
          city: data.city,
          postalCode: data.zip,
          street: data.address,
        },
        contact: {
          firstName: data.contactFirstName,
          lastName: data.contactLastName,
          phoneNumber: data.contactPhoneNumber,
          email: data.contactEmail,
          position: data.contactPosition,
        },
        managerId: data.manager,
      };

      if (action === "add") {
        return addNewClient(dataForService);
      } else if (action === "edit") {
        return updateClient(id, dataForService);
      }
    },
    onSuccess: () => {
      invalidateClientsList();
      invalidateClientDetails();
      invalidateClientDetailsReport();
    },
  });

  const handleBack = () => {
    navigate({ to: backPath });
  };

  const handleAddClientSubmit = async (data) => {
    try {
      await mutation.mutateAsync({ action: "add", data });
      toastSuccess({
        header: "Successful Action!",
        body: "Successfully added a new client!",
      });
      navigate({ to: backPath });
    } catch (err) {
      toastError({
        header: "Error!",
        body: "Oops, something went wrong. Please try again",
      });
    }
  };

  const handleEditClientSubmit = async (id, data) => {
    try {
      await mutation.mutateAsync({ action: "edit", id, data });
      toastSuccess({
        header: "Successful Action!",
        body: "Successfully changed client's information!",
      });
      navigate({ to: backPath });
    } catch (err) {
      toastError({
        header: "Error!",
        body: "Oops, something went wrong. Please try again",
      });
    }
  };

  if (isLoadingUsers) {
    return null;
  }

  const managers = users
    .filter(
      (user) => user.isActivated && user.role === "BUSINESS_ACCOUNT_ADMIN"
    )
    .map((user) => ({
      id: user._id,
      name: `${user.firstName} ${user.lastName}`,
    }));

  return (
    <Wrapper>
      <div>
        <Spacer height="32px" />

        {action === "add" && (
          <AddClient
            action={action}
            managers={managers}
            onBack={handleBack}
            onSubmit={handleAddClientSubmit}
            isLoading={mutation.isLoading}
          />
        )}
        {action === "edit" && (
          <EditClient
            action={action}
            managers={managers}
            onBack={handleBack}
            onSubmit={handleEditClientSubmit}
            isLoading={mutation.isLoading}
          />
        )}

        <Spacer height="55px" />
      </div>
    </Wrapper>
  );
};

const AddClient = ({ action, managers, onBack, onSubmit, isLoading }) => (
  <>
    <HeadingWrapper>
      <H2>Add a New Client</H2>
    </HeadingWrapper>
    <Spacer height="32px" />
    <ClientForm
      action={action}
      managers={managers}
      onBack={onBack}
      onSubmit={onSubmit}
      isLoading={isLoading}
    />
  </>
);

const EditClient = ({ action, managers, onBack, onSubmit, isLoading }) => {
  const {
    params: { clientId },
  } = useMatch();
  const { data: clientDetails, isLoading: isLoadingClientDetails } =
    useClientDetails(clientId);

  const detailsForForm = useMemo(() => {
    if (isLoadingClientDetails) {
      return undefined;
    }

    return {
      manager: clientDetails.manager,
      businessName: clientDetails.name,
      businessEmail: clientDetails.email,
      country: clientDetails.address.country,
      stateOrProvince: clientDetails.address.state,
      city: clientDetails.address.city,
      zip: clientDetails.address.postalCode,
      address: clientDetails.address.street,
      contactFirstName: clientDetails.contact.firstName,
      contactLastName: clientDetails.contact.lastName,
      contactPhoneNumber: clientDetails.contact.phoneNumber,
      contactEmail: clientDetails.contact.email,
      contactPosition: clientDetails.contact.position,
    };
  }, [clientDetails, isLoadingClientDetails]);

  const handleSubmit = useCallback(
    (data) => {
      if (typeof onSubmit !== "function") {
        return;
      }

      onSubmit(clientDetails._id, data);
    },
    [onSubmit, clientDetails._id]
  );

  if (isLoadingClientDetails) {
    return null;
  }

  return (
    <>
      <HeadingWrapper>
        <H2>Edit {clientDetails.name}</H2>
      </HeadingWrapper>
      <Spacer height="32px" />
      <ClientForm
        action={action}
        clientToEdit={detailsForForm}
        managers={managers}
        onBack={onBack}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const HeadingWrapper = styled.div`
  text-align: center;
`;
