import { Link } from "@tanstack/react-location";
import React, { useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { CustomTable } from "../../components/CustomTable";
import { toastSuccess } from "../../components/Toast/Toast";
import { Button } from "../../UI/Button";
import { H3 } from "../../UI/H3";
import { ReactComponent as RoundPlusIcon } from "../../UI/icons/RoundPlusIcon.svg";
import { ReactComponent as TeamIcon } from "../../UI/icons/TeamIcon.svg";
import { InputText } from "../../UI/InputText";
import { Spacer } from "../../UI/Spacer";
import { useAllUsers, useInvalidateUsersList } from "../../usecases/users";
import { inviteUser } from "../../usecases/users/services";
import { NewMemberModal } from "./NewMemberModal";
import { ReactComponent as EditIcon } from "../../UI/icons/EditIcon.svg";

export const Team = () => {
  const { data: team } = useAllUsers();
  const invalidateUsersList = useInvalidateUsersList();

  const sendInvitationMutation = useMutation({
    mutationFn: (data) => inviteUser(data),
    onSuccess: () => invalidateUsersList(),
  });

  const [isNewMemberModalOpen, setIsNewMemberModalOpen] = useState(false);

  const [userExists, setUserExists] = useState(null);

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const handleNewMemberSubmit = async (data, onSuccess) => {
    try {
      await sendInvitationMutation.mutateAsync(data);
      toastSuccess({
        header: "Successful Action!",
        body: "An email has been sent to the new user's email address",
      });
      onSuccess?.();
      setIsNewMemberModalOpen(false);
    } catch (err) {
      setUserExists("A user with this email already exists");
    }
  };

  const handleGlobalFilterValueChange = (event) => {
    setGlobalFilterValue(event.target.value);
  };

  const handleNewMemberClick = () => {
    setIsNewMemberModalOpen(true);
  };

  const handleNewMemberCancel = () => {
    setIsNewMemberModalOpen(false);
  };

  return (
    <>
      <NewMemberModal
        isOpen={isNewMemberModalOpen}
        onSubmit={handleNewMemberSubmit}
        onCancel={handleNewMemberCancel}
        isLoading={sendInvitationMutation.isLoading}
        userExists={userExists}
      />

      <FlexWrapper>
        <Content>
          <Hero>
            <HeroChild>
              <TeamIcon />
              <Spacer width="10px" />
              <H3>Team</H3>
              <Spacer width="18px" />
              <InputContainer>
                <InputText
                  placeholder="Search"
                  value={globalFilterValue}
                  onChange={handleGlobalFilterValueChange}
                />
              </InputContainer>
              <Spacer width="24px" />
            </HeroChild>
            <Button onClick={handleNewMemberClick}>
              <RoundPlusIcon />
              New Member
            </Button>
          </Hero>
          <Spacer height="40px" />
          <CustomTable
            data={team.map((teamMember) => ({
              ...teamMember,
              teamMemberStatus: deriveTeamMemberStatus(teamMember),
              fullName: `${teamMember.firstName} ${teamMember.lastName}`,
            }))}
            globalFilterValue={globalFilterValue}
            globalFilterFields={["fullName", "email"]}
            columns={[
              {
                header: "Name",
                sortable: true,
                field: "fullName",
              },
              {
                header: "Email",
                field: "email",
                sortable: true,
              },
              {
                header: "Phone",
                field: "phoneNumber",
                sortable: true,
              },
              {
                header: "Status",
                field: "teamMemberStatus",
                body: (teamMember) => {
                  if (teamMember.teamMemberStatus === "active") {
                    return <StyledStatus>Active</StyledStatus>;
                  } else if (teamMember.teamMemberStatus === "pending") {
                    return <StyledStatus pending>Pending Invite</StyledStatus>;
                  }
                },
                filter: {
                  type: "oneOf",
                  options: [
                    {
                      label: "Active",
                      value: "active",
                    },
                    {
                      label: "Pending Invite",
                      value: "pending",
                    },
                  ],
                },
              },
              {
                body: (teamMember) => (
                  <LastColumnWrapper>
                    <EditButton
                      className="visibleOnRowHover"
                      to={teamMember._id}
                    >
                      <EditIcon />
                    </EditButton>
                  </LastColumnWrapper>
                ),
              },
            ]}
          />
        </Content>
      </FlexWrapper>
    </>
  );
};

const deriveTeamMemberStatus = (teamMember) => {
  if (!teamMember.isActivated) {
    return "pending";
  }
  if (teamMember.isActivated) {
    return "active";
  }
};

const FlexWrapper = styled.div`
  background-color: ${(props) => `${props.theme.colors.gray[100]}`};
  padding: ${(props) => `32px ${props.theme.paddings.contentX}`};
`;

const Content = styled.div`
  margin: 0 auto;
  max-width: ${(props) => props.theme.maxWidths.content};
`;

const Hero = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeroChild = styled.div`
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
  }
`;

const InputContainer = styled.div`
  max-width: 200px;
`;

const LastColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;

  svg {
    flex-shrink: 0;
  }
`;

const StyledStatus = styled.p`
  padding: 4px 8px 6px 19px;
  border: ${(props) =>
    props.pending ? "1px solid #FAB347" : "1px solid #56c288"};
  border-radius: 25px;
  width: fit-content;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: ${(props) => (props.pending ? "#FAB347" : "#56c288")};
    border-radius: 50%;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const EditButton = styled(Link)`
  opacity: 0;
  background-color: transparent;
  border: none;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  transition: 0.3s background-color ease-in-out;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    position: absolute;
    content: "Edit";
    top: -12px;
    font-size: 10px;
    color: #fff;
    width: 29px;
    height: 16px;
    background: linear-gradient(91.98deg, #0d1026 3.35%, #19204d 96.65%);
    border-radius: 4px;
    font-family: "Roboto", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  &:hover {
    background-color: #f1f3f5;

    &::before {
      opacity: 1;
    }
  }
`;
