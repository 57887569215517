import { Link } from "@tanstack/react-location";
import { useState } from "react";
import styled from "styled-components";
import { CustomTable } from "../../../components/CustomTable";
import { H3 } from "../../../UI/H3";
import { ReactComponent as TrapsIcon } from "../../../UI/icons/TrapsIcon.svg";
import { InputText } from "../../../UI/InputText";
import { Spacer } from "../../../UI/Spacer";
import { useAllTraps } from "../../../usecases/traps";

export const Traps = () => {
  const { data: traps, isLoading: isLoadingTraps } = useAllTraps();

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const handleGlobalFilterValueChange = (event) => {
    setGlobalFilterValue(event.target.value);
  };

  if (isLoadingTraps) {
    return null;
  }

  return (
    <>
      <FlexWrapper>
        <Content>
          <Hero>
            <HeroChild>
              <TrapsIcon />
              <Spacer width="10px" />
              <H3>Trap Inventory</H3>
              <Spacer width="18px" />
              <InputContainer>
                <InputText
                  placeholder="Search"
                  value={globalFilterValue}
                  onChange={handleGlobalFilterValueChange}
                />
              </InputContainer>
              <Spacer width="24px" />
            </HeroChild>
          </Hero>
          <Spacer height="40px" />
          <CustomTable
            data={traps.map((trap) => ({
              ...trap,
              trapStatus: deriveTrapStatus(trap),
            }))}
            globalFilterValue={globalFilterValue}
            globalFilterFields={["serialNumber", "imei"]}
            columns={[
              {
                header: "Serial #",
                field: "serialNumber",
                sortable: true,
              },
              {
                header: "IMEI #",
                field: "imei",
                sortable: true,
              },
              {
                header: "Status",
                field: "trapStatus",
                body: (trap) => {
                  if (trap.trapStatus === "assigned") {
                    return <StyledStatus assigned>Assigned</StyledStatus>;
                  } else if (trap.trapStatus === "available") {
                    return <StyledStatus>Available</StyledStatus>;
                  }
                },
                filter: {
                  type: "oneOf",
                  options: [
                    {
                      label: "Available",
                      value: "available",
                    },
                    {
                      label: "Assigned",
                      value: "assigned",
                    },
                  ],
                },
              },
              {
                body: (summary) => (
                  <LastColumnWrapper>
                    <InternalLink to={summary._id}>Details</InternalLink>
                  </LastColumnWrapper>
                ),
              },
            ]}
          />
        </Content>
      </FlexWrapper>
    </>
  );
};

const deriveTrapStatus = (trap) => {
  if (!trap.assignedAt) {
    return "available";
  }
  if (trap.assignedAt) {
    return "assigned";
  }
};

const FlexWrapper = styled.div`
  background-color: ${(props) => `${props.theme.colors.gray[100]}`};
  padding: ${(props) => `32px ${props.theme.paddings.contentX}`};
`;

const Content = styled.div`
  margin: 0 auto;
  max-width: ${(props) => props.theme.maxWidths.content};
`;

const Hero = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeroChild = styled.div`
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
  }
`;

const InputContainer = styled.div`
  max-width: 200px;
`;

const InternalLink = styled(Link)`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4a7eff;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
`;

const LastColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;

  svg {
    flex-shrink: 0;
  }
`;

const StyledStatus = styled.p`
  padding: 4px 8px 6px 19px;
  border: ${(props) =>
    props.assigned ? "1px solid #B7C2CC" : "1px solid #56c288"};
  border-radius: 25px;
  width: fit-content;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: ${(props) => (props.assigned ? "#B7C2CC" : "#56c288")};
    border-radius: 50%;
    left: 8px;
    top: calc(50% + 1px);
    transform: translateY(-50%);
  }
`;
