import { useNavigate } from "@tanstack/react-location";
import { format, parseISO } from "date-fns";
import React from "react";
import styled from "styled-components";
import { BreadcrumbsFromLocation } from "../../../components/BreadcrumbsFromLocation";
import { Map } from "../../../components/Map";
import { Button } from "../../../UI/Button";
import { H3 } from "../../../UI/H3";
import { H4 } from "../../../UI/H4";
import { H5 } from "../../../UI/H5";
import { ReactComponent as BluePersonIcon } from "../../../UI/icons/BluePersonIcon.svg";
import { ReactComponent as DownloadIcon } from "../../../UI/icons/DownloadIcon.svg";
import { ReactComponent as PropertyIcon } from "../../../UI/icons/PropertyIcon.svg";
import { ReactComponent as RoundPlusIcon } from "../../../UI/icons/RoundPlusIcon.svg";
import { Link } from "../../../UI/Link";
import { Spacer } from "../../../UI/Spacer";
import { countryForDisplay } from "../../../utils/countries";
import { exportToSpreadsheet } from "../../../utils/export";
import { pick } from "../../../utils/object";

export const Hero = ({ clientDetails }) => {
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate({ to: "edit" });
  };

  const Headers = [
    [
      "Name",
      "Managed",
      "Street",
      "City",
      "Traps count",
      "Activations since last visit",
      "Last Visit",
      "Activations past day",
      "Activations past week",
      "Activations past month",
    ],
  ];

  const exportExcel = async () => {
    await exportToSpreadsheet(
      clientDetails.properties.map((property) => ({
        ...pick(property, [
          "name",
          "managed",
          "street",
          "city",
          "trapsCount",
          "activationsSinceLastVisit",
          "lastVisit",
          "activationsPastDay",
          "activationsPastWeek",
          "activationsPastMonth",
        ]),
        lastVisit: property.lastVisit
          ? format(parseISO(property.lastVisit), "dd/MM/yyyy")
          : "-",
      })),
      `Client Details`,
      "client-details",
      Headers
    );
  };

  return (
    <>
      <Spacer height="24px" />
      <BreadCrumbContainer>
        <BreadcrumbsFromLocation />
        <DownloadButton onClick={exportExcel}>
          <DownloadIcon />
          <Spacer width="6px" />
          Report
        </DownloadButton>
      </BreadCrumbContainer>
      <Spacer height="24px" />
      <HeroContainer>
        <MapContainer>
          <MapContainerChildren>
            <FlexContainer>
              <div style={{ display: "flex", alignItems: "center" }}>
                <BluePersonIcon />
                <Spacer width="8px" />
                <H4 white>{clientDetails.client.name}</H4>
              </div>
              <div style={{ display: "flex" }}>
                <ClientHeroButton onClick={handleEditClick}>
                  Edit
                </ClientHeroButton>
              </div>
            </FlexContainer>
            <Spacer height="24px" />
            <ClientInformationFlex>
              <Spacer width="24px" />
              <div>
                <ClientInformationParagraph>
                  <span>Manager</span> {clientDetails.manager}
                </ClientInformationParagraph>
                <Spacer height="8px" />
                <ClientInformationParagraph>
                  <span>Contact</span> {clientDetails.contact.name}
                </ClientInformationParagraph>
                <Spacer height="8px" />
                <ClientInformationParagraph>
                  {clientDetails.contact.email}
                </ClientInformationParagraph>
                <Spacer height="8px" />
                <ClientInformationParagraph>
                  {clientDetails.contact.phoneNumber}
                </ClientInformationParagraph>
              </div>
              <Spacer width="117px" />

              <div>
                <ClientInformationParagraph>
                  {clientDetails.client.address.street}
                </ClientInformationParagraph>
                <Spacer height="8px" />
                <ClientInformationParagraph>
                  {countryForDisplay(clientDetails.client.address.country)}{" "}
                  {clientDetails.client.address.postalCode}
                </ClientInformationParagraph>
                <Spacer height="8px" />
                <ClientInformationParagraph>
                  {clientDetails.client.address.state} /{" "}
                  {clientDetails.client.address.city}
                </ClientInformationParagraph>
                <Spacer height="8px" />
              </div>
            </ClientInformationFlex>
          </MapContainerChildren>
          <MapContainerChildren>
            {clientDetails.properties.length === 0 && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <H5 style={{ color: "white" }}>No data to display.</H5>
                <Link to="new-property">Add a property</Link>
              </div>
            )}
            {clientDetails.properties.length > 0 && (
              <Map
                pins={clientDetails.properties
                  .filter((property) => property.coordinates?.longitude)
                  .map((property) => property.coordinates)}
                heatmap={clientDetails.properties
                  .filter((property) => property.coordinates?.longitude)
                  .map((property) => ({
                    location: property.coordinates,
                    weight: property.activationsPastMonth,
                  }))}
              />
            )}
          </MapContainerChildren>
        </MapContainer>
        <Root>
          <InformationContainer>
            <Spacer height="16px" />
            <LabelParagraph>Total Client Traps</LabelParagraph>
            <Spacer height="28px" />
            <CountParagraph style={{ fontWeight: "600" }}>
              {clientDetails.totalTraps}
            </CountParagraph>
            <Spacer height="24px" />
          </InformationContainer>
          <InformationContainer>
            <Spacer height="16px" />
            <LabelParagraph>Total Active Properties</LabelParagraph>
            <Spacer height="28px" />
            <CountParagraph style={{ fontWeight: "600" }}>
              {clientDetails.totalActiveProperties}
            </CountParagraph>
            <Spacer height="24px" />
          </InformationContainer>
          <InformationContainer>
            <Spacer height="16px" />
            <LabelParagraph>Total Activations Past Week</LabelParagraph>
            <Spacer height="28px" />
            <CountParagraph style={{ fontWeight: "600" }}>
              {clientDetails.totalActivationsPastWeek}
            </CountParagraph>
            <Spacer height="24px" />
          </InformationContainer>
          <InformationContainer>
            <Spacer height="16px" />
            <LabelParagraph>Total Activations Past Month</LabelParagraph>
            <Spacer height="28px" />
            <CountParagraph style={{ fontWeight: "600" }}>
              {clientDetails.totalActivationsPastMonth}
            </CountParagraph>
            <Spacer height="24px" />
          </InformationContainer>
        </Root>
      </HeroContainer>
    </>
  );
};

export const PropertiesCTA = () => {
  const navigate = useNavigate();

  const navigateCreateProperty = () => {
    navigate({ to: "new-property" });
  };

  return (
    <>
      <ContentWrapper>
        <Child>
          <PropertyIcon />
          <Spacer width="12px" />
          <H3>Properties</H3>
        </Child>
        <Button onClick={navigateCreateProperty}>
          <RoundPlusIcon />
          New Property
        </Button>
      </ContentWrapper>
      <Spacer height="48px" />
    </>
  );
};

const BreadCrumbContainer = styled.div`
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
`;

const DownloadButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-family: "Bai Jamjuree";
  font-weight: bold;
  cursor: pointer;
`;

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 0 32px;
  position: relative;
`;

const Root = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  background-color: #fff;
  border: 1px solid #d5dce1;
  box-sizing: border-box;
  border-radius: 8px;
  position: absolute;
  top: 280px;
  left: 50%;
  transform: translateX(-50%);
`;

const InformationContainer = styled.div`
  border-radius: 8px;
  max-width: 151px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 12px;
  text-align: center;
  justify-content: space-between;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Child = styled.div`
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
  }
`;

const CountParagraph = styled.p`
  font-size: 16px;
  font-weight: 600;
  font-family: "Bai Jamjuree";
`;

const LabelParagraph = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #405261;
`;

const MapContainer = styled.div`
  display: flex;
  width: 100%;
  height: 265px;
`;

const MapContainerChildren = styled.div`
  width: 50%;
  overflow: hidden;

  &:first-child {
    background: linear-gradient(91.98deg, #0d1026 3.35%, #19204d 96.65%);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:nth-child(2) {
    background: linear-gradient(91.98deg, #0d1026 3.35%, #19204d 96.65%);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const ClientInformationFlex = styled.div`
  display: flex;
`;

const ClientInformationParagraph = styled.div`
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);

  span {
    color: #a5bfff;
  }
`;

const ClientHeroButton = styled.button`
  padding: 8px 12px;
  border: ${(props) => (props.red ? "1px solid #FF4757" : "1px solid #fff")};
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto";
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 18px 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;
