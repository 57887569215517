import styled from "styled-components";
import { StepIcon } from "./StepIcon";

/**
 * @typedef Step
 * @prop {string} label
 *
 * @typedef StepperProps
 * @prop {number} activeStep 0-based index of the step which is active`
 * @prop {Step[]} steps
 */
/** @type {React.FC<StepperProps>} */
export const Stepper = ({ activeStep, steps }) => {
  return (
    <Root>
      {steps.map((step, index) => (
        <StepContainer key={step.label}>
          <StepIcon
            step={index + 1}
            state={stepStateBasedOnActiveStep(index, activeStep)}
          />
          <StepLabel $active={index === activeStep}>{step.label}</StepLabel>
          {index > 0 && <StepConnector $passed={activeStep >= index} />}
        </StepContainer>
      ))}
    </Root>
  );
};

/**
 *
 * @param {number} step Step for which to calculate step state
 * @param {number} activeStep Active step
 */
const stepStateBasedOnActiveStep = (step, activeStep) => {
  const sign = Math.sign(activeStep - step);
  return {
    "-1": "todo",
    0: "active",
    1: "complete",
  }[sign];
};

const Root = styled.div`
  position: relative;
  z-index: 0;
  display: flex;
  width: 100%;
`;

const StepContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StepLabel = styled.p`
  font-family: "Roboto", sans-serif;
  font-weight: ${(props) => (props.$active ? "500" : "400")};
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${(props) => (props.$active ? "#040E56" : "#748494")};
  margin-top: 12px;
`;

const StepConnector = styled.div`
  position: absolute;
  right: 50%;
  top: 14px;
  z-index: -1;
  width: 100%;
  height: 1px;
  background: ${(props) => (props.$passed ? "#040E56" : "#9CF1CE")};
`;
