import { atomWithStorage, createJSONStorage } from "jotai/utils";

export const step1DataAtom = atomWithStorage(
  "step1Data",
  {},
  createJSONStorage(() => window.sessionStorage)
);
step1DataAtom.debugLabel = "step1DataAtom";
export const step2DataAtom = atomWithStorage(
  "step2Data",
  {},
  createJSONStorage(() => window.sessionStorage)
);
step2DataAtom.debugLabel = "step2DataAtom";
export const step3DataAtom = atomWithStorage(
  "step3Data",
  {},
  createJSONStorage(() => window.sessionStorage)
);
step3DataAtom.debugLabel = "step3DataAtom";
