import { Calendar as PrimeCalendar } from "primereact/calendar";
import { forwardRef } from "react";
import styled from "styled-components";
import { InputError } from "../InputError";
import { Spacer } from "../Spacer";

export const Calendar = forwardRef(
  (
    {
      value,
      onChange,
      onBlur,
      name,
      placeholder,
      errorMessage,
      readOnly,
      autoFocus,
      minDate,
      maxDate,
    },
    ref
  ) => {
    return (
      <Root>
        <StyledCalendar
          ref={ref}
          dateFormat="dd/mm/yy"
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          $hasError={Boolean(errorMessage)}
          readOnlyInput={readOnly}
          autoFocus={autoFocus}
          minDate={minDate}
          maxDate={maxDate}
        />
        {errorMessage && (
          <>
            <Spacer height="4px" />
            <InputError>{errorMessage}</InputError>
          </>
        )}
      </Root>
    );
  }
);
Calendar.displayName = "Calendar";

const Root = styled.div`
  width: 100%;
`;
Root.displayName = "Root";

const StyledCalendar = styled(PrimeCalendar)`
  width: 100%;
  height: 48px;

  input {
    background: ${(props) => (props.readOnly ? "transparent" : "#ffffff")};
    border: 1px solid ${(props) => (props.$hasError ? "#FF4757" : "#d5dce1")};
    border-radius: 2px;
    font-family: "Bai Jamjuree", sans-serif;
    font-size: 14px;

    &:focus {
      outline-style: solid;
      outline-color: #4a7eff;
      outline-width: 1px;
      outline: ${(props) => (props.readOnly ? "none" : "")};
      border-color: ${(props) => (props.readOnly ? "unset" : "")};
      box-shadow: ${(props) => (props.readOnly ? "none" : "")};
    }
  }
`;
StyledCalendar.displayName = "StyledCalendar";
