/**
 * Checks whether `x` is `undefined`
 * @param {unknown} x
 * @returns {x is undefined}
 */
export const isUndefined = (x) => x === undefined;
/**
 * Checks whether `x` is `null`
 * @param {unknown} x
 * @returns {x is null}
 */
export const isNull = (x) => x === null;
/**
 * Checks whether `x` is `null` or `undefined`
 * @param {unknown} x
 * @returns {x is undefined | null}
 */
export const isNullish = (x) => isUndefined(x) || isNull(x);
