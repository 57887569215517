import { atom } from "jotai";
import { atomWithDefault } from "jotai/utils";

/**
 * @typedef AuthUnauthenticatedState
 * @prop {'unauthenticated'} status
 *
 * @typedef AuthAuthenticatingState
 * @prop {'authenticating'} status
 *
 * @typedef AuthAuthenticatedState
 * @prop {'authenticated'} status
 * @prop {import('./').User | null} user
 *
 * @typedef {AuthUnauthenticatedState | AuthAuthenticatingState | AuthAuthenticatedState} AuthState
 */

export const authStateAtom = atomWithDefault(
  () =>
    /** @type {AuthState} */ ({
      status: "unauthenticated",
    })
);
authStateAtom.debugLabel = "authStateAtom";

export const userAtom = atom((get) => {
  const authState = get(authStateAtom);
  if (authState.status !== "authenticated") {
    return null;
  }
  return authState.user;
});
userAtom.debugLabel = "userAtom";
