import styled from "styled-components";
import { ReactComponent as StepCheckmarkIcon } from "../icons/step-checkmark.svg";
import { H4 } from "../H4";

/**
 * @typedef StepIconProps
 * @prop {number} step
 * @prop {'todo' | 'active' | 'complete'} state
 */
/** @type {React.FC<StepIconProps>} */
export const StepIcon = ({ step, state }) => (
  <Root $state={state}>
    {state === "active" && <H4>{String(step)}</H4>}
    {state === "complete" && <StepCheckmarkIcon />}
  </Root>
);

/**
 * @param {'todo' | 'active' | 'complete'} state
 * @returns {string}
 */
const backgroundColor = (state) => {
  switch (state) {
    case "todo":
      return "#9CF1CE";
    case "active":
      return "#39E29D";
    case "complete":
      return "#040E56";
    default:
      throw new Error(`Invalid state: ${state}`);
  }
};

/**
 * @param {'todo' | 'active' | 'complete'} state
 * @returns {string}
 */
const textColor = (state) => {
  switch (state) {
    case "todo":
      return "#9CF1CE";
    case "active":
      return "#040E56";
    case "complete":
      return "#39E29D";
    default:
      throw new Error(`Invalid state: ${state}`);
  }
};

/**
 * @param {'todo' | 'active' | 'complete'} state
 * @returns {string}
 */
const borderColor = (state) => {
  switch (state) {
    case "todo":
      return "transparent";
    case "active":
      return "#040E56";
    case "complete":
      return "#040E56";
    default:
      throw new Error(`Invalid state: ${state}`);
  }
};

const Root = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => backgroundColor(props.$state)};
  border: 1px solid ${(props) => borderColor(props.$state)};
  color: ${(props) => textColor(props.$state)};
`;
