import styled, { css } from "styled-components";
import { Header } from "../Header";
import { Sidebar } from "../Sidebar";
import { ReactComponent as NavClientsIcon } from "../../UI/icons/nav-clients.svg";
import { ReactComponent as NavTrapsIcon } from "../../UI/icons/nav-traps.svg";
import { ReactComponent as NavTeamIcon } from "../../UI/icons/nav-team.svg";
import { useAtomValue } from "jotai";
import { modalsDisplayedCountAtom } from "../Modal/state";
import { userAtom } from "../../usecases/auth";

export const LoggedInLayout = ({ children }) => {
  const anyModalsDisplayed = useAtomValue(modalsDisplayedCountAtom) > 0;
  const user = useAtomValue(userAtom);

  return (
    <Root $anyModalsDisplayed={anyModalsDisplayed}>
      <div className="sidebar">
        {user.role === "ADMIN" && (
          <Sidebar
            nav={[
              {
                label: "Client List",
                icon: <NavClientsIcon />,
                route: "/admin/clients",
              },
              {
                label: "Trap Inventory",
                icon: <NavTrapsIcon />,
                route: "/admin/traps",
              },
            ]}
          />
        )}
        {user.role === "BUSINESS_ACCOUNT_ADMIN" && (
          <Sidebar
            nav={[
              {
                label: "Client List",
                icon: <NavClientsIcon />,
                route: "/clients",
              },
              {
                label: "Trap Inventory",
                icon: <NavTrapsIcon />,
                route: "/traps",
              },
              {
                label: "Team",
                icon: <NavTeamIcon />,
                route: "/team",
              },
            ]}
          />
        )}
      </div>
      <div className="header">
        <Header />
      </div>
      <div className="content">{children}</div>
    </Root>
  );
};

const Root = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "sidebar header"
    "sidebar content";

  .sidebar {
    grid-area: sidebar;
    z-index: 2;
  }

  .header {
    grid-area: header;
    z-index: 1;
  }

  .content {
    grid-area: content;
    overflow-y: auto;
  }

  ${(props) =>
    props.$anyModalsDisplayed &&
    css`
      filter: blur(1.5px);
    `}
`;
