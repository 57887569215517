import { GMap } from "primereact/gmap";
import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import mapMarkerUrl from "../../UI/icons/map-marker.svg";
import { ReactComponent as MaximizeIcon } from "../../UI/icons/MaximizeIcon.svg";
import { ReactComponent as ExitFullScreenIcon } from "../../UI/icons/ExitFullScreenIcon.svg";
import { ReactComponent as ZoomInIcon } from "../../UI/icons/ZoomInIcon.svg";
import { ReactComponent as ZoomOutIcon } from "../../UI/icons/ZoomOutIcon.svg";
import { Spacer } from "../../UI/Spacer";
import { GoogleMapsApiLoadedGuard } from "../../utils/googleMapsLoader";

/**
 * @typedef Coordinate
 * @prop {number} longitude
 * @prop {number} latitude
 *
 * @typedef MapProps
 * @prop {Coordinate[] | undefined} pins
 * @prop {{ location: Coordinate, weight: number}[] | undefined} heatmap
 */

/** @type {React.FC<MapProps>} */
export const Map = GoogleMapsApiLoadedGuard(({ pins = [], heatmap }) => {
  const mapInstanceRef = useRef(/** @type {google.maps.Map} */ (null));
  const [mapType, setMapType] = useState(
    /** @type {'locations' | 'heatmap'} */ ("locations")
  );
  const heatmapLayerRef = useRef(
    new window.google.maps.visualization.HeatmapLayer({
      data: [],
      radius: 20,
    })
  );

  const [fullScreenIcon, setFullScreenIcon] = useState(false);

  const markersData = useMemo(
    () =>
      pins.map(
        (pin) =>
          new window.google.maps.Marker({
            position: { lat: pin.latitude, lng: pin.longitude },
            icon: mapMarkerUrl,
          })
      ),
    [pins]
  );

  useEffect(() => {
    heatmapLayerRef.current.setData(
      (heatmap ?? []).map((entry) => ({
        location: new window.google.maps.LatLng({
          lat: entry.location.latitude,
          lng: entry.location.longitude,
        }),
        weight: entry.weight,
      }))
    );
  }, [heatmap]);

  const handleSetMapType = (mapType) => {
    if (mapType === "locations") {
      heatmapLayerRef.current.setMap(null);
    } else if (mapType === "heatmap") {
      heatmapLayerRef.current.setMap(mapInstanceRef.current ?? null);
    }
    setMapType(mapType);
  };

  const handleZoomInClick = () => {
    mapInstanceRef.current.setZoom(mapInstanceRef.current.getZoom() + 1);
  };

  const handleZoomOutClick = () => {
    mapInstanceRef.current.setZoom(mapInstanceRef.current.getZoom() - 1);
  };

  const handleFullscreenClick = () => {
    const map = mapInstanceRef.current.getDiv().parentElement;

    if (isFullscreen(map)) {
      document.exitFullscreen();
      setFullScreenIcon(false);
    } else {
      setFullScreenIcon(true);
      map.requestFullscreen();
    }
  };

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <GMap
        style={{ width: "100%", height: "100%" }}
        options={{
          center: {
            lng: pins[0]?.longitude ?? 0,
            lat: pins[0]?.latitude ?? 0,
          },
          zoom: 13,
          styles: mapsStyles,
          zoomControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          options: {
            gestureHandling: "cooperative",
          },
        }}
        overlays={mapType === "locations" ? markersData : undefined}
        onMapReady={({ map }) => {
          return (mapInstanceRef.current = map);
        }}
      />
      {Boolean(heatmap) && (
        <LeftButtonContainer>
          <MapButton
            style={{
              cursor: "pointer",
              marginRight: "4px",
              backgroundColor: mapType === "locations" ? "#fff" : "unset",
              color: mapType === "locations" ? "#405261" : "",
            }}
            onClick={() => handleSetMapType("locations")}
          >
            Icon
          </MapButton>
          <MapButton
            style={{
              cursor: "pointer",
              backgroundColor: mapType === "heatmap" ? "#fff" : "unset",
              color: mapType === "heatmap" ? "#405261" : "",
            }}
            onClick={() => handleSetMapType("heatmap")}
          >
            Heatmap
          </MapButton>
        </LeftButtonContainer>
      )}
      <RightButtonContainer>
        <RightButton onClick={handleFullscreenClick}>
          {fullScreenIcon ? <ExitFullScreenIcon /> : <MaximizeIcon />}
        </RightButton>
        <div>
          <RightButton onClick={handleZoomInClick}>
            <ZoomInIcon />
          </RightButton>
          <Spacer height="4px" />
          <RightButton onClick={handleZoomOutClick}>
            <ZoomOutIcon />
          </RightButton>
        </div>
      </RightButtonContainer>
    </div>
  );
});
Map.displayName = "Map";

const mapsStyles = [
  {
    featureType: "all",
    elementType: "geometry",
    stylers: [
      {
        color: "#202c3e",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.fill",
    stylers: [
      {
        gamma: 0.01,
      },
      {
        lightness: 20,
      },
      {
        weight: "1.39",
      },
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [
      {
        weight: "0.96",
      },
      {
        saturation: "9",
      },
      {
        visibility: "on",
      },
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        lightness: 30,
      },
      {
        saturation: "9",
      },
      {
        color: "#29446b",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        saturation: 20,
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        lightness: 20,
      },
      {
        saturation: -20,
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        lightness: 10,
      },
      {
        saturation: -30,
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#193a55",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [
      {
        saturation: 25,
      },
      {
        lightness: 25,
      },
      {
        weight: "0.01",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        lightness: -20,
      },
    ],
  },
];

const isFullscreen = (element) => {
  return (
    (document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement) === element
  );
};

const LeftButtonContainer = styled.div`
  position: absolute;
  top: 18px;
  left: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.6);
  box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.3);
  border-radius: 60px;
  backdrop-filter: blur(50px);
`;

const RightButtonContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 40px;
`;

const RightButton = styled.button`
  background-color: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.6);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const MapButton = styled.button`
  background: transparent;
  border: none;
  border-radius: 50px;
  margin: 4px;
  width: 74px;
  padding: 5px 0;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  font-family: "Roboto";
`;
