import { formatISO } from "date-fns";
import { axios } from "../../utils/axios";

export const getTraps = async () => {
  const response = await axios.get("/traps");
  return response.data;
};

export const getTrap = async (id) => {
  const trapDetailsResponse = await axios.get(`/traps/${id}`);
  const activationsResponse = await axios.get(
    `/sensors/activations/${trapDetailsResponse.data.imei}`
  );

  return {
    ...trapDetailsResponse.data,
    activations: activationsResponse.data,
  };
};

export const registerTrap = async ({ serialNumber, imei }) => {
  const response = await axios.post("/traps", { serialNumber, imei });
  return response.data;
};

export const assignTrap = async ({ trapId, propertyId }) => {
  const response = await axios.patch(`/traps/assign/${trapId}`, {
    property: propertyId,
  });
  return response.data;
};

export const unassignTrap = async ({ trapId }) => {
  const response = await axios.patch(`/traps/unassign/${trapId}`);
  return response.data;
};

export const removeTrap = async ({ trapId }) => {
  const response = await axios.delete(`/traps/${trapId}`);
  return response.data;
};

/**
 *
 * @param {string} id
 * @param {Date} lastVisitDate
 * @returns
 */
export const updateTrapLastVisit = async (id, lastVisitDate) => {
  const response = await axios.patch(`/traps/${id}`, {
    lastVisit: formatISO(lastVisitDate, { representation: "date" }),
  });
  return response.data;
};
