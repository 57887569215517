import styled from "styled-components";
import { H3 } from "../../../UI/H3";
import { ReactComponent as ClientsIcon } from "../../../UI/icons/ClientsIcon.svg";
import { InputText } from "../../../UI/InputText";
import { Spacer } from "../../../UI/Spacer";
import { useAllBusinessAccounts } from "../../../usecases/businessAccounts";
import { CustomTable } from "../../../components/CustomTable";
import { useState } from "react";
import { Link } from "@tanstack/react-location";

export const Clients = () => {
  const { data: clients, isLoading } = useAllBusinessAccounts();

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const handleGlobalFilterValueChange = (event) => {
    setGlobalFilterValue(event.target.value);
  };

  if (isLoading) {
    return null;
  }

  return (
    <FlexWrapper>
      <Content>
        <Hero>
          <HeroChild>
            <ClientsIcon />
            <Spacer width="10px" />
            <H3>Clients</H3>
            <Spacer width="18px" />
            <InputContainer>
              <InputText
                placeholder="Search"
                value={globalFilterValue}
                onChange={handleGlobalFilterValueChange}
              />
            </InputContainer>
            <Spacer width="24px" />
          </HeroChild>
        </Hero>
        <Spacer height="40px" />
        <CustomTable
          data={clients}
          globalFilterValue={globalFilterValue}
          globalFilterFields={["name"]}
          columns={[
            {
              header: "Name",
              field: "name",
              sortable: true,
            },
            {
              header: "# of Traps",
              field: "count",
              sortable: true,
            },
            {
              body: (client) => (
                <LastColumnWrapper>
                  <InternalLink to={client._id}>Details</InternalLink>
                </LastColumnWrapper>
              ),
            },
          ]}
        />
      </Content>
    </FlexWrapper>
  );
};

const FlexWrapper = styled.div`
  background-color: ${(props) => `${props.theme.colors.gray[100]}`};
  padding: ${(props) => `32px ${props.theme.paddings.contentX}`};
`;

const Content = styled.div`
  margin: 0 auto;
  max-width: ${(props) => props.theme.maxWidths.content};
`;

const Hero = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeroChild = styled.div`
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
  }
`;

const InputContainer = styled.div`
  max-width: 200px;
`;

const InternalLink = styled(Link)`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4a7eff;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
`;

const LastColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;

  svg {
    flex-shrink: 0;
  }
`;
