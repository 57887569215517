import { startOfDay } from "date-fns";
import { Calendar } from "primereact/calendar";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { InputSubmit } from "../../UI/InputSubmit";
import { Spacer } from "../../UI/Spacer";
import { Modal } from "../Modal";

export const EditLastVisitModal = ({
  isOpen,
  onSubmit,
  isLoading,
  onRequestClose,
}) => {
  const { handleSubmit, control } = useForm({
    mode: "onBlur",
    defaultValues: {
      date: startOfDay(new Date()),
    },
  });

  const handleValidSubmit = (data) => {
    onSubmit(data);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <StyledForm onSubmit={handleSubmit(handleValidSubmit)}>
        <Controller
          control={control}
          name="date"
          render={({ field }) => <Calendar {...field} inline={true} />}
        />
        <Spacer height="20px" />
        <InputSubmit loading={isLoading}>Save</InputSubmit>
      </StyledForm>
    </Modal>
  );
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;
