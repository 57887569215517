import { Outlet } from "@tanstack/react-location";
import { useAtomValue } from "jotai";
import { useAtomsDevtools } from "jotai/devtools";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import React, { Fragment } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import "react-toastify/dist/ReactToastify.css";
import { LoggedInLayout } from "./components/LoggedInLayout";
import { GlobalStyle } from "./GlobalStyle";
import { Router } from "./routes";
import Theme from "./Theme";
import { userAtom } from "./usecases/auth";
import { ToastContainer } from "react-toastify";

const AtomsDevtools = ({ children }) => {
  useAtomsDevtools("demo");
  return <>{children}</>;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10000,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const user = useAtomValue(userAtom);

  const Layout = user ? LoggedInLayout : Fragment;

  return (
    <AtomsDevtools>
      <Theme>
        <GlobalStyle />
        <QueryClientProvider client={queryClient}>
          <Router user={user} queryClient={queryClient}>
            <Layout>
              <Outlet />
            </Layout>
          </Router>
        </QueryClientProvider>

        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar
          draggable={false}
        />
      </Theme>
    </AtomsDevtools>
  );
}

export default App;
