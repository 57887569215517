import { useNavigate } from "@tanstack/react-location";
import { format, parseISO } from "date-fns";
import React from "react";
import styled from "styled-components";
import { BreadcrumbsFromLocation } from "../../../components/BreadcrumbsFromLocation";
import { Map } from "../../../components/Map";
import { Button } from "../../../UI/Button";
import { H3 } from "../../../UI/H3";
import { H4 } from "../../../UI/H4";
import { H5 } from "../../../UI/H5";
import { ReactComponent as DownloadIcon } from "../../../UI/icons/DownloadIcon.svg";
import { ReactComponent as LocationIcon } from "../../../UI/icons/LocationIcon.svg";
import { ReactComponent as RoundPlusIcon } from "../../../UI/icons/RoundPlusIcon.svg";
import { ReactComponent as TrapsIcon } from "../../../UI/icons/TrapsIcon.svg";
import { Link } from "../../../UI/Link";
import { Spacer } from "../../../UI/Spacer";
import { countryForDisplay } from "../../../utils/countries";
import { exportToSpreadsheet } from "../../../utils/export";

export const Hero = ({ propertyDetails, onAddTrapClick }) => {
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate({ to: "edit" });
  };

  const Headers = [
    [
      "Trap #",
      "Battery",
      "Cell Signal",
      "# of Activations Since Last Visit",
      "Last Visit",
    ],
  ];

  const exportExcel = async () => {
    await exportToSpreadsheet(
      propertyDetails.traps.map((trap) => ({
        "Trap #": trap.name ? trap.name : "-",
        Battery: trap.batteryLevel ? trap.batteryLevel : "-",
        "Cell Signal": trap.signalLevel ? trap.signalLevel : "-",
        "# of Activations Since Last Visit": trap.activationsSinceLastVisit,
        "Last Visit": trap.lastVisit
          ? format(parseISO(trap.lastVisit), "dd/MM/yyyy")
          : "-",
      })),
      `Property Details`,
      "property-details",
      Headers
    );
  };

  const hasCoordinatesToShowOnMap = propertyDetails.traps.some(
    (trap) => trap.coordinates?.longitude
  );

  return (
    <>
      <Spacer height="24px" />
      <BreadCrumbContainer>
        <BreadcrumbsFromLocation />
        <DownloadButton onClick={exportExcel}>
          <DownloadIcon />
          <Spacer width="6px" />
          Report
        </DownloadButton>
      </BreadCrumbContainer>
      <Spacer height="24px" />
      <HeroContainer>
        <MapContainer>
          <MapContainerChildren>
            <FlexContainer>
              <div style={{ display: "flex", alignItems: "center" }}>
                <LocationIcon />
                <Spacer width="8px" />
                <H4 white>{propertyDetails.name}</H4>
              </div>
              <div style={{ display: "flex" }}>
                <ClientHeroButton onClick={handleEditClick}>
                  Edit
                </ClientHeroButton>
              </div>
            </FlexContainer>
            <Spacer height="24px" />
            <ClientInformationFlex>
              <Spacer width="24px" />
              <div>
                <ClientInformationParagraph>
                  <span>Manager</span> {propertyDetails.manager}
                </ClientInformationParagraph>
                <Spacer height="8px" />
                <ClientInformationParagraph>
                  <span>Contact</span> {propertyDetails.contact.name}
                </ClientInformationParagraph>
                <Spacer height="8px" />
                <ClientInformationParagraph>
                  {propertyDetails.contact.email}
                </ClientInformationParagraph>
                <Spacer height="8px" />
                <ClientInformationParagraph>
                  {propertyDetails.contact.phoneNumber}
                </ClientInformationParagraph>
              </div>
              <Spacer width="117px" />

              <div>
                <ClientInformationParagraph>
                  {propertyDetails.address.street}
                </ClientInformationParagraph>
                <Spacer height="8px" />
                <ClientInformationParagraph>
                  {countryForDisplay(propertyDetails.address.country)}{" "}
                  {propertyDetails.address.postalCode}
                </ClientInformationParagraph>
                <Spacer height="8px" />
                <ClientInformationParagraph>
                  {propertyDetails.address.state} /{" "}
                  {propertyDetails.address.city}
                </ClientInformationParagraph>
                <Spacer height="8px" />
              </div>
            </ClientInformationFlex>
          </MapContainerChildren>
          <MapContainerChildren>
            {!hasCoordinatesToShowOnMap && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <H5 style={{ color: "white" }}>No data to display.</H5>
                <Link onClick={onAddTrapClick}>
                  Assign a trap to this property
                </Link>
              </div>
            )}
            {hasCoordinatesToShowOnMap && (
              <Map
                pins={propertyDetails.traps
                  .filter((trap) => trap.coordinates?.longitude)
                  .map((trap) => trap.coordinates)}
                heatmap={propertyDetails.traps
                  .filter((trap) => trap.coordinates?.longitude)
                  .map((trap) => ({
                    location: trap.coordinates,
                    weight: trap.activationsPastMonth,
                  }))}
              />
            )}
          </MapContainerChildren>
        </MapContainer>
        <Root>
          <InformationContainer>
            <Spacer height="16px" />
            <LabelParagraph>Total Traps on Property</LabelParagraph>
            <Spacer height="28px" />
            <CountParagraph style={{ fontWeight: "600" }}>
              {propertyDetails.totalTraps}
            </CountParagraph>
            <Spacer height="24px" />
          </InformationContainer>
          <InformationContainer>
            <Spacer height="16px" />
            <LabelParagraph>Total Activations Past Week</LabelParagraph>
            <Spacer height="28px" />
            <CountParagraph style={{ fontWeight: "600" }}>
              {propertyDetails.totalActivationsPastWeek}
            </CountParagraph>
            <Spacer height="24px" />
          </InformationContainer>
          <InformationContainer>
            <Spacer height="16px" />
            <LabelParagraph>Total Activations Past Month</LabelParagraph>
            <Spacer height="28px" />
            <CountParagraph style={{ fontWeight: "600" }}>
              {propertyDetails.totalActivationsPastMonth}
            </CountParagraph>
            <Spacer height="24px" />
          </InformationContainer>
          <InformationContainer>
            <Spacer height="16px" />
            <LabelParagraph>Most Recent Technician Visit</LabelParagraph>
            <Spacer height="28px" />
            <CountParagraph style={{ fontWeight: "600" }}>
              {propertyDetails.lastVisit === null ? (
                <>-</>
              ) : (
                format(parseISO(propertyDetails.lastVisit), "dd/MM/yyyy")
              )}
            </CountParagraph>
            <Spacer height="24px" />
          </InformationContainer>
        </Root>
      </HeroContainer>
    </>
  );
};

export const IndividualPropertyCTA = ({ onAddTrapClick }) => (
  <>
    <ContentWrapper>
      <Child>
        <TrapsIcon />
        <Spacer width="10px" />
        <H3>Traps</H3>
      </Child>
      <Button onClick={onAddTrapClick}>
        <RoundPlusIcon />
        Assign Trap
      </Button>
    </ContentWrapper>
    <Spacer height="48px" />
  </>
);

const BreadCrumbContainer = styled.div`
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
`;

const DownloadButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-family: "Bai Jamjuree";
  font-weight: bold;
  cursor: pointer;
`;

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 0 32px;
  position: relative;
`;

const Root = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  background-color: #fff;
  border: 1px solid #d5dce1;
  box-sizing: border-box;
  border-radius: 8px;
  position: absolute;
  top: 280px;
  left: 50%;
  transform: translateX(-50%);
`;

const InformationContainer = styled.div`
  border-radius: 8px;
  max-width: 151px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 12px;
  text-align: center;
  justify-content: space-between;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Child = styled.div`
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
  }
`;

const CountParagraph = styled.p`
  font-size: 16px;
  font-weight: 600;
  font-family: "Bai Jamjuree";
`;

const LabelParagraph = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #405261;
`;

const MapContainer = styled.div`
  display: flex;
  width: 100%;
  height: 265px;
`;

const MapContainerChildren = styled.div`
  width: 50%;
  &:first-child {
    background: linear-gradient(91.98deg, #0d1026 3.35%, #19204d 96.65%);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:nth-child(2) {
    background: linear-gradient(91.98deg, #0d1026 3.35%, #19204d 96.65%);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const ClientInformationFlex = styled.div`
  display: flex;
`;

const ClientInformationParagraph = styled.div`
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);

  span {
    color: #a5bfff;
  }
`;

const ClientHeroButton = styled.button`
  padding: 8px 12px;
  border: ${(props) => (props.red ? "1px solid #FF4757" : "1px solid #fff")};
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto";
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 18px 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;
