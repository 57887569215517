import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearch } from "@tanstack/react-location";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import styled from "styled-components";
import * as yup from "yup";
import { toastError, toastSuccess } from "../../../components/Toast/Toast";
import { InputSubmit } from "../../../UI/InputSubmit";
import { InputText } from "../../../UI/InputText";
import { Paragraph } from "../../../UI/Paragraph";
import { Spacer } from "../../../UI/Spacer";
import { newPassword } from "../../../usecases/auth";

export const NewPasswordForm = () => {
  const navigate = useNavigate();
  const { token } = useSearch();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(formSchema),
    defaultValues: {
      password: "",
      repeatPassword: "",
    },
  });

  const mutation = useMutation({
    mutationFn: (data) => newPassword(data),
  });

  const handleValidSubmit = async ({ password }) => {
    try {
      await mutation.mutateAsync({ password, token });
      navigate({ to: "/login" });
      toastSuccess({
        header: "Successful Action!",
        body: "Password changed successfully.",
      });
    } catch (err) {
      toastError({
        header: "Error!",
        body: "Oops, something went wrong. Please try again",
      });
    }
  };

  const textField = (name, placeholder, type) => (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <InputText
          {...field}
          placeholder={placeholder}
          errorMessage={errors[name]?.message}
          type={type}
        />
      )}
    />
  );

  return (
    <NewPasswordFormContainer onSubmit={handleSubmit(handleValidSubmit)}>
      <SmallLogo>
        <img src="/Logo.svg" alt="" />
        <Spacer height="40px" />
      </SmallLogo>
      <Paragraph>Enter your new password.</Paragraph>
      <Spacer height="30px" />
      {textField("password", "Your new password", "password")}
      <Spacer height="12px" />
      {textField("repeatPassword", "Repeat your new password", "password")}
      <Spacer height="32px" />
      <InputSubmit loading={mutation.isLoading}>Reset Password</InputSubmit>
    </NewPasswordFormContainer>
  );
};

const formSchema = yup
  .object({
    password: yup.string().required("Required"),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match"),
  })
  .required();

/* Styles */

const SmallLogo = styled.div`
  display: none;

  img {
    max-width: 80%;
    margin: 0 auto;
  }

  @media (max-width: 777px) {
    display: block;
  }
`;

const NewPasswordFormContainer = styled.form`
  max-width: 424px;
  width: 100%;

  @media (max-width: 500px) {
    padding: 0 10px;
  }
`;
