import React from "react";
import styled from "styled-components";
import { BreadcrumbsFromLocation } from "../../../../components/BreadcrumbsFromLocation";
import { Map } from "../../../../components/Map";
import { H3 } from "../../../../UI/H3";
import { H4 } from "../../../../UI/H4";
import { H5 } from "../../../../UI/H5";
import { ReactComponent as BlueTrapIcon } from "../../../../UI/icons/BlueTrapIcon.svg";
import { ReactComponent as DownloadIcon } from "../../../../UI/icons/DownloadIcon.svg";
import { Spacer } from "../../../../UI/Spacer";
import { exportToSpreadsheet } from "../../../../utils/export";
import { format, parseISO } from "date-fns";

export const Hero = ({ trapDetails }) => {
  const Headers = [
    [
      "Activation Date",
      "Activation Time",
      "Temperature (°C)",
      "Pressure (kPa)",
      "Humidity (%)",
    ],
  ];

  const language = window.navigator.userLanguage || window.navigator.language;

  const localValue = new Intl.NumberFormat(language, {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  });

  const exportExcel = async () => {
    await exportToSpreadsheet(
      trapDetails.activations.map((trap) => ({
        "Activation Date": format(parseISO(trap.dateTime), "dd/MM/yyyy"),
        "Activation Time": format(parseISO(trap.dateTime), "hh:mm:ss aa"),
        "Temperature (°C)": localValue.format(trap.temperature),
        "Pressure (kPa)": localValue.format(trap.pressure * 0.001),
        "Humidity (%)": localValue.format(trap.humidity),
      })),
      `Trap Details`,
      "trap-details",
      Headers
    );
  };

  return (
    <>
      <Spacer height="24px" />
      <BreadCrumbContainer>
        <BreadcrumbsFromLocation />
        <DownloadButton onClick={exportExcel}>
          <DownloadIcon />
          <Spacer width="6px" />
          Report
        </DownloadButton>
      </BreadCrumbContainer>
      <Spacer height="24px" />
      <HeroContainer>
        <MapContainer>
          <MapContainerChildren>
            <FlexContainer>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <BlueTrapIcon />
                  <Spacer width="8px" />
                  <H4 white>{trapDetails.serialNumber}</H4>
                </div>
                <Spacer height="12px" />
                {trapDetails.assignedAt ? (
                  <StyledStatus assigned>Assigned</StyledStatus>
                ) : (
                  <StyledStatus>Available</StyledStatus>
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <ClientInformationParagraph>
                  <span>GPS Location</span>
                </ClientInformationParagraph>
                <Spacer height="12px" />
                {!trapDetails.coordinates && (
                  <ClientInformationParagraph>N/A</ClientInformationParagraph>
                )}
                {trapDetails.coordinates && (
                  <ClientInformationParagraph>
                    {Math.abs(trapDetails.coordinates.latitude).toFixed(5)}°{" "}
                    {trapDetails.coordinates.latitude > 0 ? "N" : "S"},{" "}
                    {Math.abs(trapDetails.coordinates.longitude).toFixed(5)}°{" "}
                    {trapDetails.coordinates.longitude > 0 ? "E" : "W"}
                  </ClientInformationParagraph>
                )}
              </div>
            </FlexContainer>
            <Spacer height="24px" />
            <ClientInformationFlex>
              <Spacer width="24px" />
            </ClientInformationFlex>
          </MapContainerChildren>
          <MapContainerChildren>
            {!trapDetails.coordinates && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <H5 style={{ color: "white" }}>No data to display.</H5>
              </div>
            )}
            {trapDetails.coordinates && (
              <Map pins={[trapDetails.coordinates]} />
            )}
          </MapContainerChildren>
        </MapContainer>
      </HeroContainer>
    </>
  );
};

export const AdminTrapDetailsHeading = () => {
  return (
    <>
      <ContentWrapper>
        <Child>
          <Spacer width="10px" />
          <H3>Trap Activity History</H3>
        </Child>
      </ContentWrapper>
      <Spacer height="48px" />
    </>
  );
};

const BreadCrumbContainer = styled.div`
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
`;

const DownloadButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-family: "Bai Jamjuree";
  font-weight: bold;
  cursor: pointer;
`;

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 0 32px;
  position: relative;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Child = styled.div`
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
  }
`;

const MapContainer = styled.div`
  display: flex;
  width: 100%;
  height: 265px;
`;

const MapContainerChildren = styled.div`
  &:first-child {
    background: linear-gradient(91.98deg, #0d1026 3.35%, #19204d 96.65%);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    flex-basis: 287px;
  }

  &:nth-child(2) {
    background: linear-gradient(91.98deg, #0d1026 3.35%, #19204d 96.65%);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    flex: 1;
  }
`;

const ClientInformationFlex = styled.div`
  display: flex;
`;

const ClientInformationParagraph = styled.div`
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);

  span {
    color: #a5bfff;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 18px 24px;
  flex-direction: column;
  height: 100%;
`;

const StyledStatus = styled.p`
  padding: 4px 8px 6px 19px;
  border: ${(props) =>
    props.assigned ? "1px solid #B7C2CC" : "1px solid #56c288"};
  border-radius: 25px;
  width: fit-content;
  position: relative;
  color: #fff;

  &:after {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: ${(props) => (props.assigned ? "#B7C2CC" : "#56c288")};
    border-radius: 50%;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
