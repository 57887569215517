import { useRouter, useSearch } from "@tanstack/react-location";

export const useBackLink = () => {
  const { prev } = useSearch();
  const matches = useRouter().state.matches;
  const matchesToConsider = matches.filter((match) => match.route?.meta?.nav);

  if (matchesToConsider.some((match) => match.status !== "resolved")) {
    return { status: "resolving" };
  }
  if (matchesToConsider.length < 2) {
    return { status: "no-parent" };
  }

  const parentMatch = (() => {
    if (prev) {
      // Regex that erases hash and search components of a URL
      const pathname = prev.replace(/[#?].+/g, "");
      return matchesToConsider.find((match) => match.pathname === pathname);
    } else {
      return matchesToConsider[matchesToConsider.length - 2];
    }
  })();

  const path = parentMatch.route.meta.nav.path(parentMatch);
  const name = parentMatch.route.meta.nav.label(parentMatch);

  return { status: "success", path, name };
};
